import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {SecurityTabStore} from "../stores/SecurityTabStore";
import {observer} from "mobx-react";
import {useEffect} from "react";
import {EnhancedDialog} from "../../EnhancedDialog";
import {RemoveProfile} from '../../removeProfile/RemoveProfile';

interface RemoveProfileDialogProps {
    store: SecurityTabStore;
}

export const RemoveProfileDialog = observer(({store}: RemoveProfileDialogProps) => {
    useEffect(() => {
        if (store.removeProfileDialogOpen) {
            store.onChangeConfirmState(false);
        }
    }, [store.removeProfileDialogOpen])

    return <EnhancedDialog
        title="Удаление профиля"
        open={store.removeProfileDialogOpen}
        submitButtonOption={{
            label: "Удалить",
            color: "secondary",
            icon: DeleteForeverIcon,
            disabled: store.removeProfileSubmitBtnDisabled,
            onClick: store.onRemoveProfile
        }}
        cancelButtonOption={{
            color: "inherit",
            onClick: store.onRemoveProfileDialogClose
        }}
        maxWidth="sm"
        disallowBackdropClick
        actions
    >
        <RemoveProfile onChangeConfirmState={store.onChangeConfirmState}/>
    </EnhancedDialog>
})