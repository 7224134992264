import {MainAppStore} from "../ms-ui/ui/components/mainApp";
import {AuthStore} from "../ms-ui/ui/components/authentificate/AuthStore";
import {computed, makeObservable, observable, reaction} from "mobx";
import {TherapistViewModel} from "../models/entities";
import {Nullable} from "../ms-ui/types";
import {HttpClient} from "../ms-ui/http";
import {AuthState} from "../ms-ui/ui/components/authentificate/types/AuthState";
import {TherapistStatus} from "../commons/enums";
import axios from "axios";
import {getPersonName} from "../ms-ui/helpers";
import {RoleViewModel} from "../ms-ui/models/entities";
import {ResponseBase} from "../ms-ui/models/responses/ResponseBase";
import {therapistAppSettings} from "../therapistAppSettings";

export class TherapistAppStore extends MainAppStore {
    user: Nullable<TherapistViewModel> = null;

    constructor(authStoreCtr: { new(mainAppStore: MainAppStore): AuthStore }) {
        super(authStoreCtr);

        makeObservable(this, {
            user: observable,
            userShortName: computed
        });

        reaction(
            () => this.authStore.userId,
            userId => this.getUser()
        );
    }

    getUser = async () => {
        try {
            if (this.authStore.userId == null) {
                return;
            }

            const getUserByIdRequest = therapistAppSettings
                .getService("getUserById")({userId: this.authStore.userId});
            this.user = await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase<TherapistViewModel>, TherapistViewModel>(getUserByIdRequest);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                this.error = error.cause ?? new Error(error.message);
            } else if (error instanceof Error) {
                this.error = error;
            }
        }
    }

    onLogout = async () => {
        await this.authStore.onLogout(AuthState.LoggedOut);
    }

    changeTherapistStatusHandler = async (status: TherapistStatus) => {
        try {
            if (this.user === null) {
                return;
            }

            this.isLoading = true;

            const changeStatusRequest = therapistAppSettings
                .getService("changeStatus")({request: {status}});
            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(changeStatusRequest);
            this.user.status = status;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                this.error = error.cause ?? new Error(error.message);
            } else if (error instanceof Error) {
                this.error = error;
            }
        } finally {
            this.isLoading = false;
        }
    }

    get userShortName() {
        return getPersonName(this.user?.lastName, this.user?.firstName, this.user?.middleName)
    }

    get userRole(): Nullable<RoleViewModel> {
        return this.user?.role ?? null;
    }
}