import {FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";

import {Nullable} from "../../types";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {forwardRef, useState} from "react";

interface OutlinedPasswordTextFieldProps {
    label: string;
    value: Nullable<string>;
    onChange: (value: string) => void;
    error?: Nullable<string>;
    size?: "small" | "medium";
    fullWidth?: boolean;
}

export const OutlinedPasswordTextField = forwardRef<HTMLInputElement, OutlinedPasswordTextFieldProps>((props, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const {label, value, onChange, error, size = "small", fullWidth} = props;

    return <FormControl
        variant="outlined"
        fullWidth={fullWidth}
        size={size}>
        <InputLabel
            sx={[Boolean(error) && {color: "#f44336"}]}
        >
            {label}
        </InputLabel>
        <OutlinedInput
            label={label}
            type={showPassword ? 'text' : 'password'}
            value={value === null ? "" : value}
            onChange={({currentTarget: {value}}) => onChange(value)}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={e => e.preventDefault()}
                        edge="end"
                        size="small"
                    >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                    </IconButton>
                </InputAdornment>
            }
            error={Boolean(error)}
            autoComplete="new-password"
            inputProps={{
                ref: ref
            }}
        />
        {Boolean(error) && <FormHelperText
            sx={{
                margin: 0,
                color: "#f44336"
            }}
        >
            {error}
        </FormHelperText>}
    </FormControl>
})