import {
    Box, ClickAwayListener, IconButton,
    Stack, Typography,
} from "@mui/material"
import {observer} from "mobx-react";
import SearchIcon from "@mui/icons-material/Search";
import {OutlinedTextField} from "../../../ms-ui/ui/controls";
import {OverlayFlexContainer} from "../../../ms-ui/ui/components/OverlayFlexContainer";
import {ChatStore} from "./stores/ChatStore";
import {ClientListItem} from "./ClientListItem";

export interface ClientsProps {
    store: ChatStore
}

export const Clients = observer(({store}: ClientsProps) => {
    return <ClickAwayListener onClickAway={store.onClientsClickAwayHandler}>
        <Stack
            sx={[{
                borderRight: "1px solid rgba(0,0,0, 0.12)",
                padding: "8px 4px",
                minWidth: "70px"
            },
                store.clientWidth ? {
                    width: store.clientWidth,
                    boxSizing: "border-box",
                    transition: theme => theme.transitions.create(["width"])
                } : {
                    display: "none"
                }]}
            flexDirection="column"
            spacing={1}
        >
            <Box
                sx={{
                    minHeight: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                {store.isClientExpanded ? <OutlinedTextField
                        value={store.clientsFilterString}
                        onChange={store.onChangeClientsFilterStringHandler}
                        placeholder="Поиск"
                        fullWidth
                        clearable
                    />
                    : <IconButton
                        size="small"
                        onClick={() => store.onChangeClientExpanded(true)}
                    >
                        <SearchIcon fontSize="large"/>
                    </IconButton>}
            </Box>
            <OverlayFlexContainer>
                {store.isClientsEmpty
                    ? store.isClientExpanded
                        ? <Box sx={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography color="textSecondary">
                                Список клиентов пуст
                            </Typography>
                        </Box>
                        : null
                    : store.filteredClients.length === 0
                        ? <Box sx={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography color="textSecondary">
                                Клиенты не найдены
                            </Typography>
                        </Box>
                        : store.filteredClients
                            .map(client => <ClientListItem
                                key={client.id}
                                client={client}
                                countOfUnreadMessages={store.counterOfUnreadClientsMessages[client.id!] ?? 0}
                                onSelect={store.onChangeSelectedClientHandler}
                                selected={client.id === store.selectedClient?.id}
                                fullWidth={store.isClientExpanded}
                            />)}
            </OverlayFlexContainer>
        </Stack>
    </ClickAwayListener>
})