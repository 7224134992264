import {
    Box,
    Button,
    Divider as MuiDivider,
    IconButton, ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem
} from "@mui/material";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, {createContext, useContext, useState} from "react";
import {Nullable} from "../../types";
import {useMainAppStore} from "../components/mainApp";
import _ from "underscore";
import {observer} from "mobx-react";
import {UserAvatar} from "../components";

interface ButtonMenuItemContextType {
    setMenuAnchorElement: (element: Nullable<HTMLElement>) => void;
}

const ButtonMenuItemContext = createContext<ButtonMenuItemContextType>(undefined!);

const useButtonMenuItemContext = () => useContext(ButtonMenuItemContext);

export interface ItemProps {
    onClick?: VoidFunction;
    icon?: React.ReactNode;
    avatar?: Nullable<string>;
    primaryText?: React.ReactNode;
    secondaryText?: React.ReactNode;
    selected?: boolean;
    disabled?: boolean;
    allowedRoles?: string[];
}

const Item = observer((props: ItemProps) => {
    const mainAppStore = useMainAppStore();

    const {setMenuAnchorElement} = useButtonMenuItemContext();
    const {onClick, icon, primaryText, secondaryText, selected, disabled, allowedRoles = [], avatar} = props;

    const allowedAccess = allowedRoles.length === 0
        || _.contains(allowedRoles, mainAppStore.userRole?.systemName);

    const onClickHandler = () => {
        if (onClick) {
            onClick();
            setMenuAnchorElement(null);
        }
    }

    return allowedAccess
        ? <MenuItem
            onClick={onClickHandler}
            selected={selected}
            disabled={disabled}
        >
            {avatar != null
                ? <ListItemAvatar>
                    <UserAvatar src={avatar}/>
                </ListItemAvatar>
                : icon && <ListItemIcon>
                {icon}
            </ListItemIcon>}
            <ListItemText
                primary={primaryText}
                secondary={secondaryText}
            />
        </MenuItem>
        : null;
})

const Divider = () => <MuiDivider/>

export interface ButtonMenuProps {
    children?: React.ReactNode;
    label?: React.ReactNode;
    icon?: React.ReactNode;
    type?: "button" | "icon";
    size?: "small" | "medium";
    disabled?: boolean;
}

const ButtonMenu = (props: ButtonMenuProps) => {
    const [menuAnchorElement, setMenuAnchorElement] = useState<Nullable<HTMLElement>>(null);
    const {children, size = "medium", label = "Меню", icon = MoreVertIcon, type = "icon", disabled} = props;
    const [value] = useState<ButtonMenuItemContextType>({setMenuAnchorElement: element => setMenuAnchorElement(element)})

    return <ButtonMenuItemContext.Provider value={value}>
        <Box>
            {type === "button"
                ? <Button
                    startIcon={icon}
                    disabled={disabled}
                >
                    {label}
                </Button>
                : <IconButton
                    onClick={e => setMenuAnchorElement(e.currentTarget)}
                    size={size}
                    disabled={disabled}
                >
                    {icon}
                </IconButton>}
            <Menu
                anchorEl={menuAnchorElement}
                keepMounted
                open={Boolean(menuAnchorElement)}
                onClose={() => setMenuAnchorElement(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                {children}
            </Menu>
        </Box>
    </ButtonMenuItemContext.Provider>
}

export default Object.assign(ButtonMenu, {Item, Divider});