export const PHONE_PATTERN: RegExp = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
export const LOGIN = "mindsafe_login";
export const PASSWORD = "mindsafe_password";
export const REMEMBER_ME = "mindsafe_remember_me";
export const FINGER_PRINT = "mindsafe_finger_print";
export const AUTH_USER_ID = "mindsafe_auth_user_id";
export const QS_SECRET_KEY = "secret";
export const QS_USER_ID_KEY = "user-id";
export const PRIMARY_MAIN_COLOR = "#14539A";
export const SECONDARY_MAIN_COLOR = "#A90000";
export const ACCESS_TOKEN_DATA = "mindsafe_access_token_data";

export const VERSION = `mindsafe_${process.env.REACT_APP_PROJECT_NAME}_version`;