import { ClearButton } from "./ClearButton";
import { Label } from "./Label";
import { OpenIcon } from "./OpenIcon";
import { OutlinedBaseComponent } from "./OutlinedBaseComponent";
import { SearchPanel } from "./SearchPanel";
import { Select2Context } from "./contexts";
import { Select2Props } from "./types";
import { Select2Store } from "./stores";
import { Value } from "./Value";
import { observer } from "mobx-react";
import { useMemo } from "react";

export const Select2 = observer(<TValue,>(props: Select2Props<TValue>) => {
	const store = useMemo(() => new Select2Store<TValue>(props), [props]);

	return <Select2Context.Provider value={store}>
		<OutlinedBaseComponent>
			<Label />
			<Value />
			<OpenIcon />
			<ClearButton />
			<SearchPanel />
		</OutlinedBaseComponent>
	</Select2Context.Provider>;
})
