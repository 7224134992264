import {StoreBase} from "./StoreBase";
import {MainAppStore} from "../ui/components/mainApp";
import {useLayoutEffect} from "react";

export const useStoreLifeCycle = <TAppStore extends MainAppStore, TStore extends StoreBase<TAppStore>>(store: TStore) => {
    useLayoutEffect(() => {
        store.componentDidMount().then();
        return () => {
            store.componentDidUnmount().then();
        }
    }, [store]);
}