import {Link, Typography} from "@mui/material";

export const MindSafeUserDataPolicyText = () => {
    return <Typography
        color="primary"
        variant="caption"
    >
        Я ознакомился и полностью согласен с <Link href="/documents/MindsafeUserDataPolicy.pdf"
                                                   underline="always" target="_blank">политикой обработки
        персональных данных</Link>
    </Typography>
}