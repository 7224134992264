import {FC, ReactNode} from "react";
import {Navigate, useLocation} from "react-router";

import {LoggedOut, Logging} from "../../surfaces";
import {AuthState} from "./types/AuthState";
import {Nullable} from "../../../types";
import {observer} from "mobx-react";
import {useMainAppStore} from "../mainApp";
import appSettings from "../../../appSettings";

export const AuthRoute: FC = observer(({children}) => {
    const location = useLocation();
    const mainAppStore = useMainAppStore();

    const returnUrl = `${location.pathname}${encodeURIComponent(location.search)}`;
    const loginRoute = appSettings.getRoute("login");
    const redirectToLogin = returnUrl ? `${loginRoute}?returnUrl=${returnUrl}` : loginRoute;

    let element: Nullable<ReactNode> = null;

    switch (mainAppStore.authStore.state) {
        case AuthState.Authorization:
            element = <Logging/>;
            break;

        case AuthState.Authorized:
            element = children;
            break;

        case AuthState.Unauthorized:
            element = <Navigate to={redirectToLogin}/>;
            break;

        case AuthState.LoggedOut:
            element = <LoggedOut/>;
            break;
    }

    return <>{element}</>;
})