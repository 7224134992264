import {observer} from "mobx-react";
import {useMemo} from "react";
import {ClientSessionsStore} from "./ClientSessionsStore";
import {DataGrid} from "@mui/x-data-grid";
import {useTherapistAppStore} from "../../hooks/useTherapistAppStore";
import {useStoreLifeCycle} from "../../../ms-ui/stores";
import {MainAppContent} from "../../../ms-ui/ui/components/mainApp";

export const ClientSessions = observer(() => {
    const therapistAppStore = useTherapistAppStore();
    const store = useMemo(() => new ClientSessionsStore(therapistAppStore), []);

    useStoreLifeCycle(store);

    return <MainAppContent sx={{ p: 1 }}>
        <DataGrid
            columns={store.columns}
            rows={store.sessions}
            disableColumnFilter
            disableColumnMenu
            density="compact"
            hideFooter
        />
    </MainAppContent>
})