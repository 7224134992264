import {Box, IconButton, Stack, Tooltip, Typography} from "@mui/material";

import dayjs from "dayjs";
import parser from "html-react-parser";

import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {MessageKind} from "../../../commons";
import {MessageViewModel} from "../../../models/entities";
import ReplyIcon from "@mui/icons-material/Reply";
import {amber, grey, orange, yellow} from '@mui/material/colors';

export interface MessageProps {
    message: MessageViewModel;
    onMarkReply?: (message: MessageViewModel) => void;
}

export const Message = ({message, onMarkReply}: MessageProps) => {
    return <Stack
        data-id={message.id}
        sx={[
            {
                position: "relative",
                p: 0.5,
                mb: 1,
                mx: "16px"
            },
            message.kind === MessageKind.SystemMessage && {
                border: `1px solid ${amber[700]}`,
                borderRadius: "12px",
                backgroundColor: amber[100]
            },
            message.kind === MessageKind.ClientMessage && {
                border: `1px solid ${grey[400]}`,
                borderRadius: "0 12px 12px 12px",
                backgroundColor: grey[50]
            },
            message.kind === MessageKind.TherapistMessage && {
                border: `1px solid ${yellow[500]}`,
                borderRadius: "12px 12px 0 12px",
                backgroundColor: yellow["A100"]
            }
        ]}
    >
        {message.kind === MessageKind.TherapistMessage && <Box
            sx={{
                position: "absolute",
                height: "16px",
                width: "16px",
                bottom: "-1px",
                right: "-16px",
                borderBottom: `1px solid ${yellow[500]}`,
                background: `radial-gradient(at 16px 0px, transparent 15px, ${yellow[500]} 15px, ${yellow[500]} 16px, ${yellow["A100"]} 16px)`
            }}
        >
        </Box>}
        {message.kind === MessageKind.ClientMessage && <Box
            sx={{
                position: "absolute",
                height: "16px",
                width: "16px",
                top: "-1px",
                left: "-16px",
                borderTop: `1px solid ${grey[400]}`,
                background: `radial-gradient(at 0px 16px, transparent 15px, ${grey[400]} 15px, ${grey[400]} 16px, ${grey[50]} 16px)`
            }}
        >
        </Box>}
        {message.reply && <Stack
            sx={[
                message.reply.kind === MessageKind.ClientMessage && {
                    border: `1px solid ${orange[500]}`,
                    backgroundColor: orange[100]
                },
                message.reply.kind === MessageKind.TherapistMessage && {
                    border: `1px solid ${yellow["A700"]}`,
                    backgroundColor: yellow[300]
                },
                {
                    p: 0.5,
                    borderRadius: "8px",
                    borderLeftWidth: "8px"
                }
            ]}
            spacing={0.5}
        >
            <Typography
                variant="subtitle2"
                noWrap
                sx={[
                    message.reply.kind === MessageKind.ClientMessage && {
                        color: orange[700]
                    },
                    message.reply.kind === MessageKind.TherapistMessage && {
                        color: yellow[800]
                    }]}
            >
                {message.reply.kind === MessageKind.ClientMessage && (message.reply?.client?.nickName ?? "Клиент")}
                {message.reply.kind === MessageKind.TherapistMessage && "Терапевт"}
            </Typography>
            <Typography
                variant="body2"
                noWrap
            >
                {message.reply.text}
            </Typography>
        </Stack>}
        <Typography
            variant="body2"
            p={0.5}
        >
            {parser(message.text?.replace(/\n/g, "<br />") ?? "")}
        </Typography>
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
        >
            <Tooltip title={dayjs(message.createdDateTime).format("DD.MM.YY HH:mm")}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {dayjs(message.createdDateTime).format("HH:mm")}
                </Typography>
            </Tooltip>
            {message.isRead
                ? <DoneAllIcon fontSize="small"/>
                : <DoneIcon fontSize="small"/>}
            {message.kind !== MessageKind.SystemMessage && <IconButton
                size="small"
                onClick={() => onMarkReply && onMarkReply(message)}
                disabled={!onMarkReply}
            >
                <ReplyIcon fontSize="small"/>
            </IconButton>}
        </Stack>
    </Stack>
}