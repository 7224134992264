import {Avatar, Badge, Box, ButtonBase, Stack, Typography} from "@mui/material";
import {stringToColor} from "../../../ms-ui/helpers";
import {ClientViewModel} from "../../../models/entities";
import {grey} from "@mui/material/colors";

interface ClientListItemProps {
    client: ClientViewModel;
    countOfUnreadMessages: number;
    onSelect: (client: ClientViewModel) => void;
    selected?: boolean;
    fullWidth?: boolean;
}

export const ClientListItem = ({client, countOfUnreadMessages, onSelect, selected, fullWidth}: ClientListItemProps) => {
    return <ButtonBase
        onClick={() => onSelect(client)}
        sx={[
            {
                height: "60px",
                "& > div:not(:last-of-type)": {
                    mr: 1
                }
            },
            Boolean(selected) && {
                backgroundColor: grey[200]
            }
        ]}
    >
        <Box sx={{width: "60px"}}>
            <Badge
                overlap="circular"
                badgeContent={countOfUnreadMessages}
                max={99}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                color="primary"
            >
                <Avatar
                    sx={{
                        bgcolor: stringToColor(client.nickName!)
                    }}
                    src={client.avatar ?? undefined}
                >
                    {client.nickName}
                </Avatar>
            </Badge>
        </Box>
        {fullWidth && <Stack
            sx={{
                flex: 1,
                overflow: "hidden"
            }}
            spacing={0.5}
        >
            <Typography
                variant="subtitle2"
                align="left"
                noWrap
            >
                {client.nickName!}
            </Typography>
            <Typography
                variant="caption"
                color="textSecondary"
                align="left"
                noWrap
            >
                {client.about!}
            </Typography>
        </Stack>}
    </ButtonBase>
}