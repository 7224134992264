import {Backdrop} from "@mui/material";
import {TailSpin} from "react-loader-spinner";
import {makeStyles} from "@mui/styles";
import {PRIMARY_MAIN_COLOR} from "../../commons";

const useStyles = makeStyles(theme => ({
    backdrop: {
        color: '#fff',
        zIndex: 9000
    }
}))

interface LoadingIndicatorProps {
    loading: boolean;
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
    const classes = useStyles();

    return <Backdrop className={classes.backdrop} open={props.loading}>
        <TailSpin height={100} width={100} color={PRIMARY_MAIN_COLOR}/>
    </Backdrop>
}
