import {useSearchParams} from "react-router-dom";

import {useEffect, useMemo} from "react";
import {Alert, Typography} from "@mui/material";
import {observer} from "mobx-react";
import {useMainAppStore} from "../mainApp";
import {ConfirmEmailStore} from "./ConfirmEmailStore";
import {BorderedBox} from "../../surfaces";

export const ConfirmEmail = observer(() => {
    const [searchParams] = useSearchParams();
    const mainAppStore = useMainAppStore();
    const store = useMemo(() => new ConfirmEmailStore(mainAppStore, searchParams), []);

    useEffect(() => {
        store.confirmEmail();
    }, []);

    return <BorderedBox>
        <Typography
            component="div"
            color="primary"
            align="center"
        >
            {store.message !== null && <Alert color={store.messageColor}>
                {store.message}
            </Alert>}
        </Typography>
    </BorderedBox>;
})