import {StorageHelper} from "./storageHelper";
import {ACCESS_TOKEN_DATA, FINGER_PRINT} from "../commons/consts";
import {Base64} from "js-base64";
import {v4} from "uuid";
import {ClientJS} from "clientjs";
import {Nullable, AccessTokenData} from "../types";
import dayjs from "dayjs";

export class AuthHelper {
    static validatePassword = (password: string): boolean => {
        // +пароль не пустой
        // +хотя бы одна прописная латинская буква
        // +хотя бы одна строчная латинская буква
        // +хотя бы одна цифра
        // -хотя бы один специальный символ из %, *, (, ), ?, @, #, $, ~, !, +
        // -длинная пароля от 6 символов
        return password !== ""
            && /[A-Z]+/.test(password)
            && /[a-z]+/.test(password)
            && /[0-9]+/.test(password)
            /*&& /[%, *, (, ), ?, @, #, $, ~, !, +]+/.test(password)*/
            && (password.length >= 8);
    }

    /**
     * [fingerPrint, browser, os, timeZone, userAgent]
     */
    static getAuthData = (): [string, string, string, string, string] => {
        let fingerPrint: string;
        const encodedFingerPrint = StorageHelper.getItem(FINGER_PRINT);

        if (encodedFingerPrint !== null) {
            fingerPrint = Base64.decode(encodedFingerPrint);
        } else {
            fingerPrint = v4();
            StorageHelper.setItem(FINGER_PRINT, Base64.encode(fingerPrint));
        }

        const clientJs = new ClientJS();
        return [fingerPrint, clientJs.getBrowser(), clientJs.getOS(), clientJs.getTimeZone(), clientJs.getUserAgent()];
    }

    static getAccessTokenData = () => {
        const encodedAccessTokenData = StorageHelper.getItem(ACCESS_TOKEN_DATA, sessionStorage);
        return encodedAccessTokenData !== null
            ? JSON.parse(Base64.decode(encodedAccessTokenData)) as AccessTokenData
            : null;
    }

    static setAccessTokenData = (accessTokenData: Nullable<AccessTokenData>) => {
        const encodedAccessTokenData = accessTokenData !== null
            ? Base64.encode(JSON.stringify(accessTokenData))
            : null;
        StorageHelper.setItem(ACCESS_TOKEN_DATA, encodedAccessTokenData, sessionStorage);
    }

    static isAccessTokenDataExpired = (): boolean => {
        const accessTokenData = this.getAccessTokenData();
        return accessTokenData === null || dayjs(accessTokenData.accessTokenExpires).isAfter(dayjs());
    }
}