import { Box, Link, Stack, Typography } from "@mui/material";

export const AboutTab = () => {

    return <Box p={4}>
        <Stack
            alignItems="flex-start"
            spacing={1}
        >
            <Typography variant="h6">Общество с ограниченной ответственностью «Майнд Сейф»</Typography>

            <Typography variant="caption">Адрес для корреспонденции, направления жалоб и предложений:</Typography>
            <Typography>Россия, 625035, г. Тюмень, проезд Геологоразведчиков, д.24, кв.47</Typography>

            <Typography variant="caption">Юридический адрес:</Typography>
            <Typography>Россия, 108850, Г.Москва, поселение Внуковское, бульвар Андрея Тарковского, д. 9, кв. 138</Typography>

            <Typography variant="caption">ИНН:</Typography>
            <Typography>7751202740</Typography>

            <Typography variant="caption">КПП:</Typography>
            <Typography>775101001</Typography>

            <Typography variant="caption">ОГРН:</Typography>
            <Typography>1217700368126</Typography>

        </Stack>
        <Stack
            alignItems="flex-start"
            spacing={1}
        >
            <Typography variant="h6">Документы</Typography>

            <Link href="/documents/MindsafeLicense.pdf" underline="always" target="_blank">
                <Typography>Условия пользовательского соглашения</Typography>
            </Link>
            <Link href="/documents/MindsafeUserDataPolicy.pdf" underline="always" target="_blank">
                <Typography>Политика обработки персональных данных</Typography>
            </Link>
        </Stack>
    </Box>;
}