import {Box, Container, Link, Stack, Typography} from "@mui/material";

import {FC} from "react";
import {PRIMARY_MAIN_COLOR} from "../../commons";
import {LogoIcon} from "../icons/LogoIcon";
import {OverlayFlexContainer} from "../components/OverlayFlexContainer";

const BORDER_WIDTH = "2px";
const BORDER_OFFSET = `-${BORDER_WIDTH}`;

export interface BorderedBoxProps {
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
};

const box = {
    padding: {xs: "40px 10px 50px", sm: "20px 50px 25px"},
    minHeight: "200px",
    minWidth: "350px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    "&:before": {
        sm: {
            content: '""',
            position: "absolute",
            width: "35%",
            height: "200px",
            top: BORDER_OFFSET,
            left: BORDER_OFFSET,
            boxSizing: "border-box",
            borderTopWidth: BORDER_WIDTH,
            borderTopStyle: "solid",
            borderTopColor: PRIMARY_MAIN_COLOR,
            borderLeftWidth: BORDER_WIDTH,
            borderLeftStyle: "solid",
            borderLeftColor: PRIMARY_MAIN_COLOR,
            pointerEvents: "none",
            borderTopLeftRadius: "12px"
        }
    },
    "&:after": {
        sm: {
            content: '""',
            position: "absolute",
            width: "35%",
            height: "200px",
            right: BORDER_OFFSET,
            bottom: BORDER_OFFSET,
            boxSizing: "border-box",
            borderBottomWidth: BORDER_WIDTH,
            borderBottomStyle: "solid",
            borderBottomColor: PRIMARY_MAIN_COLOR,
            borderRightWidth: BORDER_WIDTH,
            borderRightStyle: "solid",
            borderRightColor: PRIMARY_MAIN_COLOR,
            pointerEvents: "none",
            borderBottomRightRadius: "12px"
        }
    }
};

export const BorderedBox: FC<BorderedBoxProps> = ({maxWidth, children}) => {

    return <Container
        maxWidth={maxWidth ?? "sm"}
        fixed
        sx={{
            px: 0,
            py: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center"
        }}
    >
        <Box sx={box}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 1,
                    alignSelf: "center"
                }}
            >
                <LogoIcon
                    sx={{
                        mr: 1
                    }}
                    fontSize="large"
                    color="primary"
                />
                <Typography
                    color="primary"
                    variant="h3"
                    component="div"
                    align="center"
                >
                    <Link
                        href="http://mindsafe.ru"
                        underline="none"
                        target="_blank"
                        rel="noopener"
                    >
                        mindsafe
                    </Link>
                </Typography>
            </Box>
            <OverlayFlexContainer>
                <Stack alignItems="center" spacing={1} my={1} px={{xs: 1, sm: 4}}>
                    {children}
                </Stack>
            </OverlayFlexContainer>
        </Box>
    </Container>;
};