import {
    Badge,
    Avatar as MuiAvatar,
    Box,
    Modal,
    Container,
    Typography,
    Slider,
    Stack, Paper
} from "@mui/material";
import {EnhancedContainedButton, EnhancedTooltipIconButton} from "../../controls";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {ChangeEvent, useRef, useState} from "react";
import Cropper, {Area} from "react-easy-crop";
import {Nullable} from "../../../types";
import {getCroppedImage} from "./utils/getCroppedImage";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface AvatarEditorProps {
    src?: Nullable<string>;
    onChange: (src: Nullable<string>) => void;
    maxOutputSrcDimensions?: { width: number, height: number }
}

export const AvatarEditor = ({
                                 src,
                                 onChange,
                                 maxOutputSrcDimensions = {width: 160, height: 160}
                             }: AvatarEditorProps) => {
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<Nullable<{ file: string, mimeType: string }>>(null);
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Nullable<Area>>(null);

    const onCropComplete = async (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }

    const onOpenModalHandler = () => {
        inputFileRef.current?.click();
    }

    const onCloseModalHandler = () => {
        setFile(null);
        setZoom(1);
        setCrop({x: 0, y: 0});
        setRotation(0);
        inputFileRef.current!.value = "";
    }

    const onChangeFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.addEventListener("load", (ev: ProgressEvent<FileReader>) => {
                setFile({file: ev.target!.result as string, mimeType: e.target!.type});
            });
        }
    }

    const onChangeHandler = async () => {
        if (file != null) {
            const cropImageSrc = await getCroppedImage(file.file, croppedAreaPixels, file.mimeType, rotation, maxOutputSrcDimensions);
            onChange(cropImageSrc);
            onCloseModalHandler();
        }
    }

    const onRemoveHandler = () => {
        if (src != null) {
            onChange(null);
        }
    }

    return <>
        <Badge
            overlap="circular"
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            badgeContent={<Box
                sx={{
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                    padding: "2px"
                }}
            >
                <EnhancedTooltipIconButton
                    title="Удалить"
                    onClick={onRemoveHandler}
                    disabled={src == null}
                    color="error"
                >
                    <DeleteForeverIcon fontSize="small"/>
                </EnhancedTooltipIconButton>
            </Box>}
        >
            <Badge
                overlap="circular"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                badgeContent={<Box
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        padding: "2px"
                    }}
                >
                    <EnhancedTooltipIconButton
                        title="Редактировать"
                        onClick={onOpenModalHandler}
                        color="primary"
                    >
                        <CameraAltIcon fontSize="small"/>
                    </EnhancedTooltipIconButton>
                </Box>}
            >
                <MuiAvatar
                    sx={{
                        height: maxOutputSrcDimensions?.height,
                        width: maxOutputSrcDimensions?.width
                    }}
                    alt="Аватар"
                    src={src ?? undefined}
                >
                </MuiAvatar>
            </Badge>
        </Badge>
        <Box sx={{display: "none"}}>
            <input
                accept="image/*"
                ref={inputFileRef}
                type="file"
                onChange={onChangeFileHandler}
            />
        </Box>
        <Modal
            open={Boolean(file)}
            onClose={onCloseModalHandler}
        >
            <Container
                maxWidth="md"
                fixed
                sx={{
                    height: "100%",
                    padding: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        width: "100%",
                        height: "500px"
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            right: "16px",
                            top: "16px",
                            zIndex: 100,
                            border: "1px solid #fff",
                            borderRadius: "50%"
                        }}
                    >
                        <EnhancedTooltipIconButton
                            title="Отменить"
                            onClick={onCloseModalHandler}
                            sx={{
                                color: "#fff"
                            }}
                        >
                            <CloseIcon/>
                        </EnhancedTooltipIconButton>
                    </Box>
                    <Cropper
                        image={file?.file}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        cropShape="round"
                        aspect={1}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </Box>
                <Paper sx={{
                    p: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    "& > *": {flex: 1, "&:not(:last-child)": {mr: 1}}
                }}>
                    <Stack direction="row">
                        <Typography variant="overline" mr={2}>
                            Увеличение
                        </Typography>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            onChange={(e, zoom) => setZoom(zoom as number)}
                            marks
                        />
                    </Stack>
                    <Stack direction="row">
                        <Typography variant="overline" mr={2}>
                            Поворот
                        </Typography>
                        <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={10}
                            onChange={(e, rotation) => setRotation(rotation as number)}
                            marks
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <EnhancedContainedButton
                            sx={{mr: 1}}
                            color="primary"
                            onClick={onChangeHandler}
                        >
                            Сохранить
                        </EnhancedContainedButton>
                        <EnhancedContainedButton
                            color="error"
                            onClick={onCloseModalHandler}
                        >
                            Отменить
                        </EnhancedContainedButton>
                    </Stack>
                </Paper>
            </Container>
        </Modal>
    </>
}