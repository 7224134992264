import { Button } from "@mui/material";
import { EnhancedButtonProps } from "../../types";
import { FC } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
	root: {
		minWidth: 0
	},
	outlinedSizeSmall: {
		padding: "3px 4px"
	},
	containedSizeSmall: {
		padding: "4px 6px"
	}
}))

export const EnhancedButton: FC<EnhancedButtonProps> = (props) => {
	const { root, outlinedSizeSmall, containedSizeSmall } = useStyles();

	return <Button
		size="small"
		classes={{
			root,
			outlinedSizeSmall,
			containedSizeSmall
		}}
		{...props}
	>
		{props.children}
	</Button>
}
