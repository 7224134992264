import {Box, MenuItem} from "@mui/material";
import {useMemo} from "react";

import MuiPhoneNumber from "material-ui-phone-number";
import {ProfileTabStore} from "./stores/ProfileTabStore";
import {observer} from "mobx-react";
import {AvatarEditor} from "../../../ms-ui/ui/components/avatarEditor/AvatarEditor";
import {
    EnhancedContainedButton,
    OutlinedDateTimePicker,
    OutlinedEnhancedSelect,
    OutlinedTextField
} from "../../../ms-ui/ui/controls";
import {useTherapistAppStore} from "../../hooks/useTherapistAppStore";
import {EmailConfirmEditor} from "../../../ms-ui/ui/components";
import {Sex} from "../../../ms-ui/commons";

export const ProfileTab = observer(() => {
    const therapistAppStore = useTherapistAppStore();
    const store = useMemo(() => new ProfileTabStore(therapistAppStore), []);

    return <Box
        sx={{
            p: 4,
            "& > *:first-of-type": {
                mb: 10
            },
            "& > *:not(:first-of-type)": {
                mb: 1
            }
        }}
    >
        <EmailConfirmEditor
            email={store.email}
            emailConfirmed={store.emailConfirmed}
            emailToBeConfirmed={store.emailToBeConfirmed}
            onChangeEmail={store.changeEmailHandler}
            onCancelChangeEmail={store.cancelChangeEmailHandler}
            onConfirmationEmailResend={store.confirmationEmailResendHandler}
        />
        <Box sx={{display: "flex", justifyContent: "center"}}>
            <AvatarEditor
                src={store.avatar}
                onChange={src => store.avatar = src}
            />
        </Box>
        <MuiPhoneNumber
            variant="outlined"
            defaultCountry={"ru"}
            onChange={value => store.phoneNumber = value === "" ? null : value as string}
            value={store.phoneNumber}
            size="small"
            label="Телефон"
            fullWidth
        />
        <OutlinedTextField
            label="Фамилия"
            value={store.lastName}
            onChange={value => store.lastName = value}
            fullWidth
        />
        <OutlinedTextField
            label="Имя"
            value={store.firstName}
            onChange={value => store.firstName = value}
            fullWidth
        />
        <OutlinedTextField
            label="Отчество"
            value={store.middleName}
            onChange={value => store.middleName = value}
            fullWidth
        />
        <OutlinedDateTimePicker
            label="Дата рождения"
            value={store.birthDate}
            onChange={value => store.birthDate = value}
            size="small"
            type="date"
        />
        <OutlinedEnhancedSelect
            label="Пол"
            value={store.sex}
            onChange={value => store.sex = value}
            fullWidth
        >
            <MenuItem value={Sex.None}>Не выбран</MenuItem>
            <MenuItem value={Sex.Female}>Женщина</MenuItem>
            <MenuItem value={Sex.Male}>Мужчина</MenuItem>
        </OutlinedEnhancedSelect>
        <EnhancedContainedButton
            color="primary"
            onClick={store.editProfile}
        >
            Сохранить
        </EnhancedContainedButton>
    </Box>
});