import {FC} from "react";
import {MainAppStore} from "../../stores/MainAppStore";
import {MainAppContext} from "../../contexts/MainAppContext";
import {observer} from "mobx-react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {SetPassword} from "../../../setPassword/SetPassword";
import {ConfirmEmail} from "../../../confirmEmail/ConfirmEmail";
import {RecoveryPassword} from "../../../recoveryPassword/RecoveryPassword";
import {Lce} from "../../../../feedback";
import {WarningAboutRefreshSite} from "../../../WarningAboutRefreshSite";
import appSettings from "../../../../../appSettings";

export const MainAppProvider: FC<{ store: MainAppStore }> = observer(({store, children}) => {

    return <MainAppContext.Provider value={store}>
        <Lce/>
        <WarningAboutRefreshSite/>
        <Router>
            <Routes>
                <Route
                    path={appSettings.getRoute("setPassword")}
                    element={<SetPassword/>}
                />
                <Route
                    path={appSettings.getRoute("confirmEmail")}
                    element={<ConfirmEmail/>}
                />
                <Route
                    path={appSettings.getRoute("passwordRecovery")}
                    element={<RecoveryPassword/>}
                />
                {children}
            </Routes>
        </Router>
    </MainAppContext.Provider>
})