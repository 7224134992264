export const QS_SECURITY_KEY = "security";

export const QS_EMAIL_KEY = "email";

export const MAX_MESSAGE_LENGTH = 4096;

export const UPDATE_MESSAGES_STATUS_TIMEOUT = 1000;

export const MESSAGE_EDITOR_TEMP_STORE = "mindsafe_message_editor_store";

export const MESSAGE_EDITOR_SENDING_KEY = "mindsafe_message_editor_sending_key";

export const IS_CHAT_BACKGROUND_ENABLED = "mindsafe_is_chat_background_enabled";