import { IconButton, Tooltip } from "@mui/material";

import { EnhancedTooltipIconButtonProps } from "../../types";
import { FC } from "react";

export const EnhancedTooltipIconButton: FC<EnhancedTooltipIconButtonProps> = (props) => {
	const { title, ...otherProps } = props;

	return <Tooltip title={title}>
		<span>
			<IconButton
				size="small"
				{...otherProps}
			/>
		</span>
	</Tooltip>
}
