import {Link, Typography} from "@mui/material";

export const MindSafeLicenseText = () => {
    return <Typography
        color="primary"
        variant="caption"
    >
        Я
        ознакомился
        и
        полностью
        согласен
        с <Link
        href="/documents/MindsafeLicense.pdf"
        underline="always"
        target="_blank"> условиями
        пользовательского
        соглашения </Link>
    </Typography>
}