import {StoreBase} from "../../../stores";
import {MainAppStore} from "../mainApp";
import {computed, makeObservable, observable} from "mobx";
import {LoginHelper} from "../../../helpers";

export class LoginStore extends StoreBase<MainAppStore> {

    login: string = LoginHelper.getLogin();
    password: string = LoginHelper.getPassword();
    rememberMe: boolean = LoginHelper.getRememberMe();

    constructor(mainAppStore: MainAppStore, private _onLoginPostAction: VoidFunction) {
        super(mainAppStore);

        makeObservable(this, {
            login: observable,
            password: observable,
            rememberMe: observable,
            loginBtnDisabled: computed
        })
    }

    onLoginChange = (login: string) => {
        if (this.appStore.error) {
            this.appStore.error = null;
        }

        this.login = login.trim();

        if (this.login === "") {
            this.password = "";
        }
    }

    onPasswordChange = (password: string) => {
        if (this.appStore.error) {
            this.appStore.error = null;
        }

        this.password = password;
    }

    onRememberMeChange = (checked: boolean) => {
        if (!checked) {
            LoginHelper.setLogin(null);
            LoginHelper.setPassword(null);
        }

        this.rememberMe = checked;
        LoginHelper.setRememberMe(checked);
    }

    onLogin = async () => {
        if (this.loginBtnDisabled) {
            return;
        }

        return this.execute(() => {
            return this.appStore.authStore.onLogin(
                this.login,
                this.password,
                () => {
                    if (this.rememberMe) {
                        LoginHelper.setLogin(this.login);
                        LoginHelper.setPassword(this.password);
                        LoginHelper.setRememberMe(this.rememberMe);

                    }
                    this._onLoginPostAction();
                }
            );
        });
    }

    get loginBtnDisabled() {
        return this.login.trim() === "" || this.password.trim() === "";
    }
}