import {Paper} from "@mui/material";
import {FC} from "react";
import {OverlayFlexContainer} from "../OverlayFlexContainer";

interface TabPanelProps {
    index: number;
    value: number;
}

export const TabPanel: FC<TabPanelProps> = ({index, value, children}) => {

    return index === value
        ? <Paper
            sx={{
                flex: 1,
                overflow: "hidden"
            }}
        >
            <OverlayFlexContainer sx={{height: "100%"}}>
                {children}
            </OverlayFlexContainer>
        </Paper>
        : null
}