import { EnhancedTooltipIconButton } from "./EnhancedTooltipIconButton";
import { EnhancedTooltipIconButtonProps } from "../../types";
import { Theme } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>(theme => ({
	warning: {
		color: theme.palette.warning.main
	}
}))

export const WarningTooltipIconButton = (props: EnhancedTooltipIconButtonProps) => {
	const classes = useStyles();

	if (!props.title) {
		return null;
	}

	return <EnhancedTooltipIconButton {...props}>
		<WarningIcon
			className={classes.warning}
			fontSize="small"
		/>
	</EnhancedTooltipIconButton>
}
