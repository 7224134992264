import {ReactNode} from "react";
import {Box, FormControl, FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Nullable} from "../../../types";

interface EmailEditorProps {
    label: string;
    email: Nullable<string>;
    onChangeEmail?: (email: Nullable<string>) => void;
    confirmed?: boolean;
    helperText?: ReactNode;
    error?: boolean;
    disabled?: boolean
}

export const EmailEditor = (props: EmailEditorProps) => {
    return <Box
        sx={{position: "relative", pr: 4}}
    >
        <FormControl
            variant="outlined"
            size="small"
            disabled={props.disabled}
            error={props.error}
            fullWidth
        >
            <InputLabel>
                {props.label}
            </InputLabel>
            <OutlinedInput
                label={props.label}
                //placeholder="email@domain.com"
                value={props.email ?? ""}
                onChange={({target: {value}}) => props.onChangeEmail && props.onChangeEmail(value.trim() === "" ? null : value.trim())}
                inputMode="email"
            />
            {props.helperText && <FormHelperText>
                {props.helperText}
            </FormHelperText>}
        </FormControl>
        {props.confirmed && <CheckCircleIcon
            sx={{
                position: "absolute",
                top: "50%",
                right: "4px",
                transform: "translateY(-50%)"
            }}
            color="success"
        />}
    </Box>;
}