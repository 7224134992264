import {Box} from "@mui/material";
import {Outlet} from "react-router-dom";

export const MainAppOutlet = () => {
    return <Box
        sx={{
            flexGrow: 1,
            overflow: "hidden"
        }}
    >
        <Outlet/>
    </Box>
}