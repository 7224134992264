import {LOGIN, PASSWORD, REMEMBER_ME} from "../commons";

import {Base64} from "js-base64";
import {StorageHelper} from "./storageHelper";
import {Nullable} from "../types";

export class LoginHelper {
    /**
     * Получение логина пользователя из локального хранилища, и преобразование его в base64-строку
     * @returns Логин пользователя
     */
    static getLogin = (): string => {
        const encodedLogin = StorageHelper.getItem(LOGIN);
        return encodedLogin !== null
            ? Base64.decode(encodedLogin)
            : "";
    }

    /**
     * Добавление логина пользователя в локальное хранилище
     * @param login Логин пользователя
     * @returns
     */
    static setLogin = (login: Nullable<string>) => {
        const encodedLogin = login !== null
            ? Base64.encode(login)
            : null;
        StorageHelper.setItem(LOGIN, encodedLogin);
    }

    /**
     * Получение пароля пользователя из локального хранилища
     * @returns Пароль пользователя
     */
    static getPassword = () => {
        const encodedPassword = StorageHelper.getItem(PASSWORD);
        return encodedPassword !== null
            ? Base64.decode(encodedPassword)
            : "";
    }

    /**
     * Добавление пароля пользователя в локальное хранилище, и преобразование его в base64-строку
     * @param password
     */
    static setPassword = (password: Nullable<string>) => {
        const encodedPassword = password !== null
            ? Base64.encode(password)
            : null;
        StorageHelper.setItem(PASSWORD, encodedPassword);
    }

    /**
     * Получение значения признака заполнить меня
     * @returns
     */
    static getRememberMe = () => StorageHelper.getItem<boolean>(REMEMBER_ME) ?? true;

    /**
     * Добавление значения признака заполнить меня
     * @param rememberMe
     * @returns
     */
    static setRememberMe = (rememberMe: Nullable<boolean>) => StorageHelper.setItem(REMEMBER_ME, rememberMe);
}