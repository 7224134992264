import {createImageElement} from "./createImageElement";
import {getRadianAngle} from "./getRadianAngle";
import {rotateSize} from "./rotateSize";

export const getCroppedImage = async (
    imageSrc: string,
    pixelCrop: any,
    mimeType: string,
    rotation: number = 0,
    maxOutputSrcDimensions: { width: number, height: number },
    flip = {horizontal: false, vertical: false}) => {
    const image = await createImageElement(imageSrc);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (ctx == null) {
        throw new Error("Нвозможно создать ctx");
    }

    const rotRad = getRadianAngle(rotation)

    const {width: bBoxWidth, height: bBoxHeight} = rotateSize(image.width, image.height, rotation);
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)
    ctx.drawImage(image, 0, 0)

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
        throw new Error("Нвозможно создать croppedCtx");
    }

    croppedCanvas.width = maxOutputSrcDimensions.width;
    croppedCanvas.height = maxOutputSrcDimensions.height;

    croppedCtx.drawImage(
        canvas,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        maxOutputSrcDimensions.width,
        maxOutputSrcDimensions.height
    );

    return croppedCanvas.toDataURL(mimeType, 0.8);
}