import {StoreBase} from "../../../stores";
import {MainAppStore} from "../mainApp";
import {makeObservable, observable} from "mobx";
import {View} from "./types/View";
import {HttpClient} from "../../../http";
import {ResponseBase} from "../../../models/responses/ResponseBase";
import appSettings from "../../../appSettings";

export class RecoveryPasswordStore extends StoreBase<MainAppStore> {
    email: string = this._searchParams.get("email")?.trim() ?? "";
    view: View = View.RecoveryForm;

    constructor(mainAppStore: MainAppStore, private _searchParams: URLSearchParams) {
        super(mainAppStore);

        makeObservable(this, {
            email: observable,
            view: observable
        });
    }

    recovery = async () => {
        if (Boolean(this.appStore.error)) {
            this.appStore.error = null;
        }

        return this.execute(async () => {
            const request = appSettings.getService("passwordRecovery")({request: {email: this.email}});
            await new HttpClient()
                .execute<ResponseBase>(request);
            this.view = View.SuccessForm;
        });
    }
}