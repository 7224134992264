import {
    GetMessageListRequest,
    SendInvitationsRequest,
    TherapistRegistrationRequest,
    UpdateMessageStatusRequest
} from "./models/requests";
import {AppRoutes} from "./ms-ui/settings/types/AppRoutes";
import {AppServices} from "./ms-ui/settings/types/AppServices";
import {
    AppSettingsEmptyGetConfig,
    AppSettingsGetConfig,
    AppSettingsPostConfig
} from "./ms-ui/settings/types/AppSettingsConfig";
import {MessageViewModel, TherapistViewModel} from "./models/entities";
import {TherapistChangeStatusRequest} from "./models/requests/TherapistChangeStatusRequest";
import {SessionTherapistChangeRequest} from "./models/requests/SessionTherapistChangeRequest";
import appSettings from "./ms-ui/appSettings";


export interface TherapistAppRoutes extends AppRoutes {
    settings: string;
    chat: string;
    invitations: string;
    clientSessions: string;
}

export interface TherapistAppServices extends AppServices {
    registration: AppSettingsPostConfig<TherapistRegistrationRequest>;
    getUserById: AppSettingsGetConfig<"userId">;
    editUser: AppSettingsPostConfig<TherapistViewModel>;
    changeStatus: AppSettingsPostConfig<TherapistChangeStatusRequest>;
    changeTherapist: AppSettingsPostConfig<SessionTherapistChangeRequest>;
    sessionList: AppSettingsEmptyGetConfig;
    therapistList: AppSettingsEmptyGetConfig;
    invitationList: AppSettingsEmptyGetConfig;
    sendInvitations: AppSettingsPostConfig<SendInvitationsRequest>;
    reSendInvitation: AppSettingsGetConfig<"id">;
    cancelInvitation: AppSettingsGetConfig<"id">;
    clientList: AppSettingsEmptyGetConfig;
    getActivePayment: AppSettingsGetConfig<"userId">;
    sendMessage: AppSettingsPostConfig<MessageViewModel>;
    unreadMessagesCountPerClient: AppSettingsEmptyGetConfig;
    messageList: AppSettingsPostConfig<GetMessageListRequest>;
    updateMessageStatus: AppSettingsPostConfig<UpdateMessageStatusRequest>;
}

const therapistAppSettings = appSettings.castTo<TherapistAppRoutes, TherapistAppServices>();

therapistAppSettings
    .addRoute("login", "/login")
    .addRoute("passwordRecovery", "/recovery-password")
    .addRoute("registration", "/registration")
    .addRoute("confirmEmail", "/confirm-email")
    .addRoute("setPassword", "/set-password")
    .addRoute("settings", "settings")
    .addRoute("chat", "chat")
    .addRoute("invitations", "invitations")
    .addRoute("clientSessions", "client-sessions")
    .addService("login", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/auth/therapist-login`,
        data: request,
        withCredentials: true
    }))
    .addService("logout", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/auth/therapist-logout`,
        withCredentials: true
    }))
    .addService("updateAccessToken", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/auth/therapist-update-access-token`,
        data: request,
        withCredentials: true
    }))
    .addService("getUserById", request => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/${request.userId}`
    }))
    .addService("getUserId", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/get-id`
    }))
    .addService("registration", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/registration`,
        data: request
    }))
    .addService("changeEmail", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/change-email`,
        data: request
    }))
    .addService("cancelChangeEmail", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/cancel-change-email`,
        data: request
    }))
    .addService("confirmEmail", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/confirm-email`,
        data: request
    }))
    .addService("confirmationEmailResend", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/confirmation-email-resend`,
        data: request
    }))
    .addService("editUser", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/edit`,
        data: request
    }))
    .addService("changePassword", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/change-password`,
        data: request
    }))
    .addService("setPassword", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/set-password`,
        data: request
    }))
    .addService("passwordRecovery", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/recovery-password`,
        data: request
    }))
    .addService("removeProfile", () => ({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/remove`
    }))
    .addService("changeStatus", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistProfile/change-status`,
        data: request
    }))
    .addService("sendInvitations", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/invitations/send`,
        data: request
    }))
    .addService("invitationList", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/invitations/list`
    }))
    .addService("reSendInvitation", request => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/invitations/resend/${request.id}`
    }))
    .addService("cancelInvitation", request => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/invitations/cancel/${request.id}`
    }))
    .addService("messageList", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistMessages/list`,
        data: request
    }))
    .addService("updateMessageStatus", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistMessages/update-status`,
        data: request
    }))
    .addService("sendMessage", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/therapistMessages/send`,
        data: request
    }))
    .addService("unreadMessagesCountPerClient", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/therapistMessages/unread-messages-count-per-client`
    }))
    .addService("sessionList", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/sessionsManagement/list`
    }))
    .addService("therapistList", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/therapists/list`
    }))
    .addService("changeTherapist", request => ({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}/api/sessionsManagement/change-therapist`,
        data: request
    }))
    .addService("clientList", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/therapistClients/list`
    }))
    .addService("getActivePayment", request => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/therapistClientSession/active/${request.userId}`
    }))
    .addService("getVersion", () => ({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}/api/software-components/therapist-ui`
    }))
    .freeze();

export {therapistAppSettings};

/*
 editClient: AxiosRequestConfigFunction<ClientViewModel>;

 services.editClient = request => ({
        method: "POST",
        url: `${GlobalAppSettings.routes.app}/api/clients/edit`,
        data: request
    });
*/