import { ListItemIcon, ListItemText, Menu, MenuItem, SvgIconProps } from "@mui/material";
import React, { ComponentType, ReactNode, createElement } from "react";

import { EnhancedTooltipIconButton } from "./EnhancedTooltipIconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Nullable } from "../../../types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
	listIconItem: {
		minWidth: "32px"
	}
}))

interface MenuTooltipIconButtonMenuItem {
	text: string;
	handler: VoidFunction;
	icon?: ComponentType<SvgIconProps>;
}

interface MenuTooltipIconButtonProps {
	icon?: ComponentType<SvgIconProps>;
	title?: ReactNode;
	menuItems: MenuTooltipIconButtonMenuItem[];
}

export const MenuTooltipIconButton = (props: MenuTooltipIconButtonProps) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);

	const onMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onMenuClose = () => {
		setAnchorEl(null);
	};

	const onMenuItemClick = (handler?: VoidFunction) => {
		setAnchorEl(null);
		handler && handler();
	}

	return <>
		<EnhancedTooltipIconButton
			title={props.title || "Открыть меню"}
			onClick={onMenuOpen}
		>
			{props.icon
				? createElement(props.icon, { fontSize: "small" })
				: <MoreVertIcon fontSize="small" />}
		</EnhancedTooltipIconButton>
		<Menu
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={onMenuClose}
		>
			{props.menuItems.map((item, idx) => <MenuItem
				key={item.text}
				onClick={() => onMenuItemClick(item.handler)}
			>
				{item.icon && <ListItemIcon
					className={classes.listIconItem}
				>
					{createElement(item.icon, { fontSize: "small" })}
				</ListItemIcon>}
				<ListItemText primary={item.text} />
			</MenuItem>)}
		</Menu>
	</>
}
