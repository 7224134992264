import {useState} from "react";
import {EnhancedContainedButton, OutlinedTextField} from "../../../ms-ui/ui/controls";
import {EnhancedDialog} from "../../../ms-ui/ui/components/EnhancedDialog";
import {Autocomplete, AutocompleteChangeReason, AutocompleteInputChangeReason, Chip, TextField} from "@mui/material";
import {ClearIcon} from "../../../ms-ui/ui/icons";
import {validateEmail} from "../../../ms-ui/helpers";

export interface InvitationModalProps {
    onSubmitEmails?: (emails: string[], message: string) => void
}

export const InvitationModal = (props: InvitationModalProps) => {
    const defaultMessage = "Приглашаю вас присоединиться к проекту MindSafe — международной платформе для текстовых консультаций между коучами, психологами и их клиентами.";

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState<string>("");
    const [emails, setEmails] = useState<string[]>([]);
    const [message, setMessage] = useState<string>(defaultMessage);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setEmails([]);
        setEmail("");
    };

    const onSubmitEmailHandler = () => {
        props.onSubmitEmails && props.onSubmitEmails(emails.filter(email => validateEmail(email)), message);
        onClose();
    }

    const onCloseHandler = () => {
        onClose();
    }

    const onChangeHandler = (value: string[], reason: AutocompleteChangeReason) => {
        if (reason === "createOption" && !emails.includes(email)) {
            setEmails([...emails, email]);
            setEmail("");
        }

        if (reason === "removeOption") {
            setEmails(value);
        }

        if (reason === "clear") {
            setEmails([]);
            setEmail("");
        }
    }

    const onInputChangeHandler = (value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === "input") {
            setEmail(value.trim());
        }
    }

    return <>
        <EnhancedContainedButton
            color="primary"
            onClick={onOpen}
        >
            Пригласить
        </EnhancedContainedButton>
        <EnhancedDialog
            title="Приглашение новых участников"
            open={open}
            submitButtonOption={{
                label: "Пригласить",
                onClick: onSubmitEmailHandler,
                disabled: emails.length === 0
            }}
            cancelButtonOption={{
                onClick: onCloseHandler
            }}
            fullWidth
            actions
            disallowBackdropClick
        >
            <Autocomplete
                clearIcon={<ClearIcon
                    fontSize="small"
                    color="error"
                />}
                freeSolo
                multiple
                value={emails}
                onChange={(e, value, reason, details) => {
                    onChangeHandler(value as string[], reason)
                }}
                renderInput={params => <TextField
                    label="Новые участники"
                    placeholder="Введите email адрес нового учаcтника"
                    {...params}
                />}
                renderTags={(values, getTagProps) => values
                    .map((value, index) => <Chip
                        size="small"
                        label={value}
                        color={validateEmail(value as unknown as string) ? "primary" : "error"}
                        {...getTagProps({index})}
                    />)}
                options={[]}
                size="small"
                onInputChange={(e, value, reason) => {
                    onInputChangeHandler(value, reason);
                }}
            />
            <OutlinedTextField
                label="Сообщение"
                value={message}
                onChange={value => setMessage(value)}
                multiline
                fullWidth
                rows={4}
            />
        </EnhancedDialog>
    </>
}
