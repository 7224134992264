import {observer} from "mobx-react";
import {Box, Paper, Tab, Tabs as MuiTabs, useMediaQuery, useTheme} from "@mui/material";
import {Children, ReactElement} from "react";
import {TabPanel} from "./TabPanel";
import {useSearchParams} from "react-router-dom";

interface ItemProps {
    label: string;
    component: ReactElement;
}

const Item = ({component}: ItemProps) => {
    return component;
}

interface TabsProps {
    children: ReactElement<ItemProps>[];
}

const Tabs = observer((props: TabsProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabIdx = searchParams.get("idx") !== null
    && parseInt(searchParams.get("idx")!) < Children.count(props.children)
        ? parseInt(searchParams.get("idx")!)
        : 0;

    const theme = useTheme();
    const isLowResolution = useMediaQuery(theme.breakpoints.down("md"));

    const onChangeSelectedItemIdx = (idx: number) => {
        setSearchParams({"idx": idx.toString()}, {replace: true});
    }

    return <Box
        sx={{
            display: "flex",
            flexDirection: isLowResolution ? "column" : "row",
            height: "100%"
        }}
    >
        <Paper
            sx={{
                alignSelf: isLowResolution ? "stretch" : "flex-start",
                m: theme.spacing(0, isLowResolution ? 0 : 1, isLowResolution ? 1 : 0, 0,)
            }}
        >
            <MuiTabs
                orientation={isLowResolution ? "horizontal" : "vertical"}
                variant="scrollable"
                value={tabIdx}
                onChange={(e, value) => onChangeSelectedItemIdx(value)}
                sx={{
                    width: isLowResolution ? "100%" : "200px"
                }}
            >
                {Children.map(props.children, (child, idx) => <Tab
                    key={idx}
                    label={child.props.label}
                    sx={{alignItems: "flex-end", textAlign: "end", p: 1}}
                />)}
            </MuiTabs>
        </Paper>
        {Children.map(props.children, (child, idx) => <TabPanel
            key={idx}
            index={idx}
            value={tabIdx}
        >
            {child}
        </TabPanel>)}
    </Box>
})

export default Object.assign(Tabs, {Item});