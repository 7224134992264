import {ClientViewModel, MessageViewModel} from "../../../models/entities";
import {HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import accessTokenFactory from "../../../ms-ui/http/accessTokenFactory";

export class MessageHub {

    // имя метода получения сообщения от тер
    readonly RECEIVE_MESSAGE_CLIENT_METHOD = "receiveMessage";
    readonly ADD_NEW_CLIENT_METHOD = "addNewClient";

    private hubConnection: HubConnection;

    constructor({onReceiveMessage, onAddNewClient}: {
        onReceiveMessage?: (message: MessageViewModel) => void,
        onAddNewClient?: (client: ClientViewModel) => void
    }) {

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_HOST}/messages`, {
                //skipNegotiation: true,
                withCredentials: false,
                accessTokenFactory: this.accessTokenFactory
            })
            .configureLogging(LogLevel.Critical)
            .build();

        if (onReceiveMessage) {
            this.hubConnection.on(this.RECEIVE_MESSAGE_CLIENT_METHOD, onReceiveMessage);
        }

        if (onAddNewClient) {
            this.hubConnection.on(this.ADD_NEW_CLIENT_METHOD, onAddNewClient);
        }

        this.hubConnection.start();
    }

    private accessTokenFactory = async () => {
        return accessTokenFactory.accessToken;
    }

    dispose = () => {
        return this.hubConnection.stop();
    }
}