import {StoreBase} from "../../../stores";
import {MainAppStore} from "../mainApp";
import {makeObservable, observable} from "mobx";
import {Nullable} from "../../../types";
import {AlertColor} from "@mui/material";
import {EmailConfirmationStatus, QS_SECRET_KEY, QS_USER_ID_KEY} from "../../../commons";
import {HttpClient} from "../../../http";
import {ConfirmEmailResponse} from "../../../models/responses/ConfirmEmailResponse";
import {ResponseBase} from "../../../models/responses/ResponseBase";
import appSettings from "../../../appSettings";

export class ConfirmEmailStore extends StoreBase<MainAppStore> {
    message: Nullable<string> = null;
    messageColor: AlertColor = "success";

    constructor(mainAppStore: MainAppStore, private _searchParams: URLSearchParams) {
        super(mainAppStore);

        makeObservable(this, {
            message: observable,
            messageColor: observable
        });
    }

    confirmEmail = async () => {
        if (Boolean(this.appStore.error)) {
            this.appStore.error = null;
        }

        return this.execute(async () => {
            const userId = this._searchParams.get(QS_USER_ID_KEY) ?? "";
            const secret = this._searchParams.get(QS_SECRET_KEY) ?? "";

            const request = appSettings.getService("confirmEmail")({
                request: {
                    userId,
                    emailConfirmationToken: secret
                }
            });
            const response = await new HttpClient()
                .execute<ResponseBase<ConfirmEmailResponse>, ConfirmEmailResponse>(request);

            this.messageColor = response.status === EmailConfirmationStatus.Confirmed
                ? "success"
                : response.status === EmailConfirmationStatus.AlreadyWasConfirmed
                    ? "warning"
                    : "error";
            this.message = response.description;
        });
    }
}