import {StoreBase} from "../../../../ms-ui/stores";
import {TherapistAppStore} from "../../../../stores/TherapistAppStore";
import {computed, makeObservable, observable} from "mobx";
import {MAX_MESSAGE_LENGTH, MESSAGE_EDITOR_TEMP_STORE} from "../../../../commons/consts";
import React from "react";
import {ClientViewModel, MessageViewModel} from "../../../../models/entities";
import {HttpClient} from "../../../../ms-ui/http";
import {v4} from "uuid";
import {MessageKind} from "../../../../commons";
import dayjs from "dayjs";
import {Dictionary, Nullable} from "../../../../ms-ui/types";
import {StorageHelper} from "../../../../ms-ui/helpers";
import {decode, encode} from "js-base64";
import {ChatSettingsHelper} from "../helpers/ChatSettingsHelper";
import {ResponseBase} from "../../../../ms-ui/models/responses/ResponseBase";
import {therapistAppSettings} from "../../../../therapistAppSettings";

export class MessageEditorStore extends StoreBase<TherapistAppStore> {
    text: string = "";

    constructor(therapistAppStore: TherapistAppStore, private client: Nullable<ClientViewModel>) {
        super(therapistAppStore);

        makeObservable(this, {
            text: observable,
            textLengthLeft: computed,
            sendButtonDisabled: computed,
            clientId: computed
        });

        if (client != null) {
            this.setTextToLocalStorage(this.getTextFromLocalStorage());
        }
    }

    private getTextFromLocalStorage() {
        const localStore = StorageHelper.getItem<Dictionary<string>>(MESSAGE_EDITOR_TEMP_STORE) ?? {};
        return decode(localStore[`${this.appStore.user!.id}#${this.clientId!}`] ?? "");
    }

    private setTextToLocalStorage(value: string) {
        this.text = value;

        const localStore = StorageHelper.getItem<Dictionary<string>>(MESSAGE_EDITOR_TEMP_STORE) ?? {};
        if (this.text.trim() === "") {
            delete localStore[`${this.appStore.user!.id}#${this.clientId!}`]
        } else {
            localStore[`${this.appStore.user!.id}#${this.clientId!}`] = encode(this.text);
        }
        StorageHelper.setItem(MESSAGE_EDITOR_TEMP_STORE, localStore);
    }

    onChangeTextHandler = (value: string) => {
        if (value.length > MAX_MESSAGE_LENGTH) {
            return;
        }

        this.setTextToLocalStorage(value);
    }

    onChangeKeyDownHandler = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
        reply: Nullable<MessageViewModel>,
        onCancelReplay: VoidFunction) => {
        if (e.key !== "Enter") {
            return;
        }

        e.stopPropagation();
        e.preventDefault();

        const messageSendingKey = ChatSettingsHelper.getMessageSendingKey();

        if (e.ctrlKey) {
            if (messageSendingKey === "CtrlEnter") {
                this.onSendMessageHandler(reply, onCancelReplay).then();
            } else {
                this.onChangeTextHandler(this.text + "\n");
            }
        } else {
            if (messageSendingKey === "Enter") {
                this.onSendMessageHandler(reply, onCancelReplay).then();
            } else {
                this.onChangeTextHandler(this.text + "\n");
            }
        }
    }

    onSendMessageHandler = async (
        reply: Nullable<MessageViewModel>,
        onCancelReplay: VoidFunction) => {
        return this.execute(async () => {
            if (this.clientId === null || this.text.trim().length === 0) {
                return;
            }

            const message: MessageViewModel = {
                id: v4(),
                therapistId: this.appStore.user!.id,
                therapist: null,
                text: this.text,
                kind: MessageKind.TherapistMessage,
                createdDateTime: dayjs().toDate(),
                clientId: this.clientId,
                client: null,
                isRead: true,
                reply
            };

            const request = therapistAppSettings.getService("sendMessage")({request: message});

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            this.setTextToLocalStorage("");
            onCancelReplay();
        });
    }

    get sendButtonDisabled() {
        return this.clientId === null;
    }

    get clientId() {
        return this.client?.id ?? null;
    }

    get textLengthLeft() {
        return `${MAX_MESSAGE_LENGTH}/${this.text.length}`;
    }
}