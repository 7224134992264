import {makeObservable, observable, reaction} from "mobx";
import {StoreBase} from "../../../../ms-ui/stores";
import {TherapistAppStore} from "../../../../stores/TherapistAppStore";
import {Nullable} from "../../../../ms-ui/types";
import {TherapistViewModel} from "../../../../models/entities";
import {HttpClient} from "../../../../ms-ui/http";
import {Sex} from "../../../../ms-ui/commons";
import {ResponseBase} from "../../../../ms-ui/models/responses/ResponseBase";
import {therapistAppSettings} from "../../../../therapistAppSettings";

export class ProfileTabStore extends StoreBase<TherapistAppStore> {

    private therapist: Nullable<TherapistViewModel> = this.appStore.user;

    constructor(therapistAppStore: TherapistAppStore) {
        super(therapistAppStore);

        makeObservable<ProfileTabStore, "therapist">(this, {
            therapist: observable
        });

        reaction(
            () => this.appStore.user,
            user => this.therapist = user
        );
    }

    editProfile = async () => {
        return this.execute(async () => {
            const request = therapistAppSettings.getService("editUser")({request: this.therapist!});
            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.getUser();
            this.appStore.success = "Данные успешно сохранены."
        });
    }

    changeEmailHandler = async (email: string, emailToBeConfirmed: Nullable<string>) => {
        return this.execute(async () => {

            const request = therapistAppSettings.getService("changeEmail")({
                request: {
                    userId: this.therapist!.id!,
                    email,
                    emailToBeConfirmed: emailToBeConfirmed!
                }
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.getUser();
            this.appStore.success = "E-mail был изменен, письмо с подтверждением было отправлено вам на почту.";
        });
    }

    cancelChangeEmailHandler = async () => {
        return this.execute(async () => {
            const request = therapistAppSettings.getService("cancelChangeEmail")({
                request: {
                    userId: this.therapist!.id!
                }
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.getUser();
            this.appStore.success = "Смена E-mail была отменена.";
        });
    }

    confirmationEmailResendHandler = async () => {
        return this.execute(async () => {
            const request = therapistAppSettings.getService("confirmationEmailResend")({
                request: {
                    userId: this.therapist!.id!
                }
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.getUser();
            this.appStore.success = "Письмо с подверждением было повторно отправлено вам на почту.";
        });
    }

    get avatar() {
        return this.therapist?.avatar ?? null;
    }

    set avatar(value: Nullable<string>) {
        this.therapist!.avatar = value;
    }

    get email() {
        return this.therapist?.email ?? "";
    }

    set email(value: string) {
        this.therapist!.email = value.trim() === "" ? null : value;
    }

    get phoneNumber() {
        return this.therapist?.phoneNumber ?? "";
    }

    set phoneNumber(value: Nullable<string>) {
        this.therapist!.phoneNumber = value !== null ? value.trim() : null;
    }

    get firstName() {
        return this.therapist?.firstName ?? "";
    }

    set firstName(value: string) {
        this.therapist!.firstName = value.trim() === "" ? null : value;
    }

    get lastName() {
        return this.therapist?.lastName ?? "";
    }

    set lastName(value: string) {
        this.therapist!.lastName = value.trim() === "" ? null : value;
    }

    get middleName() {
        return this.therapist?.middleName ?? "";
    }

    set middleName(value: string) {
        this.therapist!.middleName = value.trim() === "" ? null : value;
    }

    get birthDate() {
        return this.therapist?.birthDate ?? null;
    }

    set birthDate(value: Nullable<Date>) {
        this.therapist!.birthDate = value;
    }

    get sex() {
        return this.therapist?.sex ?? Sex.None;
    }

    set sex(value: Sex) {
        this.therapist!.sex = value;
    }

    get emailConfirmed() {
        return this.therapist?.emailConfirmed ?? null;
    }

    get emailToBeConfirmed() {
        return this.therapist?.emailToBeConfirmed ?? null;
    }
}