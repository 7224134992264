import {Button, Stack, Typography} from "@mui/material";

import {ChangePasswordDialog} from "./modals/ChangePasswordDialog";
import {RemoveProfileDialog} from "./modals/RemoveProfileDialog";
import {SecurityTabStore} from "./stores/SecurityTabStore";
import {observer} from "mobx-react";
import {useState} from "react";
import dayjs from "dayjs";
import {useMainAppStore} from "../mainApp";

export const SecurityTab = observer(() => {
    const mainAppStore = useMainAppStore();
    const [store] = useState(new SecurityTabStore(mainAppStore));

    return <>
        <ChangePasswordDialog store={store}/>
        <RemoveProfileDialog store={store}/>
        <Stack
            spacing={9}
            p={4}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
            >
                <Typography variant="h6">Пароль</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={store.onChangePasswordDialogOpen}
                >
                    Изменить пароль
                </Button>
            </Stack>
            <Stack
                alignItems="flex-start"
                spacing={1}
            >
                <Typography variant="h6">Журнал</Typography>
                <Typography><strong>Последняя активность — {dayjs().format("DD.MM.YYYY HH:mm:ss")}</strong></Typography>
                <Stack direction="row" alignItems="flex-start" spacing={1} sx={{"& > *": {flex: 1}}}>
                    <Typography>
                        Хотите посмотреть, когда вы входили в личный кабинет, посмотрите историю активности.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled
                    >
                        Показать историю активности
                    </Button>
                </Stack>
                <Stack direction="row" alignItems="flex-start" spacing={1} sx={{"& > *": {flex: 1}}}>
                    <Typography>
                        Если вы входили в личный кабинет с чужого компьютера и хотите быть уверенны,
                        что никто не сможет войти с того же компьтера, завершите сеансы.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled
                    >
                        Завершить все сеансы
                    </Button>
                </Stack>
            </Stack>
            <Stack
                alignItems="flex-start"
                spacing={1}
            >
                <Typography variant="h6">Удаление профиля</Typography>
                <Typography>Операция безвозвратного удаления вашего профиля из системы. Мы удалим все ваши личные
                    данные,
                    отменим подписки и прекратим всякую коммуникацию с вами. Для продолжения работы вам придется снова
                    зарегистрироваться.</Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={store.onRemoveProfileDialogOpen}
                    sx={{alignSelf: "center"}}
                >
                    Удалить профиль
                </Button>
            </Stack>
        </Stack>
    </>;
})