import { Select2Context } from "./contexts";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useCommonStyles } from "./commonStyles";
import { useContext } from "react";

const useStyles = makeStyles(theme => ({
	label: {
		flex: 1
	},
	shrinkLabel: {
		position: "absolute",
		top: "-9px",
		left: "14px",
		pointerEvents: "none",
		transition: "top .2s ease-in, font-size .2s ease-in, left .2s ease-in",
		maxWidth: "calc(100% - 24px)",
		fontSize: "0.70rem !important"
	},
	fieldset: {
		position: "absolute",
		top: "-7px",
		bottom: 0,
		left: 0,
		right: 0,
		borderRadius: "4px",
		padding: "0 8px",
		pointerEvents: "none",
		margin: 0
	},
	legend: {
		visibility: "hidden",
		fontSize: "0.70rem",
		padding: "0 4px",
		height: "16px"
	}
}))

export const Label = observer(() => {
	const store = useContext(Select2Context);
	const commonClasses = useCommonStyles();
	const classes = useStyles();

	return <>
		<Typography
			noWrap
			className={clsx(classes.label,
				{
					[commonClasses.hidden]: store.isShrinkLabel && store.isPlaceholder,
					[classes.shrinkLabel]: store.isShrinkLabel,
					[commonClasses.successColor]: store.isValid
				}
			)}
			component="div"
			color={store.isError ? "error" : "textSecondary"}
			variant="subtitle1"
		>
			{store.label}
		</Typography>
		{(store.isShrinkLabel && !store.isPlaceholder) && <fieldset
			className={clsx(
				classes.fieldset,
				commonClasses.border,
				{
					[commonClasses.error]: store.isError,
					[commonClasses.valid]: store.isValid
				}
			)}
		>
			<legend className={classes.legend}>
				<span>
					{store.label}
				</span>
			</legend>
		</fieldset>}
	</>
});
