import {Stack} from "@mui/material";
import {useEffect, useState} from "react";

import {ChangePasswordRequest} from "../../../models/requests";
import {ChangePasswordStore} from "./ChangePasswordStore";
import {observer} from "mobx-react";
import {OutlinedPasswordTextField} from "../../controls/OutlinedPasswordTextField";

export interface ChangePasswordProps {
    onChangeRequest: (request: ChangePasswordRequest, isValid: boolean) => void;
};

export const ChangePassword = observer((props: ChangePasswordProps) => {
    const [store] = useState(new ChangePasswordStore());

    useEffect(() => {
        props.onChangeRequest({
            current: store.current,
            new: store.new
        }, store.isValid);
    }, [store.isValid]);

    return <Stack spacing={1}>
        <OutlinedPasswordTextField
            label="Текущий пароль"
            value={store.current}
            onChange={value => store.current = value}
            error={store.isValidCurrent}
        />
        <OutlinedPasswordTextField
            label="Новый пароль"
            value={store.new}
            onChange={value => store.new = value}
            error={store.isValidNew}
        />
        <OutlinedPasswordTextField
            label="Повтор"
            value={store.repeat}
            onChange={value => store.repeat = value}
            error={store.isValidRepeat}
        />
    </Stack>;
})