import { Box, Chip, Tooltip, Typography } from "@mui/material";

import { Select2Context } from "./contexts";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useContext } from "react";

const useStyles = makeStyles(theme => ({
	chips: {
		width: "auto",
		display: "flex",
		flexWrap: "wrap",
		flexGrow: 1,
		overflow: "hidden",
	},
	chip: {
		margin: 1,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		height: "18px",
		borderRadius: "4px"
	}
}))

export const Value = observer(() => {
	const store = useContext(Select2Context);
	const classes = useStyles();

	const deleteValue = (value: unknown) => !store.isDisabled && store.isClearable
		? () => store.removeItem(value)
		: undefined;

	if (!store.hasValue) {
		return null;
	}

	return <Box
		className={classes.chips}
	>
		{
			store.isMultiple
				? store.hasValue && (store.value as []).map((value, idx) => {
					const text = store.itemText(value);
					return <Tooltip key={idx} title={text!}>
						<Chip
							key={idx}
							label={text}
							className={classes.chip}
							size="small"
							onMouseDown={(e) => {
								e.stopPropagation();
							}}
							onDelete={deleteValue(value)}
						/>
					</Tooltip>
				})
				: <Tooltip title={store.itemText(store.value)!}>
					<Typography noWrap>
						{store.itemText(store.value)}
					</Typography>
				</Tooltip>
		}
	</Box>;
})
