import {StoreBase} from "../../../../ms-ui/stores";
import {TherapistAppStore} from "../../../../stores/TherapistAppStore";
import {computed, makeObservable, observable} from "mobx";
import {Nullable} from "../../../../ms-ui/types";
import {ClientViewModel, MessageViewModel} from "../../../../models/entities";
import {SyntheticEvent} from "react";
import {UPDATE_MESSAGES_STATUS_TIMEOUT} from "../../../../commons/consts";
import {MessageHub} from "../MessageHub";
import {HttpClient} from "../../../../ms-ui/http";
import {GetMessageListResponse} from "../../../../models/responses/GetMessageListResponse";
import {ResponseBase} from "../../../../ms-ui/models/responses/ResponseBase";
import {therapistAppSettings} from "../../../../therapistAppSettings";

export class MessagesStore extends StoreBase<TherapistAppStore> {

    //TODO: перенести в объект
    lastPreviousLoadedPage: Nullable<number> = null;
    lastNextLoadedPage: Nullable<number> = null;
    hasPreviousPage: boolean = false;
    hasNextPage: boolean = false;

    scrollToMessage: Nullable<Element> = null;
    messages: MessageViewModel[] = [];
    reply: Nullable<MessageViewModel> = null;

    private messageHub: Nullable<MessageHub> = null;
    private timer?: number = undefined;

    constructor(
        therapistAppStore: TherapistAppStore,
        private client: Nullable<ClientViewModel>,
        private onCounterOfUnreadClientMessagesChange: (clientId: string, value: number) => void) {
        super(therapistAppStore);

        makeObservable(this, {
            hasPreviousPage: observable,
            hasNextPage: observable,
            scrollToMessage: observable,
            messages: observable,
            reply: observable,
            clientId: computed
        });
    }

    private receiveMessage = (message: MessageViewModel) => {
        if (message.clientId === this.clientId) {
            this.messages.push(message);
            this.scrollToMessage = this.getHtmlElementByDataId(message.id);
        }
    }

    onMarkReplyHandler = (reply: MessageViewModel) => {
        this.reply = reply;
    }

    onCancelReplyHandler = () => {
        this.reply = null;
    }

    onScrollMessagesHandler = (e: SyntheticEvent<HTMLDivElement, UIEvent>) => {
        const parentOffsetTop = e.currentTarget.offsetTop;
        const scrollTop = e.currentTarget.scrollTop;
        const scrollHeight = e.currentTarget.scrollHeight;

        if (scrollTop === 0 && this.hasPreviousPage && !this.appStore.isLoading) {
            this.execute(() => this.loadClientMessages("previous", true));
        }

        if (scrollHeight - scrollTop <= 50 && this.hasNextPage && !this.appStore.isLoading) {
            this.execute(() => this.loadClientMessages("next"));
        }

        if (this.timer !== undefined) {
            window.clearTimeout(this.timer);
        }

        this.timer = window.setTimeout(
            () => this.updateMessageStatuses(parentOffsetTop, parentOffsetTop + scrollHeight),
            UPDATE_MESSAGES_STATUS_TIMEOUT);
    }

    onScrollToMessageHandler = () => {
        if (this.scrollToMessage !== null) {
            this.scrollToMessage.scrollIntoView();
            this.scrollToMessage = null;
        }
    }

    private loadClientMessages = async (direction: Nullable<"previous" | "next">, scrollToMessage?: boolean) => {
        return this.execute(async () => {

            if (this.clientId == null) {
                return;
            }

            const messageListRequest = therapistAppSettings.getService("messageList")({
                request: {
                    clientId: this.clientId,
                    page: direction === "previous"
                        ? this.lastPreviousLoadedPage! - 1
                        : direction === "next"
                            ? this.lastNextLoadedPage! + 1
                            : null
                }
            });

            const response = await new HttpClient()
                .useAuthorization()
                .throwErrorIfResponseIsNull()
                .execute<ResponseBase<GetMessageListResponse>, GetMessageListResponse>(messageListRequest);

            if (direction === null) {
                this.messages = response.messages;
                this.lastPreviousLoadedPage = response.page;
                this.lastNextLoadedPage = response.page;
                this.hasPreviousPage = response.hasPreviousPage;
                this.hasNextPage = response.hasNextPage;

                // финт ушами, загрузим еще одну страницу, на случай, если на текущей будет мало сообщений и не будет
                // возможности выполнить ее прокрутку
                if (this.hasPreviousPage) {
                    await this.loadClientMessages("previous");
                }

                if (scrollToMessage) {
                    let messageIndex = this.messages.findIndex(message => !message.isRead);

                    if (messageIndex === -1) {
                        messageIndex = this.messages.length - 1;
                    }

                    if (messageIndex !== -1) {
                        this.scrollToMessage = this.getHtmlElementByDataId(this.messages.at(messageIndex)!.id);
                    }
                }
            }

            if (direction === "previous") {
                let _scrollToMessage: Nullable<Element> = null;

                if (scrollToMessage && this.messages.length > 0) {
                    _scrollToMessage = this.getHtmlElementByDataId(this.messages.at(0)!.id);
                }
                this.messages = response.messages.concat(this.messages);
                this.lastPreviousLoadedPage = response.page;
                this.hasPreviousPage = response.hasPreviousPage;

                this.scrollToMessage = _scrollToMessage;
            }

            if (direction === "next") {
                this.messages = this.messages.concat(response.messages);
                this.lastNextLoadedPage = response.page;
                this.hasNextPage = response.hasNextPage;
            }
        });
    }

    updateMessageStatuses = async (parentOffsetTop: number, parentOffsetHeight: number) => {
        const messageIds = this.messages
            .filter(message => !message.isRead)
            .filter(message => {
                const messageElement = this.getHtmlElementByDataId(message.id);
                if (messageElement === null) {
                    return false;
                }
                const messageOffsetTop = (messageElement as HTMLDivElement).offsetTop;
                return messageOffsetTop >= parentOffsetTop && messageOffsetTop <= parentOffsetHeight;
            })
            .map(message => message.id);

        if (messageIds.length === 0) {
            return;
        }

        const updateMessageStatusRequest = therapistAppSettings.getService("updateMessageStatus")({
            request: {
                messageIds
            }
        });

        await new HttpClient()
            .useAuthorization()
            .execute<ResponseBase>(updateMessageStatusRequest);

        this.messages = this.messages.map(message => {
            if (messageIds.includes(message.id)) {
                message.isRead = true;
            }

            return message;
        })

        this.onCounterOfUnreadClientMessagesChange(this.clientId!, this.messages.filter(message => !message.isRead).length)
    }

    private getHtmlElementByDataId = (id: string) => document.querySelector(`div[data-id=\"${id}\"]`);

    get clientId() {
        return this.client?.id ?? null;
    }

    async componentDidMount(): Promise<void> {
        await super.componentDidMount();

        return this.execute(async () => {
            this.messageHub = new MessageHub({
                onReceiveMessage: this.receiveMessage
            });

            await this.loadClientMessages(null, true);
        })
    }

    async componentDidUnmount(): Promise<void> {
        await super.componentDidUnmount();
        this.messageHub && await this.messageHub.dispose();
    }
}