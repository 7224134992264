import {Alert, AlertTitle, Stack, TextField} from "@mui/material";

import Typography from "@mui/material/Typography";
import {useState} from "react";

interface RemoveProfileProps {
    onChangeConfirmState: (state: boolean) => void;
}

export const RemoveProfile = (props: RemoveProfileProps) => {
    const expectedConfirmString = "удалить профиль";
    const [confirmString, setConfirmString] = useState("");

    const onChangeConfirmString = (value: string) => {
        setConfirmString(value);
        props.onChangeConfirmState(value.trim().toLowerCase() === expectedConfirmString);
    }

    return <Stack spacing={1}>
        <Typography>Для подтверждения удаления введите фразу «Удалить профиль»</Typography>
        <TextField
            value={confirmString}
            onChange={({target: {value}}) => onChangeConfirmString(value)}
            error={confirmString.trim() !== "" && confirmString.toLowerCase() !== expectedConfirmString}
            size="small"
        />
        <Alert severity="error" icon={false}>
            <AlertTitle>Удаление профиля</AlertTitle>
            Операция безвозвратного удаления вашего проифиля из системы. Если вы удалите профиль, вы потеряете все
            данные, относящиеся к нему.
        </Alert>
        <Typography>Очень жаль, что мы теряем ваc.<br/>
            Если у вас есть возможность, пожалуйста, напишите отзыв
            и отправьте на <a href="mailto:support@mindsafe.ru">support@mindsafe.ru</a>.
        </Typography>
    </Stack>;
}