import { Box, ListSubheader, Typography } from "@mui/material";

import { Select2Context } from "./contexts"
import { ThreeDots } from "react-loader-spinner";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useCommonStyles } from "./commonStyles";
import { useContext } from "react"

export const useStyles = makeStyles(theme => ({
	backdrop: {
		display: "flex",
		justifyContent: "center"
	},
}))

export const BottomStatus = observer(() => {
	const store = useContext(Select2Context);
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	return <ListSubheader
		className={commonClasses.listSubheader}
		disableSticky
	>
		{store.loading
			? <Box
				className={classes.backdrop}
			>
				<ThreeDots
					color="#3f51b5"
					height={40}
					width={40}
				/>
			</Box>
			: store.foundedItems.length === 0 && <Typography
				variant="body1"
				color="textSecondary"
			>
				Данные не найдены...
			</Typography>
		}
	</ListSubheader>;
})
