import {LoginRequest} from "../../../../models/requests";
import {GetAuthentificatedUserIdResponse, AuthResponse} from "../../../../models/responses";
import {HttpClient} from "../../../../http";
import {AuthHelper} from "../../../../helpers";
import {ResponseBase} from "../../../../models/responses/ResponseBase";
import appSettings from "../../../../appSettings";


export class AuthRepository {

    readonly login = async (loginRequest: LoginRequest): Promise<AuthResponse & GetAuthentificatedUserIdResponse> => {
        const request = appSettings.getService("login")({request: loginRequest});
        const loginResponse = await new HttpClient()
            .throwErrorIfResponseIsNull()
            .execute<ResponseBase<AuthResponse>, AuthResponse>(request);

        const getUserIdRequest = appSettings.getService("getUserId")();
        getUserIdRequest.headers = {
            "Authorization": `Bearer ${loginResponse.accessToken}`
        };
        const getUserIdResponse = await new HttpClient()
            .execute<ResponseBase<GetAuthentificatedUserIdResponse>, GetAuthentificatedUserIdResponse>(getUserIdRequest);

        return {...loginResponse, ...getUserIdResponse};
    }

    readonly updateAccessToken = async (): Promise<AuthResponse & GetAuthentificatedUserIdResponse> => {
        const [fingerPrint, browser, os, timeZone, userAgent] = AuthHelper.getAuthData();
        const updateAccessTokenRequest = appSettings.getService("updateAccessToken")({
            request: {
                fingerPrint,
                browser,
                os,
                timeZone,
                userAgent
            }
        });

        const updateAccessTokenResponse = await new HttpClient()
            .execute<ResponseBase<AuthResponse>, AuthResponse>(updateAccessTokenRequest);

        if (updateAccessTokenResponse == null) {
            throw new Error("Не получен ответ на запрос обновления токена обновления.");
        }

        const getUserIdRequest = appSettings.getService("getUserId")();
        getUserIdRequest.headers = {
            "Authorization": `Bearer ${updateAccessTokenResponse.accessToken}`
        };

        const getUserIdResponse = await new HttpClient()
            .execute<ResponseBase<GetAuthentificatedUserIdResponse>, GetAuthentificatedUserIdResponse>(getUserIdRequest);

        return {...updateAccessTokenResponse, ...getUserIdResponse};
    }

    readonly logout = async () => {
        const logoutRequest = appSettings.getService("logout")();

        return new HttpClient()
            .execute<ResponseBase>(logoutRequest);
    }
}