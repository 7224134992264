import {observer} from "mobx-react";
import {IconButton, Stack, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ClientInformation} from "./ClientInformation";
import {Messages} from "./Messages";
import {ChatStore} from "./stores/ChatStore";

interface ClientsChatProps {
    store: ChatStore;
}

export const ClientsChat = observer(({store}: ClientsChatProps) => {
    if (store.hideMessages && store.isLowResolution) {
        return null;
    }

    return <Stack
        sx={{
            flex: 1,
            minWidth: 0
        }}
        justifyContent="center"
    >
        {store.hideMessages
            ? <Typography align="center">
                Выберите клиента, с которым хотите начать общение
            </Typography>
            : <>
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{
                        p: 1
                    }}
                >
                    {store.isLowResolution && <IconButton
                        sx={{mr: 1}}
                        onClick={() => store.onChangeSelectedClientHandler(null)}
                    >
                        <ArrowBackIcon fontSize="medium"/>
                    </IconButton>}
                    <ClientInformation client={store.selectedClient} subscription={store.clientSubscription}/>
                </Stack>
                <Messages
                    client={store.selectedClient}
                    onCounterOfUnreadClientMessagesChange={store.updateCounterOfUnreadClientsMessages}
                />
            </>}
    </Stack>
})