import {StoreBase} from "../../../../ms-ui/stores";
import {TherapistAppStore} from "../../../../stores/TherapistAppStore";
import {computed, makeObservable, observable} from "mobx";
import {ClientViewModel, MessageViewModel} from "../../../../models/entities";
import {Dictionary, Nullable} from "../../../../ms-ui/types";
import {HttpClient} from "../../../../ms-ui/http";
import {ResponseBase} from "../../../../ms-ui/models/responses/ResponseBase";
import dayjs from "dayjs";
import {MessageHub} from "../MessageHub";
import {ClientTherapistSessionViewModel} from "../../../../models/entities/ClientTherapistSessionViewModel";
import {therapistAppSettings} from "../../../../therapistAppSettings";

export class ChatStore extends StoreBase<TherapistAppStore> {
    hideMessages: boolean = true;
    clientsFilterString: string = "";
    selectedClient: Nullable<ClientViewModel> = null;
    activePaymentHistoryItem: Nullable<ClientTherapistSessionViewModel> = null;
    counterOfUnreadClientsMessages: Dictionary<number> = {};
    isLowResolution: boolean = false;
    isClientExpanded: boolean = false;

    private clients: ClientViewModel[] = [];
    private messageHub: Nullable<MessageHub> = null;

    constructor(therapistAppStore: TherapistAppStore) {
        super(therapistAppStore);

        makeObservable<ChatStore, "clients">(this, {
            hideMessages: observable,
            clients: observable,
            clientsFilterString: observable,
            selectedClient: observable,
            activePaymentHistoryItem: observable,
            counterOfUnreadClientsMessages: observable,
            clientSubscription: computed,
            clientWidth: computed,
            isLowResolution: observable,
            isClientExpanded: observable,
            filteredClients: computed,
            isClientsEmpty: computed
        })
    }

    onChangeClientsFilterStringHandler = (clientsFilterString: string) => {
        this.clientsFilterString = clientsFilterString;
    }

    onChangeResolution = (isLowResolution: boolean) => {
        this.isLowResolution = isLowResolution;

        if (isLowResolution) {
            if (this.selectedClient === null) {
                this.isClientExpanded = true;
                this.hideMessages = true;
            } else {
                this.isClientExpanded = false;
                this.hideMessages = false;
            }
        } else {
            if (this.selectedClient === null) {
                this.isClientExpanded = false;
                this.hideMessages = true;
            } else {
                this.isClientExpanded = false;
                this.hideMessages = false;
            }
        }
    }

    onEscapeKeyDownHandler = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            this.onChangeSelectedClientHandler(null);
        }
    }

    onChangeClientExpanded = (expanded: boolean) => {
        this.isClientExpanded = expanded;
    }

    onClientsClickAwayHandler = () => {
        if (this.isLowResolution || !this.isClientExpanded) {
            return;
        }

        this.onChangeClientExpanded(false);
        this.onChangeClientsFilterStringHandler("");
    }

    onChangeSelectedClientHandler = async (client: Nullable<ClientViewModel>) => {
        this.selectedClient = client;

        this.isClientExpanded = this.isLowResolution && client === null;
        this.hideMessages = client === null;

        if (client !== null) {
            await this.execute(async () => {
                const getActivePaymentRequest = therapistAppSettings.getService("getActivePayment")({userId: this.selectedClient?.id ?? null});
                this.activePaymentHistoryItem = await new HttpClient()
                    .useAuthorization()
                    .execute<ResponseBase<ClientTherapistSessionViewModel>, ClientTherapistSessionViewModel>(getActivePaymentRequest);
            });
        }
    }

    updateCounterOfUnreadClientsMessages = (clientId: string, value: number) => {
        this.counterOfUnreadClientsMessages = {
            ...this.counterOfUnreadClientsMessages,
            [clientId]: value
        };
    }

    private receiveMessage = (message: MessageViewModel) => {
        if (!message.isRead) {
            this.updateCounterOfUnreadClientsMessages(
                message.clientId,
                (this.counterOfUnreadClientsMessages[message.clientId] ?? 0) + 1
            )
        }
    }

    private addNewClient = (client: ClientViewModel) => {
        this.clients = [...this.clients, client];
    }

    async componentDidMount(): Promise<void> {
        await super.componentDidMount();
        document.addEventListener("keydown", this.onEscapeKeyDownHandler);

        return this.execute(async () => {
            const clientListRequest = therapistAppSettings.getService("clientList")();
            this.clients = await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase<ClientViewModel[]>, ClientViewModel[]>(clientListRequest);

            const counterOfUnreadClientsMessagesRequest = therapistAppSettings.getService("unreadMessagesCountPerClient")();
            this.counterOfUnreadClientsMessages = await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase<Dictionary<number>>, Dictionary<number>>(counterOfUnreadClientsMessagesRequest);

            this.messageHub = new MessageHub({
                onReceiveMessage: this.receiveMessage,
                onAddNewClient: this.addNewClient
            });
        })
    }

    async componentDidUnmount(): Promise<void> {
        await super.componentDidUnmount();
        document.removeEventListener("keydown", this.onEscapeKeyDownHandler);
        this.messageHub && await this.messageHub.dispose();
    }

    get clientSubscription() {
        return this.activePaymentHistoryItem != null
            ? `Подписка до ${dayjs(this.activePaymentHistoryItem.endDateTime).format("DD.MM.YY HH:mm")} Мск`
            : "Данные о подписке не удалось загрузить"
    }

    get clientWidth() {
        return this.isLowResolution
            ? this.isClientExpanded
                ? "100%"
                : false
            : this.isClientExpanded
                ? "300px"
                : "fit-content";
    }

    get filteredClients() {
        return this.clients
            .filter(client => this.clientsFilterString === ""
                || client.nickName!.includes(this.clientsFilterString));
    }

    get isClientsEmpty() {
        return this.clients.length === 0;
    }
}