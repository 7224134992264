import {Button, Typography} from "@mui/material";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {BorderedBox} from "./BorderedBox";
import appSettings from "../../appSettings";

export const LoggedOut = () => {
    return <BorderedBox>
        <Typography
            component="div"
            variant="h5"
            color="primary"
        >
            Вы вышли из системы
        </Typography>
        <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowForwardIcon fontSize="small"/>}
            href={appSettings.getRoute("login")}
        >
            Войти
        </Button>
    </BorderedBox>;
}