import {computed, makeObservable, observable} from "mobx";
import {RegistrationTherapistViewModel} from "../../../models/entities/RegistrationTherapistViewModel";
import {StoreBase} from "../../../ms-ui/stores";
import {TherapistAppStore} from "../../../stores/TherapistAppStore";
import {Nullable} from "../../../ms-ui/types";
import {Sex} from "../../../ms-ui/commons";
import {TherapistStatus} from "../../../commons/enums";
import {QS_EMAIL_KEY, QS_SECURITY_KEY} from "../../../commons/consts";
import {HttpClient} from "../../../ms-ui/http";
import {AuthHelper} from "../../../ms-ui/helpers";
import {ResponseBase} from "../../../ms-ui/models/responses/ResponseBase";
import {therapistAppSettings} from "../../../therapistAppSettings";

export class RegistrationStore extends StoreBase<TherapistAppStore> {
    therapist: RegistrationTherapistViewModel;
    security: Nullable<string>;

    constructor(therapistAppStore: TherapistAppStore, private _searchParams: URLSearchParams, private _postRegistrationAction: VoidFunction) {
        super(therapistAppStore);

        this.therapist = {
            id: null,
            firstName: null,
            middleName: null,
            lastName: null,
            avatar: null,
            sex: Sex.None,
            phoneNumber: null,
            email: _searchParams.get(QS_EMAIL_KEY),
            birthDate: null,
            userAgreement: false,
            userDataPolicy: false,
            password: null,
            passwordConfirmation: null,
            emailConfirmed: null,
            emailToBeConfirmed: null,
            status: TherapistStatus.NoNeedClient,
            role: null!
        };

        this.security = _searchParams.get(QS_SECURITY_KEY);

        makeObservable(this, {
            therapist: observable,
            isPasswordInvalid: computed,
            isPasswordConfirmationInvalid: computed,
            registrationBtnDisabled: computed
        });
    }

    registration = async () => {
        return this.execute(async () => {
            const request = therapistAppSettings
                .getService("registration")({request: {
                    therapist: this.therapist!,
                    security: this.security
                }});
            await new HttpClient()
                .execute<ResponseBase>(request);

            await this.appStore.authStore.onLogin(this.therapist.email!, this.therapist.password!)
            this._postRegistrationAction();
        })
    }

    get isPasswordInvalid() {
        if (this.therapist.password === null && this.therapist.passwordConfirmation === null) {
            return null;
        }

        if (this.therapist.password === null) {
            return "Не может быть пустым.";
        }

        if (!AuthHelper.validatePassword(this.therapist.password ?? "")) {
            return "Не соответствует требованиям политики безопасности формирования пароля, пароль должен содержать прописные и строчные буквы, хотя бы одну цифру и быть в длинну от 8 символов.";
        }

        return null;
    }

    get isPasswordConfirmationInvalid() {
        if (this.therapist.password === null && this.therapist.passwordConfirmation === null) {
            return null;
        }

        if (this.therapist.password !== this.therapist.passwordConfirmation) {
            return "Новый пароль и повтор не совпадают.";
        }

        return null;
    }

    get registrationBtnDisabled() {
        return this.therapist.email === null
            || this.therapist.password === null
            || this.therapist.passwordConfirmation === null
            || !this.therapist.userAgreement
            || !this.therapist.userDataPolicy
            || this.isPasswordInvalid !== null
            || this.isPasswordConfirmationInvalid !== null;
    }

    get emailTxtBoxDisabled() {
        return this._searchParams.has(QS_EMAIL_KEY) && this._searchParams.has(QS_SECURITY_KEY);
    }
}