import {AppRoutes} from "./AppRoutes";
import {AppServices} from "./AppServices";

export class AppSettings<TAppRoutes extends AppRoutes = AppRoutes, TAppServices extends AppServices = AppServices> {
    private _freeze = false;
    private _routes = new Map<keyof TAppRoutes, TAppRoutes[keyof TAppRoutes]>();
    private _services = new Map<keyof TAppServices, TAppServices[keyof TAppServices]>();


    addRoute = <TKey extends keyof TAppRoutes>(key: TKey, value: TAppRoutes[TKey]) => {
        if (this._freeze) {
            throw new Error(`Объект заморожен, вызов метода запрещен`);
        }

        this._routes.set(key, value);
        return this;
    }

    addService = <TKey extends keyof TAppServices>(key: TKey, value: TAppServices[TKey]) => {
        if (this._freeze) {
            throw new Error(`Объект заморожен, вызов метода запрещен`);
        }

        this._services.set(key, value);
        return this;
    }

    getRoute = <TKey extends keyof TAppRoutes>(key: TKey): TAppRoutes[TKey] => {
        if (!this._freeze) {
            throw new Error(`Объект не заморожен, вызов метода запрещен`);
        }

        if (!this._routes.has(key)) {
            throw new Error(`Ключ ${key} не зарегистрирован среди routes в AppSettings`);
        }

        const route = this._routes.get(key);

        if (route == null) {
            throw new Error(`Для ключа ${key} не указано значение в routes в AppSettings`);
        }

        return route as TAppRoutes[TKey];
    }

    getService = <TKey extends keyof TAppServices>(key: TKey): TAppServices[TKey] => {
        if (!this._freeze) {
            throw new Error(`Объект не заморожен, вызов метода запрещен`);
        }

        if (!this._services.has(key)) {
            throw new Error(`Ключ ${key} не зарегистрирован среди services в AppSettings`);
        }

        const service = this._services.get(key) as TAppServices[TKey];

        if (service == null) {
            throw new Error(`Для ключа ${key} не указано значение в services в AppSettings`);
        }

        return service;
    }

    freeze = () => {
        this._freeze = true;
    };

    castTo = <TCustomAppRoutes extends TAppRoutes = TAppRoutes, TCustomAppServices extends TAppServices = TAppServices>() => this as unknown as AppSettings<TCustomAppRoutes, TCustomAppServices>;
}