import { Box, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from "@mui/material";

import { BottomStatus } from "./BottomStatus";
import { Select2Context } from "./contexts";
import { Select2Store } from "./stores";
import _ from "underscore";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useCommonStyles } from "./commonStyles";
import { useContext } from "react";

export const useStyles = makeStyles(theme => ({
	listContainer: {
		maxHeight: "162px",
		overflowY: "scroll",
		marginBottom: 0,
		"&::-webkit-scrollbar": {
			display: "none"
		}
	},
	list: {
		padding: 0
	},
	listIcon: {
		minWidth: "38px"
	}
}));

export const FoundValues = observer(() => {
	const classes = useStyles();
	const store = useContext(Select2Context);
	const subheader = store.isMultiple ? <li /> : undefined;

	return <Box
		onScroll={store.onScroll}
		className={classes.listContainer}
		onClick={e => e.stopPropagation()}
	>
		<List disablePadding subheader={subheader}>
			{
				store.groupByKey
					? <GrouppedList store={store} />
					: <FlatList store={store} />
			}
			<BottomStatus />
		</List>
	</Box>
})

interface ListProps<TItem> {
	store: Select2Store<TItem>;
}

const FlatList = observer(<TItem,>(props: ListProps<TItem>) => {
	const commonClasses = useCommonStyles();
	const classes = useStyles();
	const { store } = props;

	return <>
		{store.foundedItems.map(item => {
			return <ListItem
				key={`item-${store.getItemKeyValue(item.item)}`}
				dense
				button
				onClick={(e) => {
					e.stopPropagation();
					store.onChangeListItem(item)
				}
				}
				selected={item.selected}
				disabled={item.disabled}
			>
				<ListItemIcon
					className={clsx(classes.listIcon, !store.checkbox && commonClasses.hidden)}
				>
					<Checkbox
						edge="start"
						checked={item.selected}
						disabled={item.disabled}
						tabIndex={-1}
						disableRipple
						size="small"
						color="primary"
					/>
				</ListItemIcon>
				<ListItemText primary={store.listItemText(item.item)} />
			</ListItem>
		})
		}
	</>;
})

const GrouppedList = observer(<TItem,>(props: ListProps<TItem>) => {
	const commonClasses = useCommonStyles();
	const classes = useStyles();
	const { store } = props;
	const groupped = _.groupBy(store.foundedItems, foundItem => foundItem.item[store.groupByKey as keyof typeof foundItem.item] as unknown as string);

	return <>
		{
			_.map(groupped, (group, key) => <li key={`group-${key}`}>
				<ul className={classes.list}>
					<ListSubheader
						className={commonClasses.listSubheader}
					>
						{key}
					</ListSubheader>
					{
						group.map(item => {
							return <ListItemButton
								key={`group-${key}-item-${store.getItemKeyValue(item.item)}`}
								dense
								onClick={(e) => {
									e.stopPropagation();
									store.onChangeListItem(item)
								}
								}
								selected={item.selected}
								disabled={item.disabled}
							>
								<ListItemIcon
									className={clsx(classes.listIcon, !store.checkbox && commonClasses.hidden)}
								>
									<Checkbox
										edge="start"
										checked={item.selected}
										disabled={item.disabled}
										tabIndex={-1}
										disableRipple
										size="small"
										color="primary"
									/>
								</ListItemIcon>
								<ListItemText primary={store.listItemText(item.item)} />
							</ListItemButton>
						})
					}
				</ul>
			</li>)
		}
	</>;
})
