import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type CommonStylesProps = { dense?: boolean; };

export const useCommonStyles = (props?: CommonStylesProps) => makeStyles<Theme, CommonStylesProps>(theme => ({
	listSubheader: {
		lineHeight: "40px !important"
	},
	hidden: {
		display: "none !important",
	},
	successColor: {
		color: theme.palette.success.main
	},
	border: {
		border: "1px solid rgba(0, 0, 0, 0.23)",
		"&:hover": {
			borderColor: "rgba(0, 0, 0, 0.87)"
		}
	},
	error: {
		borderColor: theme.palette.error.main,
		"&:hover": {
			borderColor: theme.palette.error.dark
		}
	},
	valid: {
		borderColor: theme.palette.success.main,
		"&:hover": {
			borderColor: theme.palette.success.dark
		}
	},
	medium: {
		minHeight: ({ dense }) => dense ? "30px" : "56px",
		padding: ({ dense }) => dense ? "1px 18px 1px 14px" : "13px 18px 13px 14px"
	},
	small: {
		minHeight: ({ dense }) => dense ? "30px" : "40px",
		padding: ({ dense }) => dense ? "1px 18px 1px 14px" : "5px 18px 5px 14px"
	}
}))(props || {});
