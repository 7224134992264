import {makeObservable, observable} from "mobx";
import {Nullable} from "../../../types";
import {AuthHelper} from "../../../helpers";

export class ChangePasswordStore {
    private _current: Nullable<string>;
    private _new: Nullable<string>;
    private _repeat: Nullable<string>;

    constructor() {
        this._current = null;
        this._new = null;
        this._repeat = null;

        makeObservable<ChangePasswordStore, "_current" | "_new" | "_repeat">(this, {
            _current: observable,
            _new: observable,
            _repeat: observable
        });
    }

    get current() {
        return this._current ?? "";
    }

    set current(value: string) {
        this._current = value.trim() === "" ? null : value;
    }

    get new() {
        return this._new ?? "";
    }

    set new(value: string) {
        this._new = value.trim() === "" ? null : value;
    }

    get repeat() {
        return this._repeat ?? "";
    }

    set repeat(value: string) {
        this._repeat = value.trim() === "" ? null : value;
    }

    get isValidCurrent(): string | undefined {
        if (this._current === null && this._new === null && this._repeat === null) {
            return undefined;
        }

        if (this._current === null) {
            return "Не может быть пустым";
        }

        return undefined;
    }

    get isValidNew(): string | undefined {
        if (this._current === null && this._new === null && this._repeat === null) {
            return undefined;
        }

        if (this._new === null) {
            return "Не может быть пустым";
        }

        if (!AuthHelper.validatePassword(this._new)) {
            return "Не соответствует требованиям политики безопасности формирования пароля, пароль должен содержать прописные и строчные буквы, хотя бы одну цифру и быть в длинну от 8 символов.";
        }

        return undefined;
    }

    get isValidRepeat(): string | undefined {
        if (this._current === null && this._new === null && this._repeat === null) {
            return undefined;
        }

        if (this._new !== this._repeat) {
            return "Новый пароль и повтор не совпадают";
        }

        return undefined;
    }

    // private clearFields = () => {
    //     this._current = this._new = this._repeat = null;
    // }

    get isValid() {
        return !(this._current == null
            || this._new == null
            || this._repeat == null
            || Boolean(this.isValidCurrent)
            || Boolean(this.isValidNew)
            || Boolean(this.isValidRepeat));
    }
}