import {makeObservable, observable} from "mobx";
import dayjs from "dayjs";
import {ChangeTherapistModal} from "./ChangeTherapistModal";
import {TherapistAppStore} from "../../../stores/TherapistAppStore";
import {StoreBase} from "../../../ms-ui/stores";
import {ClientTherapistSessionViewModel} from "../../../models/entities/ClientTherapistSessionViewModel";
import {TherapistViewModel} from "../../../models/entities";
import {ClientTherapistSessionStatus} from "../../../commons/enums";
import {HttpClient} from "../../../ms-ui/http";
import {GridColDef} from "@mui/x-data-grid";
import {getPersonName} from "../../../ms-ui/helpers";
import {Chip} from "@mui/material";
import {ResponseBase} from "../../../ms-ui/models/responses/ResponseBase";
import {therapistAppSettings} from "../../../therapistAppSettings";

export class ClientSessionsStore extends StoreBase<TherapistAppStore> {
    sessions: ClientTherapistSessionViewModel[] = [];
    therapists: TherapistViewModel[] = [];

    constructor(therapistAppStore: TherapistAppStore) {
        super(therapistAppStore);
        makeObservable(this, {
            sessions: observable,
            therapists: observable
        });
    }

    getSpendTimeSinceStarted = (session: ClientTherapistSessionViewModel) => {
        if (session.status === ClientTherapistSessionStatus.ClientIsWaiting) {
            return {
                text: `Нет ответа ${dayjs().diff(dayjs(session.startDateTime), "hours")} час(ов)`,
                color: "error"
            } as const;
        }

        return {
            text: "Работа идет",
            color: "success"
        } as const;
    }

    onChangeTherapistHandler = (clientTherapistSessionId: string, newTherapistId: string) => {
        return this.execute(async () => {
            const request = therapistAppSettings.getService("changeTherapist")({
                request: {
                    newTherapistId,
                    clientTherapistSessionId
                }
            });

            const changedSession = await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase<ClientTherapistSessionViewModel>, ClientTherapistSessionViewModel>(request);

            this.sessions = this.sessions.map(session => {
                return session.id === changedSession?.id ? changedSession : session;
            });
        })
    }

    async componentDidMount(): Promise<void> {
        await super.componentDidMount();
        return this.execute(async () => {
            const sessionListRequest = therapistAppSettings.getService("sessionList")();

            this.sessions = await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase<ClientTherapistSessionViewModel[]>, ClientTherapistSessionViewModel[]>(sessionListRequest) ?? [];

            const therapistListRequest = therapistAppSettings.getService("therapistList")();

            this.therapists = await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase<TherapistViewModel[]>, TherapistViewModel[]>(therapistListRequest) ?? [];
        })
    }

    get columns(): GridColDef<ClientTherapistSessionViewModel>[] {
        return [
            {
                field: "client",
                headerName: "Псевдоним клиента",
                valueGetter: ({row}) => {
                    return row.client.nickName;
                },
                width: 200,
                sortable: false
            },
            {
                field: "therapist",
                headerName: "Терапевт",
                valueGetter: ({row}) => {
                    return getPersonName(row.therapist.lastName, row.therapist.firstName, row.therapist.middleName);
                },
                width: 200,
                sortable: false
            },
            {
                field: "startDateTime",
                headerName: "Начало сессии",
                valueGetter: ({row}) => {
                    return dayjs(row.startDateTime).format("DD.MM.YYYY HH:mm:ss");
                },
                width: 180,
                align: "right",
                headerAlign: "right",
                sortable: false
            },
            {
                field: "therapistActivity",
                headerName: "Активность терапевта",
                renderCell: ({row}) => {
                    const action = this.getSpendTimeSinceStarted(row);
                    return <Chip
                        label={action.text}
                        color={action.color}
                        size="small"
                    />
                },
                width: 200,
                sortable: false
            },
            {
                field: "actions",
                type: "actions",
                headerName: "",
                renderCell: ({id, row}) => {
                    return <ChangeTherapistModal
                        session={row}
                        therapists={this.therapists}
                        onChange={newTherapistId => this.onChangeTherapistHandler(row.id, newTherapistId)}/>
                },
                flex: 1,
                minWidth: 50,
                sortable: false,
                align: "right"
            }
        ]
    }
}