import {PRIMARY_MAIN_COLOR, SECONDARY_MAIN_COLOR} from '../commons';
import {ruRU} from "@mui/x-data-grid";
import {createTheme} from '@mui/material/styles';

export const mindsafe = createTheme({
    palette: {
        primary: {
            main: PRIMARY_MAIN_COLOR
        },
        secondary: {
            main: SECONDARY_MAIN_COLOR
        }
    }
}, ruRU);