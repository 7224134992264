import { EnhancedTooltipIconButton } from "./EnhancedTooltipIconButton";
import { EnhancedTooltipIconButtonProps } from "../../types";
import { Theme } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>(theme => ({
	info: {
		color: theme.palette.info.main
	}
}))

export const InfoTooltipIconButton = (props: EnhancedTooltipIconButtonProps) => {
	const classes = useStyles();

	if (!props.title) {
		return null;
	}

	return <EnhancedTooltipIconButton {...props}>
		<WarningIcon
			className={classes.info}
			fontSize="small"
		/>
	</EnhancedTooltipIconButton>
}
