import {Nullable} from "../../../ms-ui/types";
import {MessageViewModel} from "../../../models/entities";
import {IconButton, Stack, Typography, useTheme} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";

interface ReplayMessageProps {
    reply: Nullable<MessageViewModel>;
    onCancelReplay: VoidFunction;
}

export const ReplayMessage = (props: ReplayMessageProps) => {
    const theme = useTheme();
    return props.reply
        ? <Stack
            flexDirection="row"
            alignItems="center"
            p={theme.spacing(1, 1, 0)}
            sx={{
                "& > *:not(:last-child)": {
                    mr: 1
                }
            }}
        >
            <ReplyOutlinedIcon
                color="primary"
                fontSize="small"
            />
            <Stack
                sx={{
                    borderLeft: `4px solid ${theme.palette.primary.main}`,
                    pl: 1,
                    flex: 1,
                    overflow: "hidden",
                }}
                spacing={0.5}
            >
                <Typography
                    variant="subtitle2"
                    color="primary"
                    noWrap
                >
                    Ответ для {props.reply.client?.nickName}
                </Typography>
                <Typography
                    variant="body2"
                    noWrap
                >
                    {props.reply.text}
                </Typography>
            </Stack>
            <IconButton
                onClick={props.onCancelReplay}
                color="primary"
                size="small"
            >
                <ClearIcon fontSize="small"/>
            </IconButton>
        </Stack>
        : null
}