import {MessageSendingKey} from "../types/MessageSendingKey";
import {StorageHelper} from "../../../../ms-ui/helpers";
import {IS_CHAT_BACKGROUND_ENABLED, MESSAGE_EDITOR_SENDING_KEY} from "../../../../commons/consts";

export class ChatSettingsHelper {
    static readonly getMessageSendingKey = () => {
        return StorageHelper.getItem<MessageSendingKey>(MESSAGE_EDITOR_SENDING_KEY) ?? "CtrlEnter";
    }

    static readonly setMessageSendingKey = (key: MessageSendingKey) => {
        StorageHelper.setItem(MESSAGE_EDITOR_SENDING_KEY, key);
    }

    static readonly getIsChatBackgroundEnabled = () => {
        return StorageHelper.getItem(IS_CHAT_BACKGROUND_ENABLED) ?? true;
    }

    static readonly setIsChatBackgroundEnabled = (enabled: boolean) => {
        StorageHelper.setItem(IS_CHAT_BACKGROUND_ENABLED, enabled);
    }
}