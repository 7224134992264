import {Container} from "@mui/material";
import {FC} from "react";
import {observer} from "mobx-react";

export const MainAppContainer: FC = observer(({children}) => {
    return <Container
        disableGutters
        maxWidth="lg"
        sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column"
        }}>
        {children}
    </Container>
})