import {App} from "./App";
import {configure} from "mobx";
import {render} from "react-dom";

configure({
    enforceActions: "never",
    //computedRequiresReaction: true,
    //reactionRequiresObservable: true,
    //observableRequiresReaction: true,
    //disableErrorBoundaries: true
});

render(<App/>, document.getElementById("root"));