import dayjs from "dayjs";

const dateFormat = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

const dateReviver = (key: string, value: any) => {
    return dateFormat.test(value) && key.toLowerCase().includes("date")
        ? dayjs(value).toDate()
        : value;

}

export const dateTransformer = (data: string): any => {

    try {
        if (data === "") {
            return JSON.parse("{}");
        } else {
            return JSON.parse(data, dateReviver);
        }
    } catch {

    }
    return data;
}