// принадлежность сообщения
export enum MessageKind {
    // сообщение клиента
    ClientMessage = 0,
    // сообщение психолога
    TherapistMessage = 2,
    SystemMessage = 42
};

export enum InviteStatus {
    Pending = 0,
    Registered = 2,
    Canceled = 4
};

export const InviteStatusDescription = {
    [InviteStatus.Pending]: "Ожидание регистрации",
    [InviteStatus.Registered]: "Успешная регистрация",
    [InviteStatus.Canceled]: "Приглашение отозвано"
};

export enum TherapistStatus {
    NeedClient = 10,
    NoNeedClient = 20
};

export const TherapistStatusDescription = {
    [TherapistStatus.NeedClient]: "Нужен клиент",
    [TherapistStatus.NoNeedClient]: "Клиентов достаточно"
};

export enum ClientTherapistSessionStatus {
    // Клиент ждет -- первый статус сессии, проставляется при создании
    ClientIsWaiting = 100,

    // Отменено клиентом -- выставляется при отмене сессии клиентом
    CancelledByClient = 101,

    // Терапевт работает -- выставляется сессии, в которую пришел терапевт и работает
    TherapistIsWorking = 300,

    // Терапевт изменен -- при смене терапевта проставляется закрываемой сессии
    TherapistWasChanged = 333
}