import { OutlinedTextField, OutlinedTextFieldProps } from "./OutlinedTextField";

import { Nullable } from "../../types";
import dayjs from "dayjs";

interface OutlinedDateTimePickerProps extends Omit<OutlinedTextFieldProps, "onChange" | "value" | "shrink"> {
	onChange: (value: Nullable<Date>) => void;
	value: Nullable<Date>;
	type?: "date" | "dateTime" | "time";
	maxDate?: Date;
	minDate?: Date;
}

export const OutlinedDateTimePicker = (props: OutlinedDateTimePickerProps) => {

	const { value, onChange, type, maxDate, minDate, ...otherProps } = props;

	const dateTimeType = props.type === "date"
		? "date"
		: props.type === "dateTime"
			? "datetime-local"
			: props.type === "time"
				? "time"
				: "date";

	const dateTimeFormat = props.type === "date"
		? "YYYY-MM-DD"
		: props.type === "dateTime"
			? "YYYY-MM-DDTHH:mm"
			: props.type === "time"
				? "HH:mm"
				: "YYYY-MM-DD";

	return <OutlinedTextField
		type={dateTimeType}
		{...otherProps}
		shrink
		value={value === null ? "" : dayjs(value).format(dateTimeFormat)}
		onChange={value => onChange(value === "" ? null : dayjs(value).toDate())}
		inputProps={{
			max: maxDate === undefined ? undefined : dayjs(maxDate).format(dateTimeFormat),
			min: minDate === undefined ? undefined : dayjs(minDate).format(dateTimeFormat)
		}}
		fullWidth
	/>
}
