import {useEffect, useState} from "react";
import {StorageHelper} from "../../helpers";
import {VERSION} from "../../commons";
import {HttpClient} from "../../http";
import {ResponseBase} from "../../models/responses/ResponseBase";
import {EnhancedButton} from "../controls";
import {Box, Stack, Typography} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {grey} from "@mui/material/colors";
import {Nullable} from "../../types";
import {SoftwareComponentViewModel} from "../../models/entities";
import appSettings from "../../appSettings";

export const WarningAboutRefreshSite = () => {
    const [open, setOpen] = useState(false);
    const [version, setVersion] = useState<Nullable<string>>(null);

    const getVersionFromLocalStorage = () => {
        return StorageHelper.getItem(VERSION);
    };

    const getVersionFromService = async () => {
        try {
            const request = appSettings.getService("getVersion")();
            const response = await new HttpClient()
                .execute<ResponseBase<SoftwareComponentViewModel>, SoftwareComponentViewModel>(request);
            setVersion(response.version);
            return response.version;
        } catch {
            return null;
        }
    }

    const check = async () => {
        setOpen(getVersionFromLocalStorage() !== await getVersionFromService());
    }

    const onUpdateBtnClickHandler = () => {
        if (version == null) {
            return;
        }
        StorageHelper.setItem(VERSION, version);
        window.location.reload();
    }

    useEffect(() => {
        check().then();
    }, []);

    return <Box
        sx={[{
            position: "fixed",
            top: "32px",
            backgroundColor: "#fff",
            zIndex: 1,
            left: "50%",
            transform: "translateX(-50%)",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "4px"
        },
            !open && {
                display: "none"
            }]}
    >
        <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
                minHeight: "56px",
                pr: 1

            }}
        >
            <Box
                sx={{
                    alignSelf: "stretch",
                    backgroundColor: grey[300],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "56px"
                }}
            >
                <InfoIcon/>
            </Box>
            <Typography
                sx={{flex: 1, padding: "8px 0"}}
            >
                Доступна новая версия
            </Typography>
            <EnhancedButton
                onClick={onUpdateBtnClickHandler}
            >
                Обновить
            </EnhancedButton>
        </Stack>
    </Box>
}