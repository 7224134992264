import {ArrayOrValue, inferItem, Select2FilterRequest, Select2Item, Select2Props} from "./types";
import {action, makeObservable, observable} from "mobx";
import {Nullable} from "../../../types";
import {PaginationRequest} from "../../../models/requests/PaginationRequest";

export class Select2Store<TItem = any> {

    private _timer?: number = undefined;

    value: ArrayOrValue<TItem>;
    isOpen: boolean = false;
    anchorElement: Nullable<HTMLDivElement> = null;

    foundedItems: Array<Select2Item<inferItem<TItem>>> = [];
    pagination = {
        page: 0,
        rowsPerPage: 10
    };
    totalRows: number = 0;
    searchString: string = "";
    loading: boolean = true;

    mouseOver: boolean = false;

    constructor(private props: Select2Props<TItem>) {
        this.value = props.value;

        makeObservable(this, {
            value: observable,
            isOpen: observable,
            anchorElement: observable,
            open: action,
            close: action,
            clear: action,
            foundedItems: observable,
            pagination: observable,
            totalRows: observable,
            searchString: observable,
            loading: observable,
            mouseOver: observable
        });
    }

    mouseEnter = () => {
        this.mouseOver = true;
    }

    mouseLeave = () => {
        this.mouseOver = false;
        this.close();
    }

    open = () => {
        if (this.isOpen) {
            return;
        }

        this.isOpen = true;
        this.getData();
    }

    close = () => {
        if (!this.isOpen) {
            // ClickAwayListener отрабатывает каждый клик вне своей области, при закрытом состоянии
            // повторный вызов данного метода не требуется
            return;
        }

        window.clearTimeout(this._timer);
        this.isOpen = false;
        this.props.onChange(this.value);
        //this.value = this.isValueArray ? [] as any : null;
        this.foundedItems = [];
        this.pagination.page = 0;
        this.totalRows = 0;
        this.searchString = "";
    }

    clear = () => {
        this.props.onChange(this.isValueArray ? [] as any : null);
    }

    removeItem = (removeValue: inferItem<TItem>) => {
        const newValue = (this.value as inferItem<TItem>[]).filter(value => this.getItemKeyValue(value) !== this.getItemKeyValue(removeValue));
        this.props.onChange(newValue as ArrayOrValue<TItem>);
    }

    onChangeSearchString = (value: string) => {
        window.clearTimeout(this._timer);

        this.searchString = value;
        this.pagination.page = 0;

        this._timer = window.setTimeout(() => this.getData(), this.timeout);
    }

    onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        event.stopPropagation();
        const {clientHeight, scrollTop, scrollHeight} = event.currentTarget;

        if (this.loading || this.foundedItems.length === this.totalRows) {
            return;
        }

        if (scrollHeight - (clientHeight + scrollTop) <= 50) {
            this.pagination.page++;
            this.getData();
        }
    }

    getData = async () => {
        try {
            this.loading = true;

            const request: PaginationRequest<Select2FilterRequest> = {
                request: {
                    searchString: this.searchString
                },
                pagination: this.pagination
            };

            const response = await this.props.dataSource(request);
            this.totalRows = response.pagination.totalRows;

            if (this.pagination.page === 0) {
                this.foundedItems = response.response;
                return;
            }

            this.foundedItems.push(...response.response);

        } catch {
            // TODO: пока ошибку никак не обрабатываем
        } finally {
            this.loading = false;
        }
    }

    onChangeListItem = (item: Select2Item<inferItem<TItem>>) => {
        if (this.isMultiple) {
            this.value = (item.selected
                ? (this.value as inferItem<TItem>[]).filter(value => this.getItemKeyValue(value) !== this.getItemKeyValue(item.item))
                : [...(this.value as inferItem<TItem>[]), item.item]) as ArrayOrValue<TItem>;
            this.foundedItems = this.foundedItems.map(foundItem => {
                if (this.getItemKeyValue(foundItem.item) === this.getItemKeyValue(item.item)) {
                    foundItem.selected = !item.selected
                }

                return foundItem;
            })
        } else {
            //TODO: fixed isClearable
            this.value = item.selected
                ? this.isClearable
                    ? null as ArrayOrValue<TItem>
                    : item.item as ArrayOrValue<TItem>
                : item.item as ArrayOrValue<TItem>;

            // если значение выбрано или обновлено, то сворачиваем
            if (this.value !== null) {
                this.close();
            }
        }
    }

    listItemText = (item: inferItem<TItem>) => {
        return this.props.foundedItemText
            ? this.props.foundedItemText(item)
            : this.props.itemText(item);
    }

    itemText = (item: inferItem<TItem>) => this.value !== null
        ? this.props.itemText(item)
        : null;

    getItemKeyValue = (item: inferItem<TItem>) => {
        return this.props.itemKey
            ? item !== null && item !== undefined
                ? item[this.props.itemKey]
                : null
            : item;
    }

    get isMultiple() {
        return this.props.multiple;
    }

    get groupByKey() {
        return this.props.itemGroupKey;
    }

    get hasValue() {
        return this.isValueArray
            ? (this.value as TItem[]).length > 0
            : this.value !== null;
    }

    get timeout() {
        return this.props.timeout || 500;
    }

    get checkbox() {
        return this.props.checkbox;
    }

    get hideFilter() {
        return this.props.hideFilter;
    }

    get textValue() {
        return this.hasValue
            ? this.isMultiple
                ? (this.value as unknown as []).map(value => this.itemText(value)).toString()
                : this.itemText(this.value as inferItem<TItem>)
            : this.label
    }

    get isValueArray() {
        return Array.isArray(this.value);
    }

    get isClearable() {
        return this.props.clearable;
    }

    get isDisabled() {
        return this.props.disabled;
    }

    get isError() {
        return this.props.error;
    }

    get isValid() {
        return this.props.valid;
    }

    get clearLabel() {
        return this.props.clearableLabel || "Очистить";
    }

    get helperText() {
        return this.props.helperText;
    }

    get size() {
        return !this.props.size ? "medium" : this.props.size;
    }

    get width() {
        return this.props.fullWidth
            ? "100%"
            : this.props.width || "200px";
    }

    get isShrinkLabel() {
        return this.isOpen || this.hasValue;
    }

    get label() {
        return this.props.label;
    }

    get isPlaceholder() {
        return this.props.placeholder;
    }

    get className() {
        return this.props.className;
    }

    get style() {
        return this.props.style;
    }

    get isDense() {
        return this.props.dense;
    }
}
