export enum AuthState {
    // начальное состояние, контекст авторизации не инициализирован
    //NotInitialized = 0,
    // идет процесс инициализации контекста авторизации
    Authorization = 2,
    // контекст инициализирован, пользователь авторизован
    Authorized = 4,
    // контекст не инициализирован, пользователь неавторизованный,
    // т.е. пользователь был авторизован, после чего: либо истек токен,
    // либо истекло время жизни куки авторизации
    Unauthorized = 16,
    // пользователь вышел
    LoggedOut = 32
};