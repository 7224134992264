import {CssBaseline} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FaceIcon from "@mui/icons-material/Face";
import {observer} from "mobx-react";
import {MainAppBar, MainAppContainer, MainAppOutlet} from "../../../ms-ui/ui/components/mainApp";
import {ButtonMenu} from "../../../ms-ui/ui/navigation";
import {UserAvatar} from "../../../ms-ui/ui/components";
import {MindSafeLogo} from "../../../ms-ui/ui/surfaces";
import {useTherapistAppStore} from "../../hooks/useTherapistAppStore";
import {TherapistStatus, TherapistStatusDescription} from "../../../commons/enums";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import ChatIcon from "@mui/icons-material/Chat";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {NeedClientIcon} from "./NeedClientIcon";
import {NoNeedClientIcon} from "./NoNeedClientIcon";
import {therapistAppSettings} from "../../../therapistAppSettings";

export const MainApp = observer(() => {
    const therapistAppStore = useTherapistAppStore();
    const navigate = useNavigate();
    const menuItemHandler = (to: string) => {
        navigate(to);
    }

    return <MainAppContainer>
        <CssBaseline/>
        <MainAppBar>
            <ButtonMenu icon={<MenuIcon sx={{color: "#fff"}} fontSize="medium"/>}>
                <ButtonMenu.Item
                    icon={<ChatIcon fontSize="small"/>}
                    primaryText="Чат с клиентами"
                    onClick={() => menuItemHandler(therapistAppSettings.getRoute("chat"))}
                />
                <ButtonMenu.Item
                    icon={<ContactMailIcon fontSize="small"/>}
                    primaryText="Приглашения терапевтов"
                    onClick={() => menuItemHandler(therapistAppSettings.getRoute("invitations"))}
                />
                <ButtonMenu.Item
                    icon={<SupervisorAccountIcon fontSize="small"/>}
                    primaryText="Активные сессии"
                    onClick={() => menuItemHandler(therapistAppSettings.getRoute("clientSessions"))}
                    allowedRoles={["Operator"]}
                />
            </ButtonMenu>
            <MindSafeLogo/>
            <ButtonMenu icon={therapistAppStore.user?.status === TherapistStatus.NeedClient
                ? <NeedClientIcon fontSize="medium"/>
                : <NoNeedClientIcon fontSize="medium"/>}>
                <ButtonMenu.Item
                    icon={<NeedClientIcon/>}
                    primaryText={TherapistStatusDescription[TherapistStatus.NeedClient]}
                    onClick={() => therapistAppStore.changeTherapistStatusHandler(TherapistStatus.NeedClient)}
                    selected={therapistAppStore.user?.status === TherapistStatus.NeedClient}
                />
                <ButtonMenu.Item
                    icon={<NoNeedClientIcon/>}
                    primaryText={TherapistStatusDescription[TherapistStatus.NoNeedClient]}
                    onClick={() => therapistAppStore.changeTherapistStatusHandler(TherapistStatus.NoNeedClient)}
                    selected={therapistAppStore.user?.status === TherapistStatus.NoNeedClient}
                />
            </ButtonMenu>
            <ButtonMenu icon={<UserAvatar
                src={therapistAppStore.user?.avatar ?? null}
                alt={therapistAppStore.userShortName}/>}>
                <ButtonMenu.Item
                    icon={<FaceIcon fontSize="small"/>}
                    avatar={therapistAppStore.user?.avatar ?? null}
                    primaryText={therapistAppStore.userShortName}
                    secondaryText={therapistAppStore.user?.email}
                    onClick={() => menuItemHandler(`${therapistAppSettings.getRoute("settings")}?idx=0`)}
                />
                <ButtonMenu.Divider/>
                <ButtonMenu.Item
                    icon={<SettingsIcon fontSize="small"/>}
                    primaryText="Настройки"
                    onClick={() => menuItemHandler(`${therapistAppSettings.getRoute("settings")}?idx=1`)}
                />
                <ButtonMenu.Divider/>
                <ButtonMenu.Item
                    icon={<ExitToAppIcon fontSize="small"/>}
                    primaryText="Выход"
                    onClick={therapistAppStore.onLogout}
                />
            </ButtonMenu>
        </MainAppBar>
        <MainAppOutlet/>
    </MainAppContainer>
});