import {useMemo} from "react";

import {InvitationStore} from "./InvitationStore";
import {observer} from "mobx-react";
import {useTherapistAppStore} from "../../hooks/useTherapistAppStore";
import {useStoreLifeCycle} from "../../../ms-ui/stores";
import {DataGrid} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import {StatisticsGridToolbar} from "./StatisticsGridToolbar";
import { MainAppContent } from "../../../ms-ui/ui/components/mainApp";

export const Invitations = observer(() => {
    const therapistAppStore = useTherapistAppStore();
    const store = useMemo(() => new InvitationStore(therapistAppStore), []);

    useStoreLifeCycle(store);

    return <MainAppContent sx={{ p: 1 }}>
        <Box sx={{
            width: {xs: "100%", sm: "400px"},
            mb: 1
        }}>
            <DataGrid
                columns={store.statisticColumns}
                rows={store.statistics}
                disableColumnFilter
                disableColumnMenu
                density="compact"
                hideFooter
                autoHeight
                slots={{
                    toolbar: StatisticsGridToolbar
                }}
                slotProps={{
                    toolbar: {
                        onSubmitEmails: store.sendInvitations
                    }
                }}
            />
        </Box>
        <DataGrid
            columns={store.invitationColumns}
            rows={store.invitations}
            disableColumnFilter
            disableColumnMenu
            density="compact"
            hideFooter
        />
    </MainAppContent>
})