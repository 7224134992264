import {Alert, Link} from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const RegistrationNotice = () => {
    return <Alert
        severity="success"
        icon={<InfoOutlinedIcon/>}
    >
        Сейчас работать на платформе могут только приглашенные психологи из Международной ассоциации текстовых
        консультантов и онлайн терапевтов <Link href="http://iatcot.com">IATC&OT</Link>.<br/>
        Если вы не получили инвайт — обратитесь к руководству ассоциации.
    </Alert>
}