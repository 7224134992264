import {
    Avatar,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {stringToColor} from "../../../ms-ui/helpers";
import {ClientViewModel} from "../../../models/entities";
import {Nullable} from "../../../ms-ui/types";
import {ReactNode} from "react";

interface ClientInformationProps {
    client: Nullable<ClientViewModel>;
    subscription: ReactNode;
}

export const ClientInformation = ({client, subscription}: ClientInformationProps) => {
    return <Stack
        flex={1}
        flexDirection="row"
        alignItems="center"
        sx={{
            "& > *:not(:last-child)": {
                mr: 1
            }
        }}
    >
        <Avatar
            sx={{
                bgcolor: stringToColor(client?.nickName ?? "")
            }}
            src={client?.avatar ?? undefined}
        >
            {client?.nickName}
        </Avatar>
        <Stack flex={1}>
            <Typography>{client?.nickName}</Typography>
            <Typography variant="caption" color="textSecondary">{subscription}</Typography>
        </Stack>
        <IconButton disabled>
            <MoreVertIcon/>
        </IconButton>
    </Stack>
}