import {EmailEditor} from "./EmailEditor";
import {useEffect, useState} from "react";
import {Box, Button, Stack} from "@mui/material";
import {Nullable} from "../../../types";

interface EmailConfirmEditorProps {
    email: string;
    emailConfirmed: Nullable<string>;
    emailToBeConfirmed: Nullable<string>;
    onChangeEmail: (email: string, emailToBeConfirmed: Nullable<string>) => void;
    onCancelChangeEmail: () => void;
    onConfirmationEmailResend: () => void;
}

export const EmailConfirmEditor = (props: EmailConfirmEditorProps) => {
    const {
        emailConfirmed,
        onConfirmationEmailResend,
        onCancelChangeEmail,
        onChangeEmail
    } = props;

    const [emailConfirmationMode, setEmailConfirmationMode] =
        useState(props.email !== props.emailToBeConfirmed && props.emailConfirmed !== null && props.emailToBeConfirmed !== null);
    const [email, setEmail] =
        useState<Nullable<string>>(props.email);
    const [emailToBeConfirmed, setEmailToBeConfirmed] =
        useState(props.emailToBeConfirmed);

    const isConfirmedEmail = props.email === props.emailConfirmed;

    useEffect(() => {
        setEmail(props.email);
        setEmailConfirmationMode(props.email !== props.emailToBeConfirmed && props.emailConfirmed !== null && props.emailToBeConfirmed !== null);
    }, [props.email]);

    useEffect(() => {
        setEmailToBeConfirmed(props.emailToBeConfirmed);
        setEmailConfirmationMode(props.email !== props.emailToBeConfirmed && props.emailConfirmed !== null && props.emailToBeConfirmed !== null);
    }, [props.emailToBeConfirmed]);

    return <Stack
        direction={emailConfirmationMode ? "column" : "row"}
        spacing={1}
    >
        {emailConfirmationMode
            ? <>
                <EmailEditor
                    label="E-mail"
                    email={email}
                    confirmed={email === emailConfirmed}
                    disabled={email === emailConfirmed}
                />
                <EmailEditor
                    label="Новый E-mail"
                    email={emailToBeConfirmed}
                    onChangeEmail={setEmailToBeConfirmed}
                    helperText={emailToBeConfirmed !== null && <Box component="span" color="warning.main">
                        Подвердите ваш новый адрес электронной почты.
                        Письмо с подтверждением было отправлено.
                        Если письма нет во Входящих, проверьте Спам.
                    </Box>}
                    error={emailToBeConfirmed === null}
                    disabled={props.emailToBeConfirmed !== null}
                />
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                >
                    <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={() => {
                            if (props.emailToBeConfirmed === null) {
                                onChangeEmail(email!, emailToBeConfirmed);
                            } else {
                                onConfirmationEmailResend();
                            }
                        }}
                        disabled={emailToBeConfirmed === null}
                    >
                        {props.emailToBeConfirmed === null
                            ? "Сохранить и отправить подтверждение повторно"
                            : "Отправить подтверждение повторно"}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            if (props.emailToBeConfirmed === null) {
                                setEmailToBeConfirmed(null);
                                setEmailConfirmationMode(false);
                            } else {
                                onCancelChangeEmail();
                            }
                        }}
                    >
                        Отменить смену E-Mail
                    </Button>
                </Stack>
            </>
            : <>
                <Stack
                    sx={{flex: 1}}
                    spacing={1}
                >
                    <EmailEditor
                        label="E-mail"
                        email={email}
                        onChangeEmail={setEmail}
                        confirmed={isConfirmedEmail}
                        helperText={!isConfirmedEmail && <Box component="span" color="warning.main">
                            Подвердите ваш адрес электронной почты.
                            Письмо с подтверждением было отправлено.
                            Если письма нет во Входящих, проверьте Спам.
                        </Box>}
                        disabled={isConfirmedEmail}
                    />
                    {!isConfirmedEmail && <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        onClick={props.onConfirmationEmailResend}
                    >
                        Отправить подтверждение повторно
                    </Button>}
                </Stack>
                <Stack
                    sx={{flex: 1}}
                    alignItems="flex-end"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            if (isConfirmedEmail) {
                                setEmailConfirmationMode(true);
                            } else {
                                onChangeEmail(email!, emailToBeConfirmed);
                            }
                        }}
                        disabled={email === null}
                    >
                        {email === emailConfirmed ? "Сменить E-Mail" : "Изменить E-Mail"}
                    </Button>
                </Stack>
            </>}
    </Stack>;
}