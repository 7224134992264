import {GridToolbarContainer} from "@mui/x-data-grid";
import {InvitationModal} from "./InvitationModal";

interface StatisticsGridToolbarProps {
    onSubmitEmails: (emails: string[], message: string) => void;
}

export const StatisticsGridToolbar = (props: StatisticsGridToolbarProps) => {
    return <GridToolbarContainer>
        <InvitationModal
            onSubmitEmails={props.onSubmitEmails}
        />
    </GridToolbarContainer>
}