import {makeObservable, observable} from "mobx";
import {StoreBase} from "../../../../stores";
import {Nullable} from "../../../../types";
import {ChangePasswordRequest} from "../../../../models/requests";
import {HttpClient} from "../../../../http";
import {LoginHelper} from "../../../../helpers";
import {AuthState} from "../../authentificate/types/AuthState";
import {MainAppStore} from "../../mainApp";
import {ResponseBase} from "../../../../models/responses/ResponseBase";
import appSettings from "../../../../appSettings";

export class SecurityTabStore extends StoreBase<MainAppStore> {

    private changePasswordRequest: Nullable<ChangePasswordRequest> = null;

    changePasswordDialogOpen: boolean = false;
    removeProfileDialogOpen: boolean = false;
    changePasswordSubmitBtnDisabled: boolean = true;
    removeProfileSubmitBtnDisabled: boolean = true;

    constructor(mainAppStore: MainAppStore) {
        super(mainAppStore);

        makeObservable(this, {
            changePasswordDialogOpen: observable,
            removeProfileDialogOpen: observable,
            changePasswordSubmitBtnDisabled: observable,
            removeProfileSubmitBtnDisabled: observable
        });
    }

    onChangePasswordDialogOpen = () => {
        this.changePasswordDialogOpen = true;
    }

    onChangePasswordDialogClose = () => {
        this.changePasswordDialogOpen = false;
    }

    /**
     * метод изменения пароля пользователя
     */
    onChangePassword = async () => {
        return this.execute(async () => {
            if (this.changePasswordRequest === null) {
                throw new Error("Запрос на зменение пароля не может быть null");
            }

            const request = appSettings.getService("changePassword")({request: this.changePasswordRequest});

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            if (LoginHelper.getRememberMe()) {
                LoginHelper.setPassword(this.changePasswordRequest.new);
            }
            this.changePasswordRequest = null;
            this.onChangePasswordDialogClose();
            this.appStore.success = "Пароль успешно изменен!";
        });
    }

    /**
     * метод получения запроса на изменение пароля пользователя
     * @param request запрос на изменение пароля пользваотеля
     * @param isValid признак, того, что запрос на изменение пользователя валиден
     * @returns
     */
    onChangePasswordRequest = (request: ChangePasswordRequest, isValid: boolean) => {
        this.changePasswordSubmitBtnDisabled = !isValid;

        if (isValid) {
            this.changePasswordRequest = request;
        }
    }

    onRemoveProfileDialogOpen = () => {
        this.removeProfileDialogOpen = true;
    }

    onRemoveProfileDialogClose = () => {
        this.removeProfileDialogOpen = false;
    }

    onChangeConfirmState = (state: boolean) => {
        this.removeProfileSubmitBtnDisabled = !state;
    }

    onRemoveProfile = async () => {
        return this.execute(async () => {
            if (this.removeProfileSubmitBtnDisabled) {
                return Promise.resolve();
            }

            const request = appSettings.getService("removeProfile")();

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);

            await this.appStore.authStore.onLogout(AuthState.LoggedOut);
        });
    }
}