import { IconButton, SvgIconTypeMap, Tooltip } from "@mui/material";

import { ClearIcon } from "../../icons";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Select2Context } from "./contexts";
import { observer } from "mobx-react";
import { useContext } from "react";

interface ClearButtonProps {
	icon?: OverridableComponent<SvgIconTypeMap>;
}

export const ClearButton = observer((props: ClearButtonProps) => {
	const store = useContext(Select2Context);
	const Icon = props.icon
		? props.icon
		: ClearIcon;

	if (store.isDisabled
		|| !store.isClearable
		|| !store.hasValue) {
		return null;
	}

	return <Tooltip title={store.clearLabel}>
		<IconButton
			size="small"
			onClick={(e) => {
				e.stopPropagation();
				store.clear();
			}}
		>
			<Icon
				fontSize="small"
				color="error"
			/>
		</IconButton>
	</Tooltip>
})
