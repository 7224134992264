import {SecurityTabStore} from "../stores/SecurityTabStore";
import {observer} from "mobx-react";
import {EnhancedDialog} from "../../EnhancedDialog";
import {ChangePassword} from "../../changePassword/ChangePassword";

interface ChangePasswordDialogProps {
    store: SecurityTabStore;
}

export const ChangePasswordDialog = observer((props: ChangePasswordDialogProps) => {
    const {store} = props;

    return <EnhancedDialog
        title="Сменить пароль"
        open={store.changePasswordDialogOpen}
        submitButtonOption={{
            disabled: store.changePasswordSubmitBtnDisabled,
            onClick: store.onChangePassword
        }}
        cancelButtonOption={{
            onClick: store.onChangePasswordDialogClose
        }}
        maxWidth="sm"
        disallowBackdropClick
        actions
        fullWidth
    >
        <ChangePassword onChangeRequest={store.onChangePasswordRequest}/>
    </EnhancedDialog>
})