import {FC} from "react";
import {AppBar, Toolbar} from "@mui/material";

export const MainAppBar: FC = ({children}) => {
    return <AppBar
        position="static"
        sx={{
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            mb: 1
        }}
    >
        <Toolbar
            sx={{
                minHeight: "56px !important",
                padding: "0 8px !important",
                "& > *": {
                    marginRight: 1,
                    "&:last-child": {
                        marginRight: 0
                    }
                }
            }}
        >
            {children}
        </Toolbar>
    </AppBar>
}