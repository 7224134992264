import axios, {Axios, AxiosRequestConfig} from "axios";
import {dateTransformer} from "./dateTransformer";
import {RequestBase} from "../models/requests/RequestBase";
import {ResponseBase} from "../models/responses/ResponseBase";
import accessTokenFactory from "./accessTokenFactory";

export class HttpClient {
    private readonly _instance: Axios;
    private _useAuthorization: boolean = false;
    private _throwErrorIfResponseIsNull: boolean = false;

    constructor() {
        this._instance = axios.create({timeout: 30000 * 6, transformResponse: dateTransformer});
    }

    useAuthorization = () => {
        this._useAuthorization = true;
        return this;
    }

    throwErrorIfResponseIsNull = () => {
        this._throwErrorIfResponseIsNull = true;
        return this;
    }

    execute = async <TResponseOuter extends ResponseBase<TResponseInner>, TResponseInner = {}>(request: AxiosRequestConfig<RequestBase<any>>)
        : Promise<TResponseInner> => {

        if (this._useAuthorization) {
            // если во время обновления токена безопасности произошла ошибка и его не получилось обновить, прерываем
            // выполнение основного запроса, и выполняем выход из приложения
            request.headers = {
                "Authorization": `Bearer ${await accessTokenFactory.accessToken}`
            }
        }

        const response = await this._instance.request<TResponseOuter>(request);

        if (response.status >= 401 && response.status <= 403) {
            throw new Error("Невозможно выполнить запрос, необходимо повторный логин пользователя.");
        }

        if (response.status > 500) {
            throw new Error("Произошла ошибка во время выполнения запроса, от сервиса не получен ответ или полученный ответ не может быть разобран");
        }

        if (response.status === 204) {

            if (this._throwErrorIfResponseIsNull) {
                throw new Error("Произошла ошибка во время выполнения запроса, от сервиса получен пустой ответ");
            }
        }

        if (response.data.isError) {
            throw new Error(response.data.errorReason);
        }

        return response.data.response;
    }
}