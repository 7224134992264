import { FC, useContext } from "react";

import { Box } from "@mui/material";
import { HelperText } from "./HelperText";
import { Select2Context } from "./contexts";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useCommonStyles } from "./commonStyles";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		minWidth: 0,
		borderRadius: "4px",
		position: "relative",
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		boxSizing: "border-box"
	},
	disabled: {
		color: "rgba(0, 0, 0, 0.54)",
		pointerEvents: "none",
		"& > $icon": {
			color: "rgba(0, 0, 0, 0.26)"
		}
	},
	//TODO: на данный момент это свойство не используется, потому что оно перекрыто в классе root
	fullWidth: {
		width: "100%",
	}
}))

export const OutlinedBaseComponent: FC = observer((props) => {
	const store = useContext(Select2Context);
	const commonClasses = useCommonStyles({ dense: store.isDense });
	const classes = useStyles({ dense: store.isDense });

	return <Box
		className={store.className}
		style={{
			width: store.width,
			...store.style
		}}
	>
		<div
			tabIndex={0}
			className={clsx(
				classes.root,
				{
					[commonClasses.border]: store.isPlaceholder || !store.label || !store.isShrinkLabel,
					[commonClasses.error]: (store.isPlaceholder || !store.label || !store.isShrinkLabel) && store.isError,
					[commonClasses.valid]: (store.isPlaceholder || !store.label || !store.isShrinkLabel) && store.isValid,
					[classes.disabled]: store.isDisabled,
					[commonClasses.medium]: store.size === "medium",
					[commonClasses.small]: store.size === "small"
				}
			)}
			onClick={store.open}
			ref={element => store.anchorElement = element}
		>
			{props.children}
		</div>
		<HelperText />
	</Box>;
})
