import {observer} from "mobx-react";
import {OverlayFlexContainer} from "../../../ms-ui/ui/components/OverlayFlexContainer";
import {Fragment, useLayoutEffect, useMemo} from "react";
import {Nullable} from "../../../ms-ui/types";
import {MessagesStore} from "./stores/MessagesStore";
import {useTherapistAppStore} from "../../hooks/useTherapistAppStore";
import {ClientViewModel} from "../../../models/entities";
import {useStoreLifeCycle} from "../../../ms-ui/stores";
import {Message} from "./Message";
import {MessageEditor} from "./MessageEditor";
import {ReplayMessage} from "./ReplayMessage";
import {green, lightGreen} from "@mui/material/colors";
import dayjs from "dayjs";
import {Typography} from "@mui/material";
import _ from "underscore";
import {ChatSettingsHelper} from "./helpers/ChatSettingsHelper";

interface MessagesProps {
    client: Nullable<ClientViewModel>;
    onCounterOfUnreadClientMessagesChange: (clientId: string, value: number) => void;
}

export const Messages = observer(({client, onCounterOfUnreadClientMessagesChange}: MessagesProps) => {
    const therapistAppStore = useTherapistAppStore();
    const store = useMemo(() => new MessagesStore(therapistAppStore, client, onCounterOfUnreadClientMessagesChange), [client]);

    useLayoutEffect(() => {

        store.onScrollToMessageHandler();
    }, [store.scrollToMessage]);

    useStoreLifeCycle(store);

    return <>
        <OverlayFlexContainer
            sx={[
                {
                    px: 0.5,
                    pt: 1,
                    border: "1px solid rgba(0,0,0, 0.12)",
                    justifyContent: "flex-start",
                    borderLeftWidth: 0,
                    borderRightWidth: 0
                },
                ChatSettingsHelper.getIsChatBackgroundEnabled() == true && {
                    backgroundImage: `linear-gradient(to right, ${green["A100"]}, ${lightGreen["A100"]})`
                }
            ]}
            onScroll={store.onScrollMessagesHandler}
        >
            {_.keys(_.groupBy(store.messages, message => dayjs(message.createdDateTime).startOf("date").toString()))
                .map(key => <Fragment key={key}>
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                        mt={1}
                        mb={2}
                    >
                        {dayjs(key).format("DD.MM.YYYY")}
                    </Typography>

                    {store.messages.filter(message => dayjs(message.createdDateTime).startOf("date").isSame(dayjs(key)))
                        .map(message => <Message
                            key={`${key}#${message.id}`}
                            message={message}
                            onMarkReply={store.onMarkReplyHandler}
                        />)}
                </Fragment>)}
        </OverlayFlexContainer>
        <ReplayMessage
            reply={store.reply}
            onCancelReplay={store.onCancelReplyHandler}
        />
        <MessageEditor
            client={client}
            reply={store.reply}
            onCancelReplay={store.onCancelReplyHandler}
        />
    </>
})