import {BorderedBox} from "./BorderedBox";
import {Typography} from "@mui/material";

export const Logging = () => {
    return <BorderedBox>
        <Typography
            component="div"
            variant="h5"
            color="primary"
        >
            Выполняется вход
        </Typography>
    </BorderedBox>;
}