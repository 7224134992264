import { Select2Context } from "./contexts";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { useCommonStyles } from "./commonStyles";
import { useContext } from "react";

export const HelperText = () => {
	const store = useContext(Select2Context);
	const commonClasses = useCommonStyles();

	return <Typography
		className={clsx(store.isValid && commonClasses.successColor)}
		variant="caption"
		color={store.isError ? "error" : "textSecondary"}
	>
		{store.helperText}
	</Typography>;
}
