import {grey} from "@mui/material/colors";
import {Box, BoxProps} from "@mui/material";
import {mergeSx} from "merge-sx";

export const OverlayFlexContainer = ({children, sx, ...otherProps}: BoxProps) => {
    return <Box
        sx={mergeSx({
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "overlay",
            "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px"
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: grey[400],
                borderRadius: "2px",
                display: "block"
            },
            "&::-webkit-scrollbar-corner": {
                backgroundColor: grey[400],
                borderRadius: "50%"
            }
        }, sx)}
        {...otherProps}
    >
        {children}
    </Box>
}