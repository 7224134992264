import {makeAutoObservable} from "mobx";
import {AuthState} from "./types/AuthState";
import {AuthRepository} from "./repositories/AuthRepository";
import {Nullable} from "../../../types";
import {AuthHelper} from "../../../helpers";

export class AuthStore {
    state: AuthState = AuthState.Authorization;
    userId: Nullable<string> = null;
    private _authRepository = new AuthRepository();

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            const {
                accessToken,
                accessTokenExpires,
                id
            } = await this._authRepository.updateAccessToken();

            this._setState(accessToken, accessTokenExpires, id);
            this.state = AuthState.Authorized;
        } catch (error) {
            this.state = AuthState.Unauthorized;
        }
    }

    onLogin = async (login: string, password: string, post?: VoidFunction) => {
        try {
            const [fingerPrint, browser, os, timeZone, userAgent] = AuthHelper.getAuthData();
            const loginRequest = {
                login,
                password,
                fingerPrint,
                browser,
                os,
                timeZone,
                userAgent
            };

            const {
                accessToken,
                accessTokenExpires,
                id
            } = await this._authRepository.login(loginRequest);

            this._setState(accessToken, accessTokenExpires, id);
            this.state = AuthState.Authorized;

            // выполним действие после успешного логина пользователя
            post && post();
        } catch (error) {
            this._resetState(AuthState.Unauthorized);
            throw new Error("Невозможно выполнить вход, повторите попытку.");
        }
    }

    onLogout = async (state: AuthState.LoggedOut | AuthState.Unauthorized) => {
        try {
            await this._authRepository.logout();
        } catch (error) {

        } finally {
            this._resetState(state);
        }
    }

    private _setState = (accessToken: string, accessTokenExpires: number, userId: string) => {
        AuthHelper.setAccessTokenData({
            accessToken: `Bearer ${accessToken}`,
            accessTokenExpires
        });
        this.userId = userId;
    }

    private _resetState = (state: AuthState) => {
        AuthHelper.setAccessTokenData(null);
        this.userId = null;

        this.state = state;
    }
}