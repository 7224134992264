import {useMediaQuery, useTheme} from "@mui/material";
import {useEffect} from "react";

export const useResolution = (onChangeResolution: (isLowResolution: boolean) => void) => {
    const theme = useTheme();
    const isLowResolution = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        onChangeResolution(isLowResolution);
    }, [isLowResolution]);
}