import { ClickAwayListener, Paper, Popover, PopoverPosition } from "@mui/material";
import { useEffect, useState } from "react";

import { FoundValues } from "./FoundedItems";
import { OutlinedTextField } from "../OutlinedTextField";
import { Select2Context } from "./contexts";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useCommonStyles } from "./commonStyles";
import { useContext } from "react"

const useStyles = makeStyles(theme => ({
	paper: {
		display: "flex",
		flexDirection: "column",
		minHeight: "104px",
		overflow: "hidden",
		"&:focus": {
			outline: "none",
		},
		padding: "2px"
	},
}))

export const SearchPanel = observer(() => {
	const store = useContext(Select2Context);
	const commonClasses = useCommonStyles();
	const classes = useStyles();
	const [popoverPosition, setPopoverPosition] = useState<PopoverPosition>({ top: 0, left: 0 })
	const [width, setWidth] = useState("100%");

	useEffect(() => {
		if (store.isOpen) {
			const anchorBounds = store.anchorElement?.getBoundingClientRect();

			if (!anchorBounds) {
				return;
			}

			// константа delta = складывается из TextField (hideFilter = true → 40px, hideFilter = false → 0px) + Select2FoundValues (162px),
			// тем самым двигаем Popover, если при открытом состоянии он будет выходить за рамки экрана
			const delta = 162 + (!store.hideFilter ? 40 : 0);

			const top = window.innerHeight - anchorBounds.y < delta
				? anchorBounds.y - (delta - window.innerHeight + anchorBounds.y)
				: anchorBounds.y;
			const left = anchorBounds.x;
			setPopoverPosition({ top, left });

			setWidth(`${anchorBounds.width}px`);
		}
	}, [store.isOpen])

	return <Popover
		open={store.isOpen}
		anchorReference="anchorPosition"
		anchorPosition={popoverPosition}
	>
		<ClickAwayListener onClickAway={store.close}>
			<Paper
				elevation={5}
				className={clsx(classes.paper, !store.isOpen && commonClasses.hidden)}
				style={{ width }}
			>
				{!store.hideFilter && <OutlinedTextField
					value={store.searchString}
					onChange={value => store.onChangeSearchString(value)}
					clearable
					fullWidth
					size={store.size}
					dense={store.isDense}
				/>}
				<FoundValues />
			</Paper>
		</ClickAwayListener>
	</Popover>
});
