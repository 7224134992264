import {Box, Button, Stack, Tooltip} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {observer} from "mobx-react";
import {useMemo} from "react";
import {MessageEditorStore} from "./stores/MessageEditorStore";
import {useTherapistAppStore} from "../../hooks/useTherapistAppStore";
import {OutlinedTextField} from "../../../ms-ui/ui/controls";
import {Nullable} from "../../../ms-ui/types";
import {ClientViewModel, MessageViewModel} from "../../../models/entities";

interface MessageEditorProps {
    client: Nullable<ClientViewModel>;
    reply: Nullable<MessageViewModel>;
    onCancelReplay: VoidFunction;
    disabled?: boolean;
}

export const MessageEditor = observer(({client, reply, onCancelReplay, disabled}: MessageEditorProps) => {
    const therapistAppStore = useTherapistAppStore();
    const store = useMemo(() => new MessageEditorStore(therapistAppStore, client), [client]);

    return <Stack
        direction="row"
        sx={{
            p: 1
        }}
    >
        <Box sx={{flex: 1}}>
            <OutlinedTextField
                placeholder="Напишите сообщение..."
                sx={{
                    "& textarea": {
                        fontSize: "0.875rem",
                        lineHeight: "1.43"
                    }
                }}
                value={store.text}
                onChange={store.onChangeTextHandler}
                multiline
                rows={2}
                fullWidth
                onKeyDown={e => store.onChangeKeyDownHandler(e, reply, onCancelReplay)}
                helperText={<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <span><strong>{store.textLengthLeft}</strong></span>
                    <Tooltip title="Отправить сообщение">
                        <Button
                            onClick={() => store.onSendMessageHandler(reply, onCancelReplay)}
                            disabled={disabled || store.sendButtonDisabled}
                            color="primary"
                            variant="contained"
                            size="small"
                            sx={{
                                minWidth: "30px",
                                minHeight: "30px",
                                p: 0.5,
                                borderRadius: "50%"
                            }}
                        >
                            <SendIcon
                                fontSize="small"
                                sx={{
                                    marginLeft: "1px"
                                }}
                            />
                        </Button>
                    </Tooltip>
                </Stack>}
                disabled={disabled}
            />
        </Box>
    </Stack>;
})