import {LoadingIndicator} from "./LoadingIndicator";
import {observer} from "mobx-react";
import {useMainAppStore} from "../components/mainApp";
import {EnhancedSnackbar} from "../components/EnhancedSnackbar";

export const Lce = observer(() => {
    const mainAppStore = useMainAppStore();

    return <>
        <EnhancedSnackbar
            open={Boolean(mainAppStore.error)}
            message={mainAppStore.error?.message}
            variant="filled"
            severity="error"
            onClose={() => mainAppStore.error = null}
        />
        <EnhancedSnackbar
            open={Boolean(mainAppStore.success)}
            message={mainAppStore.success}
            variant="filled"
            severity="success"
            onClose={() => mainAppStore.success = null}
        />
        <LoadingIndicator loading={mainAppStore.isLoading}/>
    </>
})
