import {IconButton, MenuItem, Stack, Tooltip} from "@mui/material";
import {useState} from "react";
import {ClientTherapistSessionViewModel} from "../../../models/entities/ClientTherapistSessionViewModel";
import {TherapistViewModel} from "../../../models/entities";
import {EnhancedDialog} from "../../../ms-ui/ui/components/EnhancedDialog";
import {OutlinedEnhancedSelect, OutlinedTextField} from "../../../ms-ui/ui/controls";
import {getPersonName} from "../../../ms-ui/helpers";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

interface ChangeTherapistModalProps {
    session: ClientTherapistSessionViewModel;
    therapists: TherapistViewModel[];
    onChange: (newTherapistId: string) => void;
}

export const ChangeTherapistModal = (({session, therapists, onChange}: ChangeTherapistModalProps) => {
    const [open, setOpen] = useState(false);
    const [newTherapistId, setNewTherapistId] = useState<string>("none");

    const onSubmitHandler = () => {
        onChange(newTherapistId);
        setNewTherapistId("none");
        setOpen(false);
    }

    const onCloseHandler = () => {
        setNewTherapistId("none");
        setOpen(false);
    }

    return <>
        <Tooltip title="Заменить терапевта">
            <IconButton
                color="primary"
                onClick={() => setOpen(true)}
                size="small"
            >
                <ManageAccountsIcon/>
            </IconButton>
        </Tooltip>
        <EnhancedDialog
            title="Замена терапевта"
            open={open}
            submitButtonOption={{
                label: "Заменить",
                onClick: onSubmitHandler,
                disabled: newTherapistId === "none" || session.therapist.id === newTherapistId
            }}
            cancelButtonOption={{
                onClick: onCloseHandler
            }}
            disallowBackdropClick
            maxWidth="sm"
            fullWidth
            actions
        >
            <Stack spacing={1}>
                <OutlinedTextField
                    label="Текущий терапевт"
                    value={getPersonName(session.therapist.lastName, session.therapist.firstName, session.therapist.middleName)}
                    disabled
                    fullWidth
                />
                <OutlinedEnhancedSelect
                    label="Новый терапевт"
                    value={newTherapistId}
                    onChange={value => setNewTherapistId(value)}
                    fullWidth
                >
                    <MenuItem value="none">Не выбран</MenuItem>
                    {therapists.map(therapist => <MenuItem
                        key={therapist.id!}
                        value={therapist.id!}
                    >
                        {getPersonName(therapist.lastName, therapist.firstName, therapist.middleName)}
                    </MenuItem>)}
                </OutlinedEnhancedSelect>
            </Stack>
        </EnhancedDialog>
    </>
})