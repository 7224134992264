import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Select as MuiSelect,
    SelectChangeEvent,
    SelectProps as MuiSelectProps,
    Tooltip
} from "@mui/material";

import {ClearIcon} from "../icons";
import {PropsWithChildren} from "react";
import clsx from "clsx";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    dense: {
        "& .MuiOutlinedInput-input": {
            paddingTop: "6px",
            paddingBottom: "6px"
        },
        "& .MuiInputLabel-outlined": {
            transform: "translate(14px, 8px) scale(1)",
            "&.MuiInputLabel-shrink": {
                transform: "translate(14px, -8px) scale(0.75)"
            }
        }
    },
    inputAdornment: {
        marginRight: "16px !important"
    }
}));

export type OutlinedEnhancedSelectProps<TValue> = Omit<MuiSelectProps<TValue>, "onChange"> & {
    onClear?: VoidFunction;
    size?: "small" | "medium";
    dense?: boolean;
    onChange?: (value: TValue) => void;
    width?: string;
};

export const OutlinedEnhancedSelect = <TValue, >(props: PropsWithChildren<OutlinedEnhancedSelectProps<TValue>>) => {
    const classes = useStyles();
    const {onClear, fullWidth, size = "small", label, value, dense, onChange, width = "200px", ...otherProps} = props;

    const onChangeHandler = (event: SelectChangeEvent<TValue>) => {
        onChange && onChange(event.target.value as TValue);
    }

    return <FormControl
        variant="outlined"
        size={size}
        className={clsx(dense && classes.dense)}
        fullWidth={fullWidth}
        sx={[
            !fullWidth && {width}
        ]}
    >
        <InputLabel
            error={props.error}
        >
            {label}
        </InputLabel>
        <MuiSelect
            label={label}
            value={value}
            onChange={onChangeHandler}
            {...otherProps}
            endAdornment={(onClear && Boolean(value))
                ? <InputAdornment position="start" className={classes.inputAdornment}>
                    <Tooltip title="Очистить">
                        <IconButton
                            size="small"
                            onClick={onClear}
                            edge="end"
                            color="error"
                        >
                            <ClearIcon fontSize="small"/>
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
                : undefined
            }
        >
            {props.children}
        </MuiSelect>
    </FormControl>;
}
