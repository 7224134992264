import "dayjs/locale/ru";
import {Navigate, Route} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import {useEffect, useMemo} from "react";
import {TherapistAppStore} from "./stores/TherapistAppStore";
import {AuthStore} from "./ms-ui/ui/components/authentificate/AuthStore";
import {MainAppProvider} from "./ms-ui/ui/components/mainApp";
import {Login} from "./ms-ui/ui/components/login/Login";
import {AuthRoute} from "./ms-ui/ui/components/authentificate/AuthRoute";
import {MindsafeTheme} from "./ms-ui/theme";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ru from "dayjs/locale/ru";
import {MainApp} from "./ui/components/MainApp/MainApp";
import {AuthRouteElement} from "./ms-ui/ui/components/authentificate/AuthRouteElement";
import {RegistrationNotice} from "./ui/components/RegistrationNotice";
import {Settings} from "./ui/components/Settings/Settings";
import {ClientSessions} from "./ui/components/ClientSessions/ClientSessions";
import {Registration} from "./ui/components/Registration/Registration";
import {Invitations} from "./ui/components/Invitations/Invitations";
import {Chat} from "./ui/components/Chat/Chat";
import {Typography} from "@mui/material";
import {therapistAppSettings} from "./therapistAppSettings";

dayjs.locale(ru);
dayjs.extend(localizedFormat);

export const App = () => {
    const therapistAppStore = useMemo(() => new TherapistAppStore(AuthStore), []);

    useEffect(() => {
        therapistAppStore.init().then();
    }, []);

    return <ThemeProvider theme={MindsafeTheme}>
        <MainAppProvider store={therapistAppStore}>
            <Route path={therapistAppSettings.getRoute("login")}
                   element={<Login
                       top={<Typography color="primary" variant="h5" sx={{mb: 4}}>вход для терапевта</Typography>}
                       bottom={<RegistrationNotice/>}
                   />}
            />
            <Route path={therapistAppSettings.getRoute("registration")} element={<Registration/>}/>
            <Route
                path="/"
                element={
                    <AuthRoute>
                        <MainApp/>
                    </AuthRoute>}>
                <Route index element={<Navigate to={`${therapistAppSettings.getRoute("chat")}`}/>}/>
                <Route path={therapistAppSettings.getRoute("settings")} element={<Settings/>}/>
                <Route path={therapistAppSettings.getRoute("chat")} element={<Chat/>}/>
                <Route path={therapistAppSettings.getRoute("invitations")} element={<Invitations/>}/>
                <Route path={therapistAppSettings.getRoute("clientSessions")}
                       element={<AuthRouteElement allowedRoles={["Operator"]}>
                           <ClientSessions/>
                       </AuthRouteElement>}/>
            </Route>
        </MainAppProvider>
    </ThemeProvider>
}