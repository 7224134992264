import {
    FormControl, FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    OutlinedInputProps,
    Tooltip
} from "@mui/material";
import React, {ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState} from "react";

import {ClearIcon} from "../icons";
import {InputAdornmentComponent} from "../types/InputAdornmentComponent";
import {v4} from "uuid";
import {sxMerge} from "merge-sx";

const ClearAdornment = ({title, onClick}: InputAdornmentComponent) => {
    return <InputAdornment
        sx={{pr: 1}}
        position="start"
    >
        <Tooltip title={title ?? "Очистить поле"}>
            <IconButton
                size="small"
                onClick={onClick}
                edge="end"
                tabIndex={-1}
                color="error"
            >
                <ClearIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
    </InputAdornment>
}

export interface OutlinedTextFieldProps extends Omit<OutlinedInputProps, "onChange" | "variant"> {
    size?: "small" | "medium";
    dense?: boolean;
    onChange?: (value: string) => void;
    clearable?: boolean;
    endAdornments?: ReactNode[];
    shrink?: boolean;
    fullWidth?: boolean;
    helperText?: ReactNode;
}

export const OutlinedTextField = (props: OutlinedTextFieldProps) => {
    const labelRef = useRef<HTMLLabelElement>(null);
    const [id] = useState(v4());
    const {
        value,
        sx,
        size = "small",
        dense,
        clearable,
        onChange,
        endAdornments,
        shrink,
        inputProps,
        fullWidth,
        helperText,
        multiline,
        ...otherProps
    } = props;

    useEffect(() => {
        setLegendWidth();
        window.addEventListener("resize", setLegendWidth);

        return () => window.removeEventListener("resize", setLegendWidth);
    }, [])

    const setLegendWidth = useCallback(() => {
        if (!labelRef.current) {
            return;
        }

        const initialWidth = labelRef.current.getBoundingClientRect().width;

        const width = labelRef.current.dataset["shrink"] === "true"
            ? initialWidth
            : initialWidth * .75;


        const querySelector = `div[data-id=\"${id}\"] legend`;
        const legendElement = document
            .querySelector(querySelector) as HTMLElement;

        if (!legendElement) {
            return;
        }

        legendElement.style.width = width === 0
            ? "0px"
            : `${width + 10}px`;
    }, []);

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange && onChange(e.target.value);
    }

    return <FormControl
        fullWidth={fullWidth}
        variant="outlined"
        size={size}
        sx={[
            Boolean(dense) && {
                "& .MuiInputBase-multiline": {
                    paddingTop: 0,
                    paddingBottom: 0
                },
                "& .MuiOutlinedInput-input": {
                    paddingTop: "5px",
                    paddingBottom: "4px"
                },
                "& .MuiInputLabel-outlined": {
                    transform: "translate(14px, 6px) scale(1)",
                    "&.MuiInputLabel-shrink": {
                        transform: "translate(14px, -8px) scale(0.75)"
                    }
                }
            }
        ]}
    >
        <InputLabel
            ref={labelRef}
            error={props.error}
            shrink={shrink}
            sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "calc(100% - 24px)"
            }}
        >
            {props.label}
        </InputLabel>
        <OutlinedInput
            sx={sxMerge([Boolean(shrink) && {
                "& legend":
                    {
                        maxWidth: "1000px"
                    }
            },
                {
                    "& .MuiOutlinedInput-adornedEnd": {
                        pr: 1
                    }
                },
                Boolean(multiline) && {
                    "& textarea::-webkit-scrollbar": {
                        display: "none"
                    }
                }], sx)}
            value={value}
            multiline={multiline}
            {...otherProps}
            inputProps={{
                autoComplete: "off",
                ...inputProps
            }}
            onChange={onChangeHandler}
            autoComplete="off"
            endAdornment={<>
                {(endAdornments && endAdornments.length) ? endAdornments.map((endAdornment, idx) => <React.Fragment
                    key={idx}>{endAdornment}</React.Fragment>) : undefined}
                {(clearable && Boolean(value)) ? <ClearAdornment onClick={() => onChange && onChange("")}/> : undefined}
            </>
            }
            data-id={id}
        />
        {helperText && <FormHelperText
            margin="dense"
            sx={{
                mx: 0
            }}
            component="div"
        >
            {helperText}
        </FormHelperText>}
    </FormControl>
}
