import {Nullable} from "../types";

export class StorageHelper {
    /**
     * Получить объект типа TResult из хранилища, по умолчанию возвращаемый тип string
     * @param key Ключ
     * @param storage Хранилище из которого будет получено значение, по умолчанию localStorage, достпуно также sessionStorage
     * @returns Объект типа TResult из хранилища, по умолчанию возвращаемый тип string
     */
    static getItem = <TResult = string>(key: string, storage: Storage = localStorage): Nullable<TResult> => {
        const value = storage.getItem(key);
        return value !== null
            ? JSON.parse(value!) as unknown as TResult
            : null;
    }

    /**
     * Добавить объект типа TValue в хранилище
     * @param key Ключ
     * @param value Значение типа TValue, если передано значение null, то объект будет удален из хранилища
     * @param storage Хранилище в которое будет будет добавлено значение, по умолчанию localStorage, достпуно также sessionStorage
     */
    static setItem = <TValue>(key: string, value: Nullable<TValue>, storage: Storage = localStorage) => {
        if (value === null) {
            storage.removeItem(key);
            return;
        }

        storage.setItem(key, JSON.stringify(value));
    }
}
