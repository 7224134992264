import {MainAppContent} from "../../../ms-ui/ui/components/mainApp";
import {Clients} from "./Clients";
import {useMemo} from "react";
import {ChatStore} from "./stores/ChatStore";
import {useTherapistAppStore} from "../../hooks/useTherapistAppStore";
import {useStoreLifeCycle} from "../../../ms-ui/stores";
import {observer} from "mobx-react";
import {useResolution} from "./hooks/useResolution";
import {ClientsChat} from "./ClientsChat";

export const Chat = observer(() => {
    const therapistAppStore = useTherapistAppStore();
    const store = useMemo(() => new ChatStore(therapistAppStore), []);

    useResolution(store.onChangeResolution);
    useStoreLifeCycle(store);

    return <MainAppContent sx={{flexDirection: "row"}}>
        <Clients store={store}/>
        <ClientsChat store={store}/>
    </MainAppContent>
})