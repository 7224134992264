import { ClearButton } from "../ClearButton";
import { Collapse } from "./Collapse";
import { Label } from "../Label";
import { OpenIcon } from "../OpenIcon";
import { OutlinedBaseComponent } from "../OutlinedBaseComponent";
import { SearchPanel } from "../SearchPanel";
import { Select2Context } from "../contexts";
import { Select2Props } from "../types";
import { Select2Store } from "../stores";
import { TextValue } from "./TextValue";
import { Value } from "../Value";
import { observer } from "mobx-react";
import { useMemo } from "react";

export const Select2Collapsed = observer(<TValue,>(props: Select2Props<TValue>) => {
	const store = useMemo(() => new Select2Store<TValue>(props), [props.value]);

	return <Select2Context.Provider value={store}>
		{store.isDisabled
			? <TextValue />
			: <Collapse>
				<OutlinedBaseComponent>
					<Label />
					<Value />
					<OpenIcon />
					<ClearButton />
					<SearchPanel />
				</OutlinedBaseComponent>
			</Collapse>}
	</Select2Context.Provider>;
})
