import { ReactNode, useContext } from "react";

import { Box } from "@mui/material";
import { Select2Context } from "../contexts";
import { TextValue } from "./TextValue";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useCommonStyles } from "../commonStyles";

interface CollapseProps {
	children: ReactNode;
}
export const Collapse = observer((props: CollapseProps) => {
	const store = useContext(Select2Context);
	const commonClasses = useCommonStyles({ dense: store.isDense });

	return <Box
		style={{
			width: store.width
		}}
		className={clsx(!store.mouseOver && {
			[commonClasses.medium]: store.size === "medium",
			[commonClasses.small]: store.size === "small"
		})}
		onMouseEnter={store.mouseEnter}
		onMouseLeave={store.mouseLeave}
	>
		{store.mouseOver
			? props.children
			: <TextValue />}
	</Box>
})
