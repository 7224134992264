import {makeObservable} from "mobx";
import {MainAppStore} from "../ui/components/mainApp";
import axios from "axios";
import {UnauthorizedAccessException} from "../models/exceptions";
import {AuthState} from "../ui/components/authentificate/types/AuthState";

export class StoreBase<TAppStore extends MainAppStore> {

    constructor(protected appStore: TAppStore) {
        makeObservable(this, {});
    }

    execute = async <T>(action: () => Promise<T>) => {
        try {
            this.appStore.isLoading = true;
            await action();
        } catch (error) {
            if (error instanceof UnauthorizedAccessException) {
                await this.appStore.authStore.onLogout(AuthState.Unauthorized);
            } else if (axios.isAxiosError(error)) {
                this.appStore.error = error.cause ?? new Error(error.message);
            } else if (error instanceof Error) {
                this.appStore.error = error;
            }
        } finally {
            this.appStore.isLoading = false;
        }
    };

    async componentDidMount() {
    }

    async componentDidUnmount() {
    }
}
