import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {observer} from "mobx-react";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {ReactNode, useEffect, useMemo, useRef} from "react";
import {BorderedBox} from "../../surfaces";
import {LoginStore} from "./LoginStore";
import {EnhancedButton, EnhancedContainedButton, OutlinedTextField} from "../../controls";
import {OutlinedPasswordTextField} from "../../controls/OutlinedPasswordTextField";
import {EnhancedCheckbox} from "../../controls/EnhancedCheckbox";
import {useMainAppStore} from "../mainApp";
import appSettings from "../../../appSettings";

interface LoginProps {
    top?: ReactNode;
    bottom?: ReactNode;
}

export const Login = observer(({top, bottom}: LoginProps) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const mainAppStore = useMainAppStore();
    const store = useMemo(() => new LoginStore(mainAppStore, onLoginPostAction), []);
    const loginFieldRef = useRef<HTMLInputElement>(null);
    const passwordFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (loginFieldRef?.current != null && passwordFieldRef?.current != null) {
            loginFieldRef.current.focus();
            loginFieldRef.current.onkeydown = e => {
                if (e.key === "Enter") {
                    passwordFieldRef.current!.focus();
                }
            };
            passwordFieldRef.current.onkeydown = e => {
                if (e.key === "Enter") {
                    store.onLogin();
                }
            };
        }
    }, []);

    function onLoginPostAction() {
        const returnUrl = searchParams.has("returnUrl")
            ? searchParams.get("returnUrl")!
            : "/";
        navigate(returnUrl);
    }

    const onRecoveryPasswordClick = () => {
        navigate(`${appSettings.getRoute("passwordRecovery")}?email=${store.login}`);
    }

    return <BorderedBox>
        {top}
        <OutlinedTextField
            label="E-mail или телефон"
            value={store.login}
            onChange={store.onLoginChange}
            fullWidth
            inputProps={{
                ref: loginFieldRef
            }}
        />
        <OutlinedPasswordTextField
            label="Пароль"
            value={store.password}
            onChange={store.onPasswordChange}
            fullWidth
            ref={passwordFieldRef}
        />
        <EnhancedCheckbox
            label="Запомнить меня"
            checked={store.rememberMe}
            onChange={store.onRememberMeChange}
            fullWidth
        />
        <EnhancedContainedButton
            color="primary"
            startIcon={<ArrowForwardIcon fontSize="small"/>}
            fullWidth
            disabled={store.loginBtnDisabled}
            onClick={store.onLogin}
        >
            Войти
        </EnhancedContainedButton>
        <EnhancedButton
            color="primary"
            fullWidth
            onClick={onRecoveryPasswordClick}
        >
            Восстановить пароль
        </EnhancedButton>
        {bottom}
    </BorderedBox>
});