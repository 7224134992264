import {FC} from "react";
import {useMainAppStore} from "../mainApp";
import _ from "underscore";
import {AccessDenied} from "../../surfaces/AccessDenied";
import {observer} from "mobx-react";

export const AuthRouteElement: FC<{ allowedRoles?: string[] }> = observer(({children, allowedRoles = []}) => {
    const mainAppStore = useMainAppStore();

    const allowedAccess = allowedRoles.length === 0
        || _.contains(allowedRoles, mainAppStore.userRole?.systemName);

    return allowedAccess
        ? <>{children}</>
        : <AccessDenied/>;
})