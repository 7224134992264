import {Checkbox, CheckboxProps, FormControlLabel, Typography} from "@mui/material";
import {FC, ReactNode} from "react";

export const EnhancedCheckbox: FC<Omit<CheckboxProps, "size" | "onChange"> & {
    label: ReactNode;
    fullWidth?: boolean;
    onChange: (checked: boolean) => void;
}> = ({color = "primary", label, fullWidth, onChange, ...otherProps}) => {

    return <FormControlLabel
        sx={[
            fullWidth == true && {width: "100%"}
        ]}
        control={
            <Checkbox
                onChange={(e, checked) => onChange(checked)}
                {...otherProps}
                color={color}
                size="small"
            />
        }
        label={<Typography color={color}>{label}</Typography>}
    />
}

