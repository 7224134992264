import {Nullable} from "../../types";
import {Avatar} from "@mui/material";
import FaceIcon from "@mui/icons-material/Face";

export const UserAvatar = ({src, alt}: { src: Nullable<string>; alt?: Nullable<string> }) => {
    return <Avatar
        src={src ?? undefined}
        alt={alt ?? undefined}
    >
        {alt != null
            ? alt[0]
            : <FaceIcon fontSize="small"/>}
    </Avatar>
}