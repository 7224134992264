import {observer} from "mobx-react";
import {BorderedBox} from "../../../ms-ui/ui/surfaces";
import {useTherapistAppStore} from "../../hooks/useTherapistAppStore";
import {useMemo} from "react";
import {RegistrationStore} from "./RegistrationStore";
import {useNavigate, useSearchParams} from "react-router-dom";
import {LoginHelper} from "../../../ms-ui/helpers";
import {AvatarEditor} from "../../../ms-ui/ui/components/avatarEditor/AvatarEditor";
import {
    EnhancedContainedButton,
    OutlinedDateTimePicker,
    OutlinedEnhancedSelect,
    OutlinedTextField
} from "../../../ms-ui/ui/controls";
import MuiPhoneNumber from "material-ui-phone-number";
import {Sex} from "../../../ms-ui/commons";
import {MenuItem} from "@mui/material";
import {OutlinedPasswordTextField} from "../../../ms-ui/ui/controls/OutlinedPasswordTextField";
import {EnhancedCheckbox} from "../../../ms-ui/ui/controls/EnhancedCheckbox";
import {MindSafeLicenseText, MindSafeUserDataPolicyText} from "../../../ms-ui/ui/components/information";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const Registration = observer(() => {
    const therapistAppStore = useTherapistAppStore();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const postRegistrationAction = () => {
        LoginHelper.setRememberMe(true);
        LoginHelper.setLogin(store.therapist.email);
        LoginHelper.setPassword(store.therapist.password);
        navigate("/");
    }

    const store = useMemo(() => new RegistrationStore(therapistAppStore, searchParams, postRegistrationAction), []);

    return <BorderedBox>
        <AvatarEditor
            src={store.therapist.avatar}
            onChange={src => store.therapist.avatar = src}
        />
        <OutlinedTextField
            label="E-mail"
            placeholder="__________@_____.__"
            value={store.therapist.email ?? ""}
            onChange={value => store.therapist.email = value === "" ? null : value}
            fullWidth
            //clearable={!store.emailTxtBoxDisabled}
            type="email"
            disabled={store.emailTxtBoxDisabled}
        />
        <MuiPhoneNumber
            variant="outlined"
            defaultCountry={'ru'}
            onChange={value => store.therapist.phoneNumber = value === "" ? null : value as string}
            size="small"
            label="Телефон"
            fullWidth
        />
        <OutlinedTextField
            label="Фамилия"
            value={store.therapist.lastName ?? ""}
            onChange={value => store.therapist.lastName = value === "" ? null : value}
            fullWidth
        />
        <OutlinedTextField
            label="Имя"
            value={store.therapist.firstName ?? ""}
            onChange={value => store.therapist.firstName = value === "" ? null : value}
            fullWidth
        />
        <OutlinedTextField
            label="Отчество"
            value={store.therapist.middleName ?? ""}
            onChange={value => store.therapist.middleName = value === "" ? null : value}
            fullWidth
        />
        <OutlinedDateTimePicker
            value={store.therapist.birthDate}
            onChange={value => store.therapist.birthDate = value}
            type="date"
        />
        <OutlinedEnhancedSelect
            label="Пол"
            value={store.therapist.sex}
            onChange={value => store.therapist.sex = value}
            fullWidth
            onClear={() => store.therapist.sex = Sex.None}
        >
            <MenuItem value={Sex.None}>Не выбран</MenuItem>
            <MenuItem value={Sex.Female}>Женщина</MenuItem>
            <MenuItem value={Sex.Male}>Мужчина</MenuItem>
        </OutlinedEnhancedSelect>
        <OutlinedPasswordTextField
            label="Пароль"
            value={store.therapist.password}
            onChange={value => store.therapist.password = value}
            error={store.isPasswordInvalid}
            fullWidth
        />
        <OutlinedPasswordTextField
            label="Подтверждение пароля"
            value={store.therapist.passwordConfirmation}
            onChange={value => store.therapist.passwordConfirmation = value}
            error={store.isPasswordConfirmationInvalid}
            fullWidth
        />
        <EnhancedCheckbox
            label={<MindSafeLicenseText/>}
            checked={store.therapist.userAgreement}
            onChange={checked => store.therapist.userAgreement = checked}
        />
        <EnhancedCheckbox
            label={<MindSafeUserDataPolicyText/>}
            checked={store.therapist.userDataPolicy}
            onChange={checked => store.therapist.userDataPolicy = checked}
        />
        <EnhancedContainedButton
            color="primary"
            startIcon={<ArrowForwardIcon fontSize="small"/>}
            onClick={store.registration}
            fullWidth
            disabled={store.registrationBtnDisabled}
        >
            Зарегистрироваться
        </EnhancedContainedButton>
    </BorderedBox>;
})
