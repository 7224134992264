import {AuthHelper} from "../helpers";
import {AuthResponse} from "../models/responses";
import axios from "axios";
import {Nullable} from "../types";
import {UnauthorizedAccessException} from "../models/exceptions";
import {ResponseBase} from "../models/responses/ResponseBase";
import appSettings from "../appSettings";
import {HttpClient} from "./httpClient";

class AccessTokenFactory {
    private accessTokenPromise: Nullable<Promise<string>> = null;
    private isPromisePending: boolean = false;

    private updateAccessToken = async (
        resolve: (accessToken: string) => void,
        reject: (error: Error) => void
    ) => {
        try {
            const httpClient = new HttpClient();

            const [fingerPrint, browser, os, timeZone, userAgent] = AuthHelper.getAuthData();
            const updateAccessTokenRequest = appSettings.getService("updateAccessToken")({
                request: {
                    fingerPrint,
                    browser,
                    os,
                    timeZone,
                    userAgent
                }
            });

            const updateAccessTokenResponse = await httpClient
                .execute<ResponseBase<AuthResponse>, AuthResponse>(updateAccessTokenRequest);

            AuthHelper.setAccessTokenData(updateAccessTokenResponse);

            resolve(AuthHelper.getAccessTokenData()!.accessToken);
        } catch (error) {
            const message = axios.isAxiosError(error)
                ? error.cause?.message ?? error.message
                : error instanceof Error
                    ? error.message
                    : "Во время обновления токена безопасности на стороне сервера произошла непредвиденная ошибка, необходимо повторно выполнить логин пользователя.";
            reject(new UnauthorizedAccessException(message));
        } finally {
            this.isPromisePending = false;
        }
    }

    get accessToken(): Promise<string> {
        if ((this.isPromisePending || AuthHelper.isAccessTokenDataExpired()) && this.accessTokenPromise != null) {
            return this.accessTokenPromise;
        }

        this.isPromisePending = true;
        this.accessTokenPromise = new Promise(this.updateAccessToken);
        return this.accessTokenPromise;
    }
}

export default new AccessTokenFactory()