import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Select2Context } from "./contexts";
import { SvgIconTypeMap } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useCommonStyles } from "./commonStyles";
import { useContext } from "react";

const useStyles = makeStyles(theme => ({
	openIcon: {
		margin: "3px",
		pointerEvents: "none",
	}
}))

interface OpenIconProps {
	icon?: OverridableComponent<SvgIconTypeMap>;
}

export const OpenIcon = observer((props: OpenIconProps) => {
	const store = useContext(Select2Context);
	const commonClasses = useCommonStyles();
	const classes = useStyles();

	const Icon = props.icon
		? props.icon
		: ArrowDropDownIcon;

	return <Icon
		className={clsx(classes.openIcon, store.isValid && commonClasses.successColor)}
		color={store.isError ? "error" : "action"}
		fontSize="small"
	/>
});
