import {Nullable} from "../types";

export const getPersonName = (lastName?: Nullable<string>, firstName?: Nullable<string>, middleName?: Nullable<string>, fullName?: boolean) => {
	if (!lastName && !firstName && !middleName) {
		return "";
	}
	const _lastName = lastName && lastName.trim() !== ""
		? `${lastName.trim()} `
		: "";
	const _firstName = firstName && firstName.trim() !== ""
		? fullName ? `${firstName.trim()} ` : `${firstName.trim()[0]}.`
		: "";
	const _middleName = middleName && middleName.trim() !== ""
		? fullName ? `${middleName.trim()} ` : `${middleName.trim()[0]}.`
		: "";

	return `${_lastName}${_firstName}${_middleName}`;
}
