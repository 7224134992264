import {FC} from "react";
import {Paper} from "@mui/material";
import {SxProps} from "@mui/system";
import {sxMerge} from "merge-sx";

interface MainAppContentProps {
    sx?: SxProps;
}

export const MainAppContent: FC<MainAppContentProps> = ({children, sx}) => {
    return <Paper
        sx={sxMerge({
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
        }, sx)}
    >
        {children}
    </Paper>
}
