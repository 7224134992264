/**
 * Метод создания объекта изображения
 * @param src Данные в формате base64
 */
export const createImageElement = (src: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error: ErrorEvent) => reject(error));
        image.src = src;
    });