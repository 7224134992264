import {ProfileTab} from "./ProfileTab";
import Tabs from "../../../ms-ui/ui/components/tabs/Tabs";
import {AboutTab, SecurityTab} from "../../../ms-ui/ui/components";
import {PreferencesTab} from "./PreferencesTab";


export const Settings = () => {
    return <Tabs>
        <Tabs.Item label="Профиль" component={<ProfileTab/>}/>
        <Tabs.Item label="Установки пользователя" component={<PreferencesTab/>}/>
        <Tabs.Item label="Безопасность" component={<SecurityTab/>}/>
        <Tabs.Item label="О компании" component={<AboutTab/>}/>
    </Tabs>;
}