import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material"
import {ChatSettingsHelper} from "../Chat/helpers/ChatSettingsHelper";
import {MessageSendingKey} from "../Chat/types/MessageSendingKey";
import React, {useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const PreferencesTab = () => {
    const [messageSendingKey, setMessageSendingKey] = useState(ChatSettingsHelper.getMessageSendingKey());
    const [isChatBackgroundEnabled, setIsChatBackgroundEnabled] = useState(ChatSettingsHelper.getIsChatBackgroundEnabled());

    const onMessageSendingKeyChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setMessageSendingKey(value as MessageSendingKey);
        ChatSettingsHelper.setMessageSendingKey(value as MessageSendingKey);
    }

    const onIsChatBackgroundEnabledChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setIsChatBackgroundEnabled(value === "true");
        ChatSettingsHelper.setIsChatBackgroundEnabled(value === "true");
    }

    return <Box p={4}>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                Способ отправки сообщения
            </AccordionSummary>
            <AccordionDetails>
                <RadioGroup
                    value={messageSendingKey}
                    onChange={onMessageSendingKeyChangeHandler}
                >
                    <FormControlLabel
                        value={"CtrlEnter" as MessageSendingKey}
                        control={<Radio size="small"/>}
                        label={<Typography variant="body2">
                            <strong>Ctrl + Enter</strong> — отправка сообщения, <strong>Enter</strong> — перенос
                            строки
                        </Typography>}
                    />
                    <FormControlLabel
                        value={"Enter" as MessageSendingKey}
                        control={<Radio size="small"/>}
                        label={<Typography variant="body2">
                            <strong>Enter</strong> — отправка сообщения, <strong>Ctrl + Enter</strong> — перенос
                            строки
                        </Typography>}
                    />
                </RadioGroup>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                Фон чата с сообщениями
            </AccordionSummary>
            <AccordionDetails>
                <RadioGroup
                    value={isChatBackgroundEnabled}
                    onChange={onIsChatBackgroundEnabledChangeHandler}
                >
                    <FormControlLabel
                        value={true}
                        control={<Radio size="small"/>}
                        label={<Typography variant="body2">
                            Включен
                        </Typography>}
                    />
                    <FormControlLabel
                        value={false}
                        control={<Radio size="small"/>}
                        label={<Typography variant="body2">
                            Выключен
                        </Typography>}
                    />
                </RadioGroup>
            </AccordionDetails>
        </Accordion>
    </Box>
}