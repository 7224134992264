import {computed, makeObservable, observable} from "mobx";
import {ReactNode} from "react";
import {AuthStore} from "../../authentificate/AuthStore";
import {Nullable} from "../../../../types";
import {RoleViewModel} from "../../../../models/entities";

export class MainAppStore {
    isLoading: boolean = false;
    error: Nullable<Error> = null;
    success: Nullable<ReactNode> = null;
    authStore: AuthStore;

    constructor(
        authStoreCtr: { new(mainAppStore: MainAppStore): AuthStore }) {
        this.authStore = new authStoreCtr(this);

        makeObservable(this, {
            isLoading: observable,
            error: observable,
            success: observable,
            authStore: observable,
            userRole: computed
        })
    }

    init = async () => {
        await this.authStore.init();
    }

    get userRole(): Nullable<RoleViewModel> {
        return null;
    }
}