import {computed, makeObservable, observable} from "mobx";
import {InvitationTherapistViewModel, InviteTherapistStatisticsViewModel} from "../../../models/entities";
import {StoreBase} from "../../../ms-ui/stores";
import {TherapistAppStore} from "../../../stores/TherapistAppStore";
import {HttpClient} from "../../../ms-ui/http";
import {InvitationListResponse} from "../../../models/responses";
import {v4} from "uuid";
import {GridActionsCellItem, GridColDef} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {Chip} from "@mui/material";
import {InviteStatus, InviteStatusDescription} from "../../../commons";
import CancelIcon from "@mui/icons-material/Cancel";
import ReplayIcon from "@mui/icons-material/Replay";
import {ResponseBase} from "../../../ms-ui/models/responses/ResponseBase";
import {therapistAppSettings} from "../../../therapistAppSettings";

interface StatisticItem {
    id: string;
    item: string;
    value: number;
}

export class InvitationStore extends StoreBase<TherapistAppStore> {

    public statistics: StatisticItem[] = [];
    public invitations: InvitationTherapistViewModel[] = [];

    constructor(therapistAppStore: TherapistAppStore) {
        super(therapistAppStore);

        makeObservable(this, {
            statistics: observable,
            invitations: observable,
            statisticColumns: computed,
            invitationColumns: computed
        })
    }


    init = async () => {
        return this.execute(async () => {
            const request = therapistAppSettings.getService("invitationList")();

            const response = await new HttpClient()
                .useAuthorization()
                .throwErrorIfResponseIsNull()
                .execute<ResponseBase<InvitationListResponse>, InvitationListResponse>(request);

            this.statistics = this._convertStatistics(response!.statistics);
            this.invitations = response!.invites;
        });
    }

    private _convertStatistics = (responseStatistics: InviteTherapistStatisticsViewModel): StatisticItem[] => {
        return [
            {id: v4(), item: "Приглашений отправлено", value: responseStatistics.total},
            {id: v4(), item: "Приглашеных зарегистрировано", value: responseStatistics.registered},
            {id: v4(), item: "Ожидает ответа", value: responseStatistics.pending}
        ];
    }

    sendInvitations = async (emails: string[], message: string) => {
        await this.execute(async () => {
            const request = therapistAppSettings.getService("sendInvitations")({
                request: {
                    emails, message
                }
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);
        });
        await this.init();
    }

    reSendInvitation = async (id: string) => {
        await this.execute(async () => {
            const request = therapistAppSettings.getService("reSendInvitation")({
                id
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);
        });
        await this.init();
    }

    cancelInvitation = async (id: string) => {
        await this.execute(async () => {
            const request = therapistAppSettings.getService("cancelInvitation")({
                id
            });

            await new HttpClient()
                .useAuthorization()
                .execute<ResponseBase>(request);
        });
        await this.init();
    }

    componentDidMount = async () => {
        await super.componentDidMount();
        await this.init();
    }

    get statisticColumns(): GridColDef<StatisticItem>[] {
        return [
            {field: "item", headerName: "Тип", width: 250, sortable: false},
            {field: "value", headerName: "Значение", sortable: false}
        ]
    }

    get invitationColumns(): GridColDef<InvitationTherapistViewModel>[] {
        return [
            {field: "email", headerName: "Email", width: 250, sortable: false},
            {
                field: "sendDateTime",
                headerName: "Дата отправки",
                align: "right",
                headerAlign: "right",
                width: 180,
                sortable: false,
                valueGetter: ({row}) => {
                    return dayjs(row.sendDateTime).format("DD.MM.YYYY HH:mm:ss");
                }
            },
            {
                field: "status", headerName: "Статус", flex: 1, minWidth: 200, sortable: false, renderCell: ({row}) => {
                    return <Chip
                        label={InviteStatusDescription[row.status]}
                        color={row.status === InviteStatus.Registered
                            ? "success"
                            : row.status === InviteStatus.Pending
                                ? "warning"
                                : row.status === InviteStatus.Canceled
                                    ? "error"
                                    : "default"}
                        size="small"
                    />
                }
            },
            {
                field: "actions",
                type: "actions",
                headerName: "Действия",
                getActions: ({row}) => {
                    return [
                        <GridActionsCellItem
                            label="Отозвать приглашение"
                            icon={<CancelIcon fontSize="small" color="error"/>}
                            onClick={() => this.cancelInvitation(row.id)}
                            showInMenu
                            disabled={row.status !== InviteStatus.Pending}
                        />,
                        <GridActionsCellItem
                            label="Отправить повторно"
                            icon={<ReplayIcon fontSize="small" color="primary"/>}
                            onClick={() => this.reSendInvitation(row.id)}
                            showInMenu
                            disabled={row.status === InviteStatus.Registered}
                        />
                    ]
                }
            }
        ]
    }
}