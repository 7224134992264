import {Alert, Button, Typography} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {BorderedBox} from "../../surfaces";
import {SetPasswordStore} from "./SetPasswordStore";
import {observer} from "mobx-react";
import {useMemo} from "react";
import {OutlinedPasswordTextField} from "../../controls/OutlinedPasswordTextField";
import {useMainAppStore} from "../mainApp";

export const SetPassword = observer(() => {
    const navigate = useNavigate();
    const mainAppStore = useMainAppStore();
    const [searchParams] = useSearchParams();
    const store = useMemo(() => new SetPasswordStore(mainAppStore, searchParams), []);

    const setPassword = async () => {
        await store.setPassword(() => navigate("/login"));
    }

    return <BorderedBox>
        <Typography
            component="div"
            variant="h5"
            color="primary"
        >
            Установить пароль
        </Typography>
        <Alert severity="info">
            <Typography variant="caption">
                Пароль должен содержать прописные и строчные буквы, хотя бы одну цифру, один спец. символ: %, *, (,
                ), ?, @, #, $, ~, !, + и быть в длинну от 8 до 15 символов.
            </Typography>
        </Alert>
        <OutlinedPasswordTextField
            label="Новый пароль"
            value={store.new}
            onChange={value => store.new = value}
            error={store.isValidNew}
        />
        <OutlinedPasswordTextField
            label="Повтор"
            value={store.repeat}
            onChange={value => store.repeat = value}
            error={store.isValidRepeat}
        />
        <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<ArrowForwardIcon fontSize="small"/>}
            fullWidth
            onClick={setPassword}
            disabled={store.changePasswordBtnDisabled}
        >
            Сохранить
        </Button>
    </BorderedBox>;
})