import {AuthHelper, LoginHelper} from "../../../helpers";
import {QS_SECRET_KEY, QS_USER_ID_KEY} from "../../../commons";
import {makeObservable, observable} from "mobx";
import {StoreBase} from "../../../stores";
import {Nullable} from "../../../types";
import {HttpClient} from "../../../http";
import {MainAppStore} from "../mainApp";
import {ResponseBase} from "../../../models/responses/ResponseBase";
import appSettings from "../../../appSettings";

export class SetPasswordStore extends StoreBase<MainAppStore> {
    private _new: Nullable<string>;
    private _repeat: Nullable<string>;

    constructor(mainAppStore: MainAppStore, private queryParams: URLSearchParams) {
        super(mainAppStore);

        this._new = null;
        this._repeat = null;

        makeObservable<SetPasswordStore, "_new" | "_repeat">(this, {
            _new: observable,
            _repeat: observable
        });
    }

    get new() {
        return this._new ?? "";
    }

    set new(value: string) {
        this._new = value.trim() === "" ? null : value;
    }

    get repeat() {
        return this._repeat ?? "";
    }

    set repeat(value: string) {
        this._repeat = value.trim() === "" ? null : value;
    }

    get isValidNew(): Nullable<string> {
        if (this._new === null && this._repeat === null) {
            return null;
        }

        if (this._new === null) {
            return "Не может быть пустым";
        }

        if (!AuthHelper.validatePassword(this._new)) {
            return "Не соответствует требованиям политики безопасности формирования пароля";
        }

        return null;
    }

    get isValidRepeat(): Nullable<string> {
        if (this._new === null && this._repeat === null) {
            return null;
        }

        if (this._new !== this._repeat) {
            return "Новый пароль и повтор не совпадают";
        }

        return null;
    }

    setPassword = async (post?: VoidFunction) => {
        return this.execute(async () => {
            const userId = this.queryParams.get(QS_USER_ID_KEY);
            const secret = this.queryParams.get(QS_SECRET_KEY);

            if (userId === null || secret === null) {
                throw new Error("Невозможно выполнить установку пароля, требуемые параметры отсутствуют");
            }

            const request = appSettings.getService("setPassword")({
                request: {
                    userId,
                    new: this.new,
                    secret
                }
            });

            await new HttpClient()
                .execute<ResponseBase>(request);

            if (LoginHelper.getRememberMe()) {
                LoginHelper.setPassword(this.new);
            }

            this.clearFields();

            post && post();
        });
    }

    private clearFields = () => {
        this._new = this._repeat = null;
    }

    get changePasswordBtnDisabled() {
        return this._new == null
            || this._repeat == null
            || Boolean(this.isValidNew)
            || Boolean(this.isValidRepeat);
    }
}