import { FC } from "react";

export const Spacer: FC = (props) => {
	return <div
		style={{
			flex: 1,
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		}}
	>
		{props.children}
	</div>
}
