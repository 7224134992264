import {Typography} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import {useSearchParams} from "react-router-dom";
import {useMemo} from "react";
import {useMainAppStore} from "../mainApp";
import {RecoveryPasswordStore} from "./RecoveryPasswordStore";
import {View} from "./types/View";
import {EnhancedContainedButton, OutlinedTextField} from "../../controls";
import {BorderedBox} from "../../surfaces";
import {observer} from "mobx-react";
import {validateEmail} from "../../../helpers";


export const RecoveryPassword = observer(() => {
    const [searchParams] = useSearchParams();
    const mainAppStore = useMainAppStore();
    const store = useMemo(() => new RecoveryPasswordStore(mainAppStore, searchParams), []);

    return <>
        {store.view === View.RecoveryForm && <BorderedBox>
            <OutlinedTextField
                label="E-mail"
                placeholder="__________@_____.__"
                value={store.email}
                onChange={value => store.email = value}
                inputMode="email"
                fullWidth
            />
            <EnhancedContainedButton
                color="primary"
                startIcon={<ArrowForwardIcon fontSize="small"/>}
                onClick={store.recovery}
                fullWidth
                disabled={!validateEmail(store.email)}
            >
                Восстановить
            </EnhancedContainedButton>
        </BorderedBox>}
        {store.view === View.SuccessForm && <BorderedBox>
            <Typography
                component="div"
                color="primary"
                align="center"
            >
                Мы отправили на вашу электронную почту письмо.<br/>
                В письме ссылка для восстановления пароля.<br/>
                Пройдите по этой ссылке.
            </Typography>
        </BorderedBox>}
    </>;
})