import { Select2Context } from "../contexts";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react"
import { useCommonStyles } from "../commonStyles";
import { useContext } from "react";

export const TextValue = observer(() => {
	const store = useContext(Select2Context);
	const commonClasses = useCommonStyles({ dense: store.isDense });

	return <Typography
		className={clsx(store.isValid && commonClasses.successColor,
			store.isDisabled && {
				[commonClasses.medium]: store.size === "medium",
				[commonClasses.small]: store.size === "small"
			})}
		color={store.isError ? "error" : "initial"}
		noWrap
	>
		{store.textValue}
	</Typography>
})
