import {
    Breakpoint,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {EnhancedButton, EnhancedTooltipIconButton} from "../controls";
import React, {createElement, FC, ReactNode} from "react";

import CloseIcon from "@mui/icons-material/Close";
import {Spacer} from "./Spacer";
import {makeStyles} from "@mui/styles";
import {SvgIconComponent} from "@mui/icons-material";

const useStyles = makeStyles<Theme, { maxHeight?: string }>(theme => ({
    title: {
        padding: "4px 8px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "rgba(0, 0, 0, 0.6)"
    },
    content: {
        padding: "8px !important",
        height: ({maxHeight}) => maxHeight,
        "& > :not(:last-child)": {
            marginBottom: "8px"
        },
        display: "flex",
        flexDirection: "column"
    },
    actions: {
        justifyContent: "space-between",
        padding: "4px 8px"
    }
}))

interface ButtonOptions {
    label?: ReactNode;
    icon?: SvgIconComponent;
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
    onClick: VoidFunction;
    disabled?: boolean;
}

export interface EnhancedDialogProps {
    title?: React.ReactNode;
    open: boolean;
    submitButtonOption?: ButtonOptions;
    cancelButtonOption?: ButtonOptions;
    maxWidth?: Breakpoint;
    maxHeight?: string;
    actions?: boolean;
    disallowBackdropClick?: boolean;
    fullWidth?: boolean;
}

export const EnhancedDialog: FC<EnhancedDialogProps> = (props) => {
    const classes = useStyles({maxHeight: props.maxHeight});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const onCancelHandler = (event: {}, reason?: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick" && props.disallowBackdropClick) {
            return;
        }

        props.cancelButtonOption?.onClick && props.cancelButtonOption?.onClick();
    }

    return <Dialog
        open={props.open}
        onClose={onCancelHandler}
        scroll="paper"
        fullScreen={fullScreen}
        maxWidth={props.maxWidth ?? "lg"}
        fullWidth={props.fullWidth}
    >
        {props.title && <DialogTitle className={classes.title}>
            {props.title}
            <EnhancedTooltipIconButton
                title="Закрыть"
                onClick={onCancelHandler}
                disabled={props.cancelButtonOption?.disabled}
            >
                <CloseIcon/>
            </EnhancedTooltipIconButton>
        </DialogTitle>}
        <DialogContent className={classes.content} dividers>
            {props.children}
        </DialogContent>
        {props.actions && <DialogActions className={classes.actions}>
            {props.submitButtonOption && <EnhancedButton
                startIcon={props.submitButtonOption.icon && createElement(props.submitButtonOption.icon)}
                onClick={props.submitButtonOption?.onClick}
                color={props.submitButtonOption?.color ?? "primary"}
                disabled={props.submitButtonOption?.disabled}
            >
                {props.submitButtonOption?.label ?? "Применить"}
            </EnhancedButton>}
            <Spacer/>
            {props.cancelButtonOption && <EnhancedButton
                startIcon={props.cancelButtonOption.icon && createElement(props.cancelButtonOption.icon)}
                onClick={onCancelHandler}
                color={props.cancelButtonOption?.color ?? "error"}
                disabled={props.cancelButtonOption?.disabled}
            >
                {props.cancelButtonOption?.label ?? "Закрыть"}
            </EnhancedButton>}
        </DialogActions>}
    </Dialog>
}
