// пол
export enum Sex {
    None = 0,
    Female = 2,
    Male = 4
};

export const SexDescription = {
    [Sex.None]: "Не указан",
    [Sex.Female]: "Женский",
    [Sex.Male]: "Мужской"
}

export enum EmailConfirmationStatus {
    Confirmed = 10,
    AlreadyWasConfirmed = 20,
    Error = 500
}

export const EmailConfirmationStatusDescription = {
    [EmailConfirmationStatus.Confirmed]: "Адрес подтвержден",
    [EmailConfirmationStatus.AlreadyWasConfirmed]: "Адрес уже был подтвержден",
    [EmailConfirmationStatus.Error]: "Ошибка при подтверждении"
}