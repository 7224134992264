import { EnhancedButton } from "./EnhancedButton";
import { EnhancedButtonProps } from "../../types";
import { FC } from "react";

export const EnhancedContainedButton: FC<Omit<EnhancedButtonProps, "variant">> = (props) => {
	return <EnhancedButton
		variant="contained"
		{...props}
	>
		{props.children}
	</EnhancedButton>
}
