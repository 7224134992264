import { Alert, AlertColor, Snackbar, SnackbarOrigin } from "@mui/material";

import React from "react";
import { makeStyles } from "@mui/styles";

export interface EnhancedSnackbarProps {
	open: boolean;
	message: React.ReactNode;
	severity?: AlertColor;
	variant?: "standard" | "filled" | "outlined";
	onClose?: () => void;
	anchorOrigin?: SnackbarOrigin;
};

const useStyles = makeStyles(theme => ({
	message: {
		overflowWrap: "anywhere"
	}
}))

export const EnhancedSnackbar = (props: EnhancedSnackbarProps) => {
	const classes = useStyles();

	return <Snackbar
		open={props.open}
		onClose={props.onClose}
		autoHideDuration={10000}
		anchorOrigin={props.anchorOrigin || { vertical: "top", horizontal: "center" }}
	>
		<Alert
			variant={props.variant}
			severity={props.severity}
			onClose={props.onClose}
			classes={{
				message: classes.message
			}}
		>
			{props.message}
		</Alert>
	</Snackbar>
};
